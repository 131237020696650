import React, { useCallback, useRef, useState } from 'react';
import { useRolesContext } from 'src/contexts/roles.context';
import { useEmployeeContext } from 'src/contexts/employee.context';
import { rolesActionTypes } from 'src/ducks/roles.duck';
import history from 'src/history';
import {
  IEmployeeRoleFilter,
  IRoleAssigmentNumber,
  IRolesForm,
  IRolesPreForm,
} from 'src/models/roles.model';
import { IEmployee } from 'src/models/employee.model';
import { camelCase } from 'lodash';
import {
  getRolesParams,
  getRolesStatus,
  groupBy,
} from 'src/selectors/roles.selector';
import {
  ToastError,
  ToastSuccess,
} from 'src/components/atoms/toaster/toaster.component';
import { AccessRole } from 'src/constants/access.constant';
import RolesAddView from './roles-add.view';

type IProps = {
  onClose: boolean;
  setOnClose: () => void;
  setFilterState: React.Dispatch<
    React.SetStateAction<Partial<IEmployeeRoleFilter>>
  >;
};

const RolesAdd: React.FC<IProps> = ({
  onClose,
  setOnClose,
  setFilterState,
}) => {
  const { state, actions } = useRolesContext();
  const { state: employeeState } = useEmployeeContext();
  const formRef = useRef<HTMLFormElement>(null);
  const [existingEmp, setExistingEmpId] = useState<Record<any, any>>();
  const [editExistingEmp, setEditExistingEmp] = useState<boolean>(false);
  const [employee, setEmployee] = useState<IEmployee | undefined>(undefined);
  const [employeeData, setEmployeeData] = useState<boolean>(false);
  const groupByEmpId = groupBy('empId');
  const status = getRolesStatus(state, rolesActionTypes.ROLES_DATA_CREATE);
  const statusUpdate = getRolesStatus(
    state,
    rolesActionTypes.ROLES_DATA_UPDATE
  );

  const handleSearchAdd = useCallback(
    (id) => {
      const user = employeeState.list.find((value) => value.emp_id === id);
      return user;
    },
    [employeeState]
  );

  const handleClick = useCallback(() => {
    if (formRef && formRef.current) {
      formRef.current.handleSubmit();
    }
  }, [formRef]);

  const handleSubmit = useCallback(
    async (formData: IRolesPreForm) => {
      let rolesId = Object.values(formData.rolesId)
        .map((value) => Number(value))
        .filter((value) => value > 0)
        .map((value) => Number(value));

      const rtlUpdater = rolesId.find(
        (value) => value === AccessRole.REAL_TIME_LOG_UPDATER
      );

      const rtlViewer = rolesId.find(
        (value) => value === AccessRole.REAL_TIME_LOG_VIEWER
      );

      if (!rtlUpdater && !rtlViewer) {
        rolesId = rolesId.filter(
          (value) =>
            value !== AccessRole.BPOR_SENDER && value !== AccessRole.BPOR_VIEWER
        );
      } else if (rtlViewer) {
        rolesId = rolesId.filter((value) => value !== AccessRole.BPOR_SENDER);
      }
      // ***********************************************************************

      const newFormData = {
        ...formData,
        rolesId,
      } as IRolesForm;
      const employeeAdd = await handleSearchAdd(newFormData.empId);
      const response = await actions.createPOST({
        ...newFormData,
      });
      if (!response.error) {
        ToastSuccess(
          `Roles added successfully for the Employee ${employeeAdd?.first_name} ${employeeAdd?.last_name}`
        );
        setEmployee(undefined);
        setEditExistingEmp(false);
        setOnClose();
        setFilterState({});
        history.push('/roles');
      } else {
        ToastError('Error!');
        setEmployee(undefined);
        setOnClose();
      }
    },
    [actions, handleSearchAdd]
  );

  const handleSubmitEdit = useCallback(
    async (formData: IRolesPreForm) => {
      let rolesId = Object.values(formData.rolesId)
        .map((value) => Number(value))
        .filter((value) => value > 0)
        .map((value) => Number(value));

      // Ensure that bpor role is not set if real-time log role is not Updater
      const rtlUpdater = rolesId.find(
        (value) => value === AccessRole.REAL_TIME_LOG_UPDATER
      );

      const rtlViewer = rolesId.find(
        (value) => value === AccessRole.REAL_TIME_LOG_VIEWER
      );

      if (!rtlUpdater && !rtlViewer) {
        rolesId = rolesId.filter(
          (value) =>
            value !== AccessRole.BPOR_SENDER && value !== AccessRole.BPOR_VIEWER
        );
      } else if (rtlViewer) {
        rolesId = rolesId.filter((value) => value !== AccessRole.BPOR_SENDER);
      }
      // ***********************************************************************

      const newFormData = {
        ...formData,
        rolesId,
      } as IRolesForm;
      const employeeAdd = await handleSearchAdd(newFormData.empId);
      // Edit
      const response = await actions.updatePUT(newFormData?.empId, {
        ...newFormData,
      });
      setExistingEmpId(undefined);
      if (!response.error) {
        ToastSuccess(
          `Roles have been updated successfully for employee ${employeeAdd?.first_name} ${employeeAdd?.last_name}`
        );
        setEmployee(undefined);
        setEditExistingEmp(false);
        setOnClose();
        setFilterState({});
        history.push('/roles');
      } else {
        ToastError(
          'Failed on saving/updating roles. Contact your system administrator.'
        );
      }
    },
    [actions, handleSearchAdd]
  );

  const handleLoader = useCallback(
    (empStateData: boolean) => {
      setEmployeeData(empStateData);
    },
    [setEmployeeData]
  );

  const handleSetRoles = useCallback(
    (rolesData) => {
      handleLoader(true);
      const transformedArray: Record<any, any>[][] = [];
      let rolesId: Record<any, any> = [];
      const grouped = groupByEmpId(rolesData);
      if (Object.keys(grouped) !== undefined) {
        Object.keys(grouped).map((el) => {
          transformedArray.push(grouped[el]);
        });
      }
      if (transformedArray !== undefined) {
        rolesId = transformedArray[0]?.map((element) => {
          return {
            [camelCase(element.role.contentAccess)]: element.roleId,
          };
        });
      }
      if (rolesId?.length > 0) {
        handleLoader(false);
        const newObject: IRoleAssigmentNumber = Object.values(rolesId).reduce(
          (r, c) => Object.assign(r, c),
          {}
        );
        setExistingEmpId({
          empId:
            transformedArray[0]
              ?.map((element) => element.empId)
              .splice(0, 1)[0] ?? '',
          roleStatus: transformedArray[0]
            ?.map((element) => element.roleStatus)
            .splice(0, 1)[0],
          rolesId: {
            keys: newObject.keys ?? null,
            authList: newObject.authList ?? null,
            outagePlanning: newObject.outagePlanning ?? null,
            realTimeLog: newObject.realTimeLog ?? null,
            bporReport: newObject.bporReport ?? null,
            roles: newObject.roles ?? null,
            substationEntryLog: newObject.substationEntryLog ?? null,
            troubleJob: newObject.troubleJob ?? null,
            troubleTicket: newObject.troubleTicket ?? null,
          },
        });
      }
    },
    [setExistingEmpId, groupByEmpId]
  );

  const handleSearch = useCallback(
    async (id) => {
      handleLoader(true);
      const params = await getRolesParams(
        { limit: 0, page: 1 },
        { empId: id.toString() }
      );
      const response = await actions.listGET(params);
      const user = employeeState.list.find((value) => value.emp_id === id);
      if (!response.payload?.rows?.length) {
        setExistingEmpId(undefined);
        setEmployee(user);
        setEditExistingEmp(false);
        handleLoader(false);
        return id;
      }
      if (response.payload?.rows?.length) {
        handleLoader(true);
        handleSetRoles(response.payload?.rows);
        setEmployee(undefined);
        setEditExistingEmp(true);
      }
      return '';
    },
    [employeeState, actions, handleLoader]
  );

  return (
    <RolesAddView
      formRef={formRef}
      loading={status.fetching || statusUpdate.fetching}
      handleClick={handleClick}
      handleSubmit={handleSubmit}
      handleSubmitEdit={handleSubmitEdit}
      setOnClose={setOnClose}
      onClose={onClose}
      employee={employee}
      defaultValues={existingEmp}
      handleSearch={handleSearch}
      employeeData={employeeData}
      setEmployee={setEmployee}
      grantAccess
      setExistingEmpId={setExistingEmpId}
      setEditExistingEmp={setEditExistingEmp}
      editExistingEmp={editExistingEmp}
    />
  );
};

export default RolesAdd;
