import yup from 'src/libraries/validator.library';
import { REGEX_24HR_TIME } from 'src/constants/regex.constant';
import { LerRequestOutageDateSchema } from './ler-request.model';
import { NoteSchema } from './note.model';
import { RequestStatusSchema } from './request-status.model';
import { SubstationSchema } from './substation.model';

export const SystemChangeShape = {
  requestId: yup.number().required(),
  sysChngInd: yup.boolean().nullable(),
  requestStat: yup.number().nullable(),
  sysChngSummaryId: yup.number().nullable(),
  wrkDesc: yup.string().nullable(),
  requestStatus: RequestStatusSchema.clone(),
  outageFacility: yup
    .object({
      facTypId: yup.number(),
      outgFacId: yup.number().required(),
      outageType: yup
        .object({
          outageTypeDesc: yup.string().nullable(),
          outageTypeId: yup.number().nullable(),
        })
        .nullable(),
      facility: yup
        .object({
          outgFacId: yup.number().nullable(),
          facTypId: yup.number().nullable(),
          outgFacNm: yup.string().nullable(),
          facTyp: yup
            .object({
              facTypId: yup.number().nullable(),
              facTypNm: yup.string().nullable(),
            })
            .nullable(),
        })
        .nullable(),
      substationId: yup.string().nullable(),
      substation: SubstationSchema.nullable(),
      voltId: yup.number().nullable(),
      equipmentId: yup.number().nullable(),
      outgTypId: yup.number().required(),
    })
    .nullable(),
  sysChngSummary: NoteSchema.optionalContent(),
  outageDates: yup.array().of(LerRequestOutageDateSchema.clone()).required(),
  inServiceDate: yup.date(),
  startDate: yup.date(),
  completedBy: yup.string().nullable(),
  completedOn: yup.date().nullable(),
  completionSetBy: yup.string().nullable(),
  isComplete: yup.boolean().nullable(),
  completionMailStatus: yup.boolean().nullable(),
};

export const RequestFacilityShape = {
  requestId: yup.number().nullable(),
  version_no: yup.string().nullable(),
  outgFacId: yup.number().nullable(),
  relayNbrs: yup.string().nullable(),
  updatedAt: yup.date().nullable(),
  updatedBy: yup.string().nullable(),
  voltId: yup.number().nullable(),
  substationId: yup.string().nullable(),
  outgTypId: yup.number().nullable(),
};

export const SystemChangeReportShape = {
  requestId: yup.number().nullable(),
  sysChngInd: yup.boolean().nullable(),
  requestStat: yup.number().nullable(),
  sysChngSummaryId: yup.number().nullable(),
  wrkDesc: yup.string().nullable(),
  requestStatus: yup.object({
    requestStatusId: yup.number().nullable(),
    name: yup.string().nullable(),
    updatedAt: yup.date(),
    updatedBy: yup.string(),
  }).nullable(),
  outageFacility: yup.object({
    ...RequestFacilityShape,
    facility: yup.object({
      outgFacId: yup.number().nullable(),
      outgFacNm: yup.string().nullable(),
      voltId: yup.number().nullable(),
      facTypId: yup.number().nullable(),
      facTblsCoord: yup.string().nullable(),
      updatedAt: yup.date().nullable(),
      updatedBy: yup.string().nullable(),
      besInd: yup.string().nullable(),
      endDt: yup.date().nullable(),
      isoFacId: yup.number().nullable(),
      stat: yup.string().nullable(),
      offSysInd: yup.string().nullable(),
      spclOpsConcern: yup.string().nullable(),
    }).nullable(),
    substation: yup.object({
      substationId: yup.string().nullable(),
      name: yup.string().nullable(),
      addr1: yup.string().nullable(),
      addr2: yup.string().nullable(),
      city: yup.string().nullable(),
      state: yup.string().nullable(),
      zipCd: yup.string().nullable(),
      gps: yup.string().nullable(),
      areaCd: yup.string().nullable(),
      phnNbr: yup.string().nullable(),
      modemFlg: yup.string().nullable(),
      updatedAt: yup.date().nullable(),
      updatedBy: yup.string().nullable(),
      subDistId: yup.string().nullable(),
      tbros: yup.string().nullable(),
      custCnt: yup.number().nullable(),
      loadMw: yup.number().nullable(),
    }).nullable(),
  }).nullable(),
  sysChngSummary: yup.object({
    noteId: yup.number().nullable(),
    noteTxt: yup.string().nullable(),
    version_no: yup.string().nullable(),
    status: yup.string().nullable(),
    name: yup.string().nullable(),
    updatedBy: yup.string(),
    updatedAt: yup.date(),
  }).nullable(),
  outageDates: yup
    .array()
    .of(yup.object({
      requestId: yup.number().nullable(),
      version_no: yup.string().nullable(),
      reqStatId: yup.number(),
      startTm: yup.date().nullable(),
      stopTm: yup.date().nullable(),
      updatedAt: yup.date().nullable(),
      updatedBy: yup.string().nullable(),
      significantCostInd: yup.string().nullable(),
      actualStrtTm: yup.date().nullable(),
      actualStopTm: yup.date().nullable(),
      actualSwitchInTm: yup.date().nullable(),
      actualSwitchOutTm: yup.date().nullable(),
    }).nullable())
    .nullable(),
  inServiceDate: yup.date().nullable(),
  startDate: yup.date().nullable(),
  completedBy: yup.string().nullable(),
  completionSetBy: yup.string().nullable(),
  completedOn: yup.date().nullable(),
  isComplete: yup.boolean().nullable(),
  completionMailStatus: yup.boolean().nullable(),
};

export const SystemChangeReportSchema = yup.object(SystemChangeReportShape);

export const SystemChangeCompleteFormShape = {
  requestId: yup.number().required(),
  isComplete: yup.boolean().nullable(),
  completedOn: yup
    .date()
    .nullable()
    .when('isComplete', {
      is: (value) => Boolean(value) === true,
      then: yup.date().required().typeError('This field is required'),
    }),
  completedOnDate: yup
    .date()
    .nullable()
    .when('isComplete', {
      is: (value) => Boolean(value) === true,
      then: yup.date().required().typeError('This field is required'),
    }),
  completedOnTime: yup
    .string()
    .nullable()
    .when('isComplete', {
      is: (value) => Boolean(value) === true,
      then: yup
        .string()
        .required()
        .typeError('This field is required')
        .matches(REGEX_24HR_TIME, 'Invalid Time. Format: hh:mm'),
    }),
  completionSetBy: yup.string().nullable(),
  mailContent: yup.string().required(),
};

export const SystemChangeSchema = yup.object(SystemChangeShape);
export const SystemChangeCompleteFormSchema = yup.object(
  SystemChangeCompleteFormShape
);

export const SystemChangeListPayloadSchema = yup.object({
  count: yup.number().defined(),
  rows: yup.array().of(SystemChangeSchema.clone()),
});

export const SystemChangePayloadSchema = yup.object({
  systemChange: SystemChangeSchema.clone(),
});

export const SystemChangeDeletePayloadSchema = yup.object({
  success: yup.boolean().required(),
});

export type IsystemChangeCompleteForm = yup.Asserts<
  typeof SystemChangeCompleteFormSchema
>;

export type ISystemChange = yup.Asserts<typeof SystemChangeSchema>;

export type ISystemChangeReport = yup.Asserts<typeof SystemChangeReportSchema>;
