import {
  checkCachedTokenDuration,
  getFileDataUrl,
  getParentFolderName,
  getSharePointSettingProperty,
  helpers,
  SharepointEnvironmentProperties,
} from 'src/helpers/sharepoint-service.helper';
import { useFileContext } from 'src/contexts/file.context';
import services from 'src/services/file.service';
import {
  ITostPosition,
  ToastError,
  ToastSuccess,
} from 'src/components/atoms/toaster/toaster.component';
import {
  ISharepointProperty,
  ISharepointRequest,
  ISharepointRequestData,
  ISharepointResponse,
  IUploadedFileData,
} from 'src/models/sharepoint-service.model';
import {
  SharePointAccessTokenSession,
  SharePointApplicationType,
  SharePointDownloadType,
} from 'src/constants/sharepoint-common.constant';
import moment from 'moment';
import { IFileSharePointType } from 'src/ducks/file.duck';
import { IFileList, IFileSharePoint } from 'src/models/file.model';
import { formatDate } from 'src/libraries/moment.library';

export type SharePointSubmitRequestType = {
  requestData: ISharepointRequestData;
  files: File[];
  applicationType: SharePointApplicationType;
  setting: any;
  withParentFolder: boolean;
  cachedToken?: IFileSharePointType;
  setIsUpdated?: React.Dispatch<React.SetStateAction<boolean>>;
  setMccListFile?: React.Dispatch<React.SetStateAction<IFileSharePoint[]>>;
  callback?: () => void;
};

const useSharepoint = () => {
  const { actions } = useFileContext();
  let data: ISharepointResponse = {
    uploadFileResponse: [],
    mccListResponse: '',
    createFolderResponse: false,
    deleteDocumentResponse: false,
    cachedToken: '',
  };

  const mccList = async (mccListReqData: ISharepointRequest) => {
    try {
      const response = await actions.listSharepoint(
        helpers.mccListDataHelper(
          mccListReqData.parentFolder,
          mccListReqData.folderName,
          mccListReqData.accessToken,
          mccListReqData.documentLibrary,
          mccListReqData.documentId
        )
      );

      if (response.payload === null) {
        return null;
      }

      if (response.payload) {
        const fileLocationList: IUploadedFileData[] = [];
        const parser = new DOMParser();
        const xml = parser.parseFromString(response.payload, 'text/xml');
        const listItems = xml.getElementsByTagName('z:row');
        for (let i = 0; i < listItems.length; i++) {
          fileLocationList[i] = {
            fileName: `${SharepointEnvironmentProperties.SHAREPOINT_BASE_URL}/${
              listItems[i].getAttribute('ows_FileRef')?.split('#')[1]
            }`,
            uploadedDate: `${listItems[i].getAttribute(
              'ows_Created_x0020_Date'
            )}`,
            uploadedBy: `${listItems[i].getAttribute('ows_Title')}`,
            fileId: `${listItems[i].getAttribute('ows_ID')}`,
          };
        }
        const fileListResponse: ISharepointResponse = {
          mccListResponse: JSON.stringify(fileLocationList),
          uploadFileResponse: [],
          createFolderResponse: false,
          deleteDocumentResponse: false,
          cachedToken: '',
        };
        data = fileListResponse;
      }
    } catch (error) {
      return data;
    }
    return data;
  };

  const createFolder = async (createFolderReqData: ISharepointRequest) => {
    try {
      const createFolderDataReq = helpers.createFolderDataHelper(
        createFolderReqData.parentFolder,
        createFolderReqData.folderName,
        createFolderReqData.documentLibrary,
        createFolderReqData.accessToken
      );
      const createFolderResponse = await services.createFolderSharePoint(
        createFolderDataReq
      );
      const isValid =
        helpers.createFolderSuccessValidation(createFolderResponse);
      const createFolderRes: ISharepointResponse = {
        createFolderResponse: isValid,
        uploadFileResponse: [],
        mccListResponse: '',
        cachedToken: '',
        deleteDocumentResponse: false,
      };
      if (isValid) {
        data = createFolderRes;
      }
    } catch (error) {
      return data;
    }
    return data;
  };

  const uploadFile = async (
    uploadFileReqData: ISharepointRequest,
    files: File[],
    successMessage: string
  ) => {
    try {
      let withSuccessUpload = false;
      const failedFiles: string[] = [];
      await Promise.all(
        files.map(async (value) => {
          const { name: fileName } = value;
          const fileStream = await getFileDataUrl(value);

          const uploadData = helpers.uploadFileDataHelper(
            uploadFileReqData.parentFolder,
            fileStream,
            fileName,
            uploadFileReqData.accessToken,
            uploadFileReqData.createdBy.trim(),
            uploadFileReqData.folderName,
            uploadFileReqData.documentLibrary
          );
          const result = await actions.uploadSharePoint(uploadData);
          if (result.payload) {
            const isUploaded = helpers.fileUploadSuccessValidation(
              result.payload
            );
            if (!isUploaded) {
              failedFiles.push(fileName);
              const uploadResponse: ISharepointResponse = {
                uploadFileResponse: failedFiles,
                mccListResponse: '',
                cachedToken: '',
                createFolderResponse: false,
                deleteDocumentResponse: false,
              };
              data = uploadResponse;
            } else {
              withSuccessUpload = true;
            }
          }
        })
      );
      if (withSuccessUpload) {
        ToastSuccess(successMessage, {
          position: ITostPosition.BottomRight,
        });
      }
    } catch (error) {
      return data;
    }
    return data;
  };

  const deleteDocument = async (deleteDocumentReqData: ISharepointRequest) => {
    try {
      const deleteDocDataReq = helpers.deleteDocumentDataHelper(
        deleteDocumentReqData.parentFolder,
        deleteDocumentReqData.folderName,
        deleteDocumentReqData.fileId,
        deleteDocumentReqData.documentId,
        deleteDocumentReqData.documentLibrary,
        deleteDocumentReqData.fileName,
        deleteDocumentReqData.accessToken
      );
      const deleteDocumentResponse = await services.deleteDocumentSharepoint(
        deleteDocDataReq
      );

      const isFileSuccessfullyDeleted = helpers.deleteDocumentSuccessValidation(
        deleteDocumentResponse
      );
      const deleteDocumentRes: ISharepointResponse = {
        uploadFileResponse: [],
        mccListResponse: '',
        createFolderResponse: false,
        cachedToken: '',
        deleteDocumentResponse: isFileSuccessfullyDeleted,
      };
      data = deleteDocumentRes;
    } catch (error) {
      return data;
    }
    return data;
  };

  const failedUpload = (failedFiles) => {
    let failed = '';
    if (failedFiles) {
      failedFiles.map((fileName) => {
        failed += `${fileName}, `;
      });
      if (failed) {
        ToastError(
          `Failed to upload file(s) - ${
            failed ? failed.substring(0, failed.length - 2) : ''
          }`,
          {
            position: ITostPosition.BottomRight,
          }
        );
      }
    }
  };

  const handleCachedToken = async (latestDate: Date) => {
    const cachedTokenValue =
      JSON.parse(
        sessionStorage.getItem(
          (SharePointAccessTokenSession.SHAREPOINT_ACCESS_TOKEN as any) ?? ''
        ) as any
      ) ?? undefined;
    const accessToken: IFileSharePointType =
      (cachedTokenValue?.cachedToken && cachedTokenValue?.cachedToken) ??
      undefined;
    if (!accessToken) {
      await actions?.tokenGET();
      const newToken =
        JSON.parse(
          sessionStorage.getItem(
            (SharePointAccessTokenSession.SHAREPOINT_ACCESS_TOKEN as any) ?? ''
          ) as any
        ) ?? undefined;
      const token: ISharepointResponse = {
        uploadFileResponse: [],
        mccListResponse: '',
        createFolderResponse: false,
        deleteDocumentResponse: false,
        cachedToken: JSON.stringify(newToken?.cachedToken),
      };
      data = token;
    } else {
      const lastTimeFetched = moment(cachedTokenValue.timeLastFetched).toDate();
      const latestTimeFetched = moment(latestDate).toDate();
      const minuteDuration = checkCachedTokenDuration(
        lastTimeFetched,
        latestTimeFetched
      );
      const cachedTokenData = cachedTokenValue as IFileSharePointType;
      if (minuteDuration > 45 || !cachedTokenData) {
        await actions?.tokenGET();
        const newToken =
          JSON.parse(
            sessionStorage.getItem(
              (SharePointAccessTokenSession.SHAREPOINT_ACCESS_TOKEN as any) ??
                ''
            ) as any
          ) ?? undefined;
        const token: ISharepointResponse = {
          uploadFileResponse: [],
          mccListResponse: '',
          createFolderResponse: false,
          deleteDocumentResponse: false,
          cachedToken: JSON.stringify(newToken?.cachedToken),
        };
        data = token;
      } else {
        const token: ISharepointResponse = {
          uploadFileResponse: [],
          mccListResponse: '',
          createFolderResponse: false,
          deleteDocumentResponse: false,
          cachedToken: JSON.stringify(accessToken),
        };
        data = token;
      }
    }
    return data;
  };

  const getCachedAccessToken = async (latestDate: Date) => {
    const accessToken = await handleCachedToken(latestDate);
    const token = accessToken?.cachedToken?.replace(/"/g, '');
    return token ?? '';
  };

  const submitUpload = async (request: SharePointSubmitRequestType) => {
    if (request.files.length) {
      const settingData = (await getSharePointSettingProperty(
        request.applicationType,
        request.setting
      )) as unknown as ISharepointProperty;
      const token = await getCachedAccessToken(new Date());
      const dataRequestValue: ISharepointRequest = {
        ...request.requestData,
        accessToken: token ?? '',
        parentFolder: request.withParentFolder
          ? getParentFolderName(request.requestData?.requestId)
          : '',
        documentLibrary: settingData.documentLibraryName,
        documentId: settingData.documentLibraryId,
      };
      if (token.length) {
        const isFolderCreated = await createFolder(dataRequestValue);
        if (isFolderCreated?.createFolderResponse) {
          const uploadResponse = await uploadFile(
            dataRequestValue,
            request.files,
            request.files.length > 1
              ? 'File(s) uploaded successfully'
              : 'File uploaded successfully'
          );
          failedUpload(uploadResponse.uploadFileResponse);
          actions.deleteAllStashedFiles();
          if (uploadResponse) {
            if (request.callback) {
              request.callback();
            }
          }
          if (request.setIsUpdated) {
            request.setIsUpdated(true);
          }
        } else {
          actions.deleteAllStashedFiles();
        }
      }
      if (request.setIsUpdated) {
        request.setIsUpdated(false);
      }
    }
  };

  const fetchMccList = async (
    request: SharePointSubmitRequestType
  ): Promise<IFileList> => {
    let dataFileList: IFileList = {
      fileList: [],
      withError: false,
    };
    actions?.hasSharepointError(false);

    if (request) {
      const token = await getCachedAccessToken(new Date());
      const setting = (await getSharePointSettingProperty(
        request.applicationType,
        request.setting
      )) as unknown as ISharepointProperty;
      const list = await mccList({
        parentFolder: request.withParentFolder
          ? getParentFolderName(request?.requestData?.requestId)
          : '',
        folderName: JSON.stringify(request?.requestData?.requestId) || '',
        documentLibrary: setting.documentLibraryName || '',
        accessToken: (token as unknown as any) || '',
        createdBy: '',
        documentId: setting.documentLibraryId || '',
        fileId: '',
        fileName: '',
      });

      const fileList: IFileSharePoint[] = [];
      if (list?.mccListResponse.length) {
        Object.entries(JSON.parse(list?.mccListResponse)).forEach(
          (urlEntry) => {
            const [, url] = urlEntry as unknown as IUploadedFileData[];
            const fileLocation = JSON.stringify(url.fileName).replace(/"/g, '');
            let uploadedBy = '';
            const fileName = JSON.stringify(url.fileName)
              .replace(/"/g, '')
              .split('/');
            const createdDate = JSON.stringify(url.uploadedDate)
              .replace(/\s/g, '#')
              .split('#');
            if (
              url.uploadedBy.length &&
              (url.uploadedBy.includes('Created by')
              || url.uploadedBy.includes('Updated by'))
            ) {
              uploadedBy = url.uploadedBy;
            }
            if (uploadedBy.trim().length) {
              uploadedBy =
                uploadedBy.split('by').length > 1
                  ? uploadedBy.split('by')[1].trim()
                  : '';
              uploadedBy = uploadedBy.split('_').length
                ? uploadedBy.split('_')[0].trim()
                : uploadedBy;
            }
            const fileInfo: IFileSharePoint = {
              uploadDate: formatDate(createdDate.length ? createdDate[1] : ''),
              docName: fileName.length ? fileName[fileName.length - 1] : '',
              uploadedBy,
              documentLibraryName: setting.documentLibraryName,
              folderName: JSON.stringify(request?.requestData?.requestId),
              listName: setting.documentLibraryId,
              fileId: url.fileId,
              fileName: fileName.length ? fileName[fileName.length - 1] : '',
              requestId: request?.requestData?.requestId,
              id: undefined,
              uploadId: undefined,
              app: undefined,
              container: undefined,
              environment: undefined,
              message: undefined,
              uploadTypeId: undefined,
              parentFolderName: request.withParentFolder
                ? getParentFolderName(request?.requestData?.requestId)
                : '',
              createdBy: uploadedBy.length > 0 ? uploadedBy : '-',
              isEditView: false,
              downloadType: SharePointDownloadType.MCC_SHAREPOINT_TYPE,
              fileUrl: fileLocation,
              targetFile: '',
            };
            fileList.push(fileInfo);
          }
        );

        dataFileList = {
          fileList,
          withError: false,
        };
        actions?.hasSharepointError(false);
      } else {
        dataFileList = {
          fileList: [],
          withError: true,
        };
        actions?.hasSharepointError(true);
      }
      if (request?.setMccListFile) {
        request.setMccListFile(fileList);
      }

      return dataFileList;
    }

    return dataFileList;
  };

  return {
    mccList,
    uploadFile,
    createFolder,
    deleteDocument,
    failedUpload,
    submitUpload,
    fetchMccList,
    handleCachedToken,
    getCachedAccessToken,
  };
};

export { useSharepoint };
