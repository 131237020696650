import React, { useEffect } from 'react';
import { useSickListContext } from 'src/contexts/sick-list.context';
import { sickListActionTypes } from 'src/ducks/sick-list.duck';
import { getSickListStatus } from 'src/selectors/sick-list.selector';
import { ToastError } from 'src/components/atoms/toaster/toaster.component';
import SickListListView from './sick-list-list.view';

const SickListList: React.FC = () => {
  const { state, actions } = useSickListContext();

  const status = getSickListStatus(
    state,
    sickListActionTypes.SICK_LIST_LIST_READ
  );

  useEffect(() => {
    const fetchData = async () => {
      const result = await actions.listGET();
      if (result.error) {
        ToastError('Failed to retrieve the Sick List.');
      }
    };

    fetchData();
  }, []);

  return <SickListListView data={state.list} loading={status.fetching} />;
};

export default SickListList;
