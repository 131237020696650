import { IORGDataTableQueryState } from 'shared-it-appmod-ui';
import { RealTimeLogAuditTab } from 'src/constants/real-time-log.constant';
import { IRealTimeLogAuditAll } from 'src/models/real-time-log-audit.model';
import Moment from 'src/libraries/moment.library';
import { IStatus } from 'src/libraries/thunk.library';
import {
  IRealTimeLogAuditState,
  realTimeLogAuditActionTypes,
} from 'src/ducks/real-time-log-audit.duck';

export const getRealTimeLogAuditStatus = (
  state: IRealTimeLogAuditState,
  action: keyof typeof realTimeLogAuditActionTypes
): IStatus =>
  state.status[action] ?? {
    fetching: false,
    error: null,
  };

export const getRealTimeLogAuditParams = (
  params: Partial<IORGDataTableQueryState>,
  currentTab?: RealTimeLogAuditTab,
  filterAll?: IRealTimeLogAuditAll
): Partial<IORGDataTableQueryState> => {
  const { keyword, ...temp } = params;
  const data = temp;

  if (data.filters) {
    const updatedAt = data.filters?.filter((val) => val.name === 'updatedAt');
    const updatedBy = data.filters?.filter((val) => val.name === 'updatedBy');
    const auditType = data.filters?.filter((val) => val.name === 'auditType');
    const isAll = data.filters?.filter((val) => val.name === 'isAll');

    if (
      updatedAt &&
      updatedAt.length > 0 &&
      updatedAt[0].value &&
      updatedAt[0].value.length > 0
    ) {
      // eslint-disable-next-line eqeqeq
      data.filters = data.filters?.filter((val) => val.name !== 'updatedAt');
      data.filters = [
        ...(data.filters ?? []),
        {
          name: 'updatedAt',
          value: [
            Moment(String(updatedAt[0].value[0])).startOf('day').toDate(),
            Moment(String(updatedAt[0].value[1] ?? updatedAt[0].value[0]))
              .endOf('day')
              .toDate(),
          ],
        },
      ];
    }

    if (auditType && auditType.length > 0) {
      // eslint-disable-next-line eqeqeq
      data.filters = data.filters?.filter((val) => val.name !== 'auditType');
      data.filters = [
        ...(data.filters ?? []),
        {
          name: 'auditType',
          value: auditType?.[0].value,
        },
      ];
    }

    if (updatedBy && updatedBy.length > 0) {
      // eslint-disable-next-line eqeqeq
      data.filters = data.filters?.filter((val) => val.name !== 'updatedBy');
      data.filters = [
        ...(data.filters ?? []),
        {
          name: 'updatedBy',
          value: updatedBy?.[0].value,
        },
      ];
    }

    if (isAll && isAll?.length > 0) {
      // eslint-disable-next-line eqeqeq
      data.filters = data.filters?.filter((val) => val.name !== 'isAll');
    }
  }

  if (filterAll) {
    data.filters = [
      {
        name: 'updatedAt',
        value: [
          Moment(String(filterAll?.updatedAt ? filterAll?.updatedAt[0] : ''))
            .startOf('day')
            .toDate(),
          Moment(
            String(() => {
              let endDate;
              if (filterAll?.updatedAt && filterAll?.updatedAt[0]) {
                if (filterAll?.updatedAt[1]) {
                  endDate = filterAll?.updatedAt[1];
                } else {
                  endDate = filterAll?.updatedAt[0];
                }
              }
              return endDate ?? '';
            })
          )
            .endOf('day')
            .toDate(),
        ],
      },
      {
        name: 'updatedBy',
        value: filterAll?.updatedBy,
      },
      {
        name: 'auditType',
        value: filterAll?.auditType,
      },
    ];
  }

  switch (currentTab) {
    case RealTimeLogAuditTab.All: {
      data.filters = [
        ...(data.filters ?? []),
        {
          name: 'isAll',
          value: true,
        },
      ];
      break;
    }

    // Note: Commenting this if currrentTab is 0 (today) to unset 0isAll (and prevent showing isAll as filter in Today's tab)
    // case RealTimeLogAuditTab.Today: {
    //   data.filters = [
    //     ...(data.filters ?? []),
    //     {
    //       name: 'isAll',
    //       value: false,
    //     },
    //   ];
    //   break;
    // }

    default: {
      break;
    }
  }

  return data;
};
