/* eslint-disable no-lone-blocks */
/* eslint-disable react/button-has-type */
// import { Editor } from '@tiptap/react';
import React, { useRef } from 'react';
import { ATMButton, ATMDropdown } from 'shared-it-appmod-ui';
import { IRealTimeLog } from 'src/models/real-time-log.model';
import styles from './editor.module.scss';
import { Editor } from '@tiptap/core';

type IProps = {
  data?: IRealTimeLog[];
  editor?: Editor | null;
  withDate?: boolean;
  date?: Date;
  setDate?: React.Dispatch<React.SetStateAction<Date>>;
  isPopup?: boolean;
  isDefault?: boolean;
};

const MenuBar: React.FC<IProps> = ({ editor, isPopup, isDefault }) => {
  const formRef = useRef<HTMLFormElement>(null);
  {
    if (!isDefault) {
      return (
        <>
          <div className={isPopup ? styles.menuBarPopup : styles.menuBar}>
            <ATMButton
              type="button"
              icon="redo"
              onClick={() => {
                return editor?.chain().focus().redo().run();
              }}
              disabled={!editor?.can().chain().focus().undo().run()}
              className={editor?.isActive('redo') ? 'is-active' : ''}
            />
            <ATMButton
              type="button"
              icon="undo"
              onClick={() => {
                return editor?.chain().focus().undo().run();
              }}
              disabled={!editor?.can().chain().focus().undo().run()}
              className={editor?.isActive('undo') ? 'is-active' : ''}
            />
            <ATMButton
              type="button"
              icon="bold"
              onClick={() => {
                return editor?.chain().focus().toggleBold().run();
              }}
              disabled={!editor?.can().chain().focus().toggleBold().run()}
              className={editor?.isActive('bold') ? 'is-active' : ''}
            />
            <ATMButton
              type="button"
              icon="italic"
              onClick={() => {
                return editor?.chain().focus().toggleItalic().run();
              }}
              className={editor?.isActive('italic') ? 'is-active' : ''}
            />
            <ATMButton
              type="button"
              icon="underline"
              onClick={() => {
                return editor?.chain().focus().toggleUnderline().run();
              }}
              className={editor?.isActive('underline') ? 'is-active' : ''}
            />
            {!isPopup ? (
              <ATMButton
                type="button"
                icon="window minimize outline"
                onClick={() => {
                  return editor?.chain().focus().setHorizontalRule().run();
                }}
                className={
                  editor?.isActive('horizontalRule') ? 'is-active' : ''
                }
              />
            ) : (
              ''
            )}
            <ATMButton
              type="button"
              icon="paint brush"
              onClick={() => formRef.current?.click()}
            />
            <input
              className={
                isPopup
                  ? styles.fontColorSelectorPopup
                  : styles.fontColorSelector
              }
              ref={formRef as any}
              type="color"
              onChange={(e) =>
                editor?.chain().focus().setColor(e?.target?.value).run()
              }
              value={editor?.getAttributes('textStyle').color}
              data-testid="setColor"
              list="presetColors"
            />
            <div
              className={
                isPopup
                  ? styles.presetColorPaletteDark
                  : styles.presetColorPalette
              }
            >
              <datalist id="presetColors">
                <option>#ff0000</option>
                <option>#00ff00</option>
                <option>#0000ff</option>
                <option>#fff000</option>
                <option>#031858</option>
              </datalist>
            </div>
            <ATMButton
              type="button"
              icon="list ul"
              onClick={() => {
                return editor?.chain().focus().toggleBulletList().run();
              }}
              className={editor?.isActive('bulletList') ? 'is-active' : ''}
            />
            <ATMButton
              type="button"
              icon="numbered list"
              onClick={() => {
                return editor?.chain().focus().toggleOrderedList().run();
              }}
              className={editor?.isActive('orderedList') ? 'is-active' : ''}
            />
            <>
              {isPopup ? (
                <ATMDropdown
                  size="small"
                  icon="ellipsis vertical"
                  upward
                  className={styles.textAlignment}
                >
                  <ATMDropdown.Menu>
                    <div className={styles.menuBarPopup}>
                      <ATMButton
                        type="button"
                        icon="align left"
                        onClick={() => {
                          return editor
                            ?.chain()
                            .focus()
                            .setTextAlign('left')
                            .run();
                        }}
                        className={
                          editor?.isActive({ textAlign: 'left' })
                            ? 'is-active'
                            : ''
                        }
                      />
                      <ATMButton
                        type="button"
                        icon="align center"
                        onClick={() => {
                          return editor
                            ?.chain()
                            .focus()
                            .setTextAlign('center')
                            .run();
                        }}
                        className={
                          editor?.isActive({ textAlign: 'center' })
                            ? 'is-active'
                            : ''
                        }
                      />
                      <ATMButton
                        type="button"
                        icon="align right"
                        onClick={() => {
                          return editor
                            ?.chain()
                            .focus()
                            .setTextAlign('right')
                            .run();
                        }}
                        className={
                          editor?.isActive({ textAlign: 'right' })
                            ? 'is-active'
                            : ''
                        }
                      />
                      <ATMButton
                        type="button"
                        icon="align justify"
                        onClick={() => {
                          return editor
                            ?.chain()
                            .focus()
                            .setTextAlign('justify')
                            .run();
                        }}
                        className={
                          editor?.isActive({ textAlign: 'justify' })
                            ? 'is-active'
                            : ''
                        }
                      />
                      <ATMButton
                        type="button"
                        icon="window minimize outline"
                        onClick={() => {
                          return editor
                            ?.chain()
                            .focus()
                            .setHorizontalRule()
                            .run();
                        }}
                        className={
                          editor?.isActive('horizontalRule') ? 'is-active' : ''
                        }
                      />
                    </div>
                  </ATMDropdown.Menu>
                </ATMDropdown>
              ) : (
                <>
                  <ATMButton
                    type="button"
                    icon="align left"
                    onClick={() => {
                      return editor?.chain().focus().setTextAlign('left').run();
                    }}
                    className={
                      editor?.isActive({ textAlign: 'left' }) ? 'is-active' : ''
                    }
                  />
                  <ATMButton
                    type="button"
                    icon="align center"
                    onClick={() => {
                      return editor
                        ?.chain()
                        .focus()
                        .setTextAlign('center')
                        .run();
                    }}
                    className={
                      editor?.isActive({ textAlign: 'center' })
                        ? 'is-active'
                        : ''
                    }
                  />
                  <ATMButton
                    type="button"
                    icon="align right"
                    onClick={() => {
                      return editor
                        ?.chain()
                        .focus()
                        .setTextAlign('right')
                        .run();
                    }}
                    className={
                      editor?.isActive({ textAlign: 'right' })
                        ? 'is-active'
                        : ''
                    }
                  />
                  <ATMButton
                    type="button"
                    icon="align justify"
                    onClick={() => {
                      return editor
                        ?.chain()
                        .focus()
                        .setTextAlign('justify')
                        .run();
                    }}
                    className={
                      editor?.isActive({ textAlign: 'justify' })
                        ? 'is-active'
                        : ''
                    }
                  />
                </>
              )}
            </>
          </div>
        </>
      );
    }
  }
  return (
    <>
      <div className={isPopup ? styles.menuBarPopup : styles.menuBar}>
        <ATMButton
          icon="redo"
          onClick={() => {
            return editor?.chain().focus().redo().run();
          }}
          disabled={!editor?.can().chain().focus().undo().run()}
          className={editor?.isActive('redo') ? 'is-active' : ''}
        />
        <ATMButton
          icon="undo"
          onClick={() => {
            return editor?.chain().focus().undo().run();
          }}
          disabled={!editor?.can().chain().focus().undo().run()}
          className={editor?.isActive('undo') ? 'is-active' : ''}
        />
        <ATMButton
          icon="bold"
          onClick={() => {
            return editor?.chain().focus().toggleBold().run();
          }}
          disabled={!editor?.can().chain().focus().toggleBold().run()}
          className={editor?.isActive('bold') ? 'is-active' : ''}
        />
        <ATMButton
          icon="italic"
          onClick={() => {
            return editor?.chain().focus().toggleItalic().run();
          }}
          className={editor?.isActive('italic') ? 'is-active' : ''}
        />
        <ATMButton
          icon="underline"
          onClick={() => {
            return editor?.chain().focus().toggleUnderline().run();
          }}
          className={editor?.isActive('underline') ? 'is-active' : ''}
        />
        <ATMButton
          icon="list ul"
          onClick={() => {
            return editor?.chain().focus().toggleBulletList().run();
          }}
          className={editor?.isActive('bulletList') ? 'is-active' : ''}
        />
        <ATMButton
          icon="numbered list"
          onClick={() => {
            return editor?.chain().focus().toggleOrderedList().run();
          }}
          className={editor?.isActive('orderedList') ? 'is-active' : ''}
        />
      </div>
    </>
  );
};

export default MenuBar;
