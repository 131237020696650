// import { format } from 'date-fns';
// import { REAL_TIME_LOG_EMAIL_FIELD_LABEL } from 'src/constants/real-time-log.constant';
// import {
//   IForcedOutageConfirmationForm,
//   IRealTimeLog,
//   IRealTimeLogFieldModel,
// } from 'src/models/real-time-log.model';

// export const dateTimeFormat = 'MMM dd, yyyy (EEE) HH:mm';

// export const removeComma = (data: any) => {
//   return data?.toString()?.replace(/,/g, '') ?? '';
// };

export const generateSystemChangeEmailFormDetails = (
  isComplete: boolean
): string => {
  if (isComplete) {
    return 'System Change has been completed. ';
  }

  return 'System Change has been set back to In Progress. ';
  // const NL = '\n';
  // const mailBody: Record<
  //   REAL_TIME_LOG_EMAIL_FIELD_LABEL,
  //   IRealTimeLogFieldModel
  // > = {
  //   [REAL_TIME_LOG_EMAIL_FIELD_LABEL.DATE_TIME]: {
  //     fieldTitle: REAL_TIME_LOG_EMAIL_FIELD_LABEL.DATE_TIME,
  //     fieldValue: data.logDtTm ? format(data.logDtTm, dateTimeFormat) : '',
  //   },
  //   [REAL_TIME_LOG_EMAIL_FIELD_LABEL.CATEGORY]: {
  //     fieldTitle: REAL_TIME_LOG_EMAIL_FIELD_LABEL.CATEGORY,
  //     fieldValue: data.logCategory,
  //   },
  //   [REAL_TIME_LOG_EMAIL_FIELD_LABEL.LINE]: {
  //     fieldTitle: REAL_TIME_LOG_EMAIL_FIELD_LABEL.LINE,
  //     fieldValue: data.facility?.outgFacNm,
  //   },
  //   [REAL_TIME_LOG_EMAIL_FIELD_LABEL.SUBSTATION]: {
  //     fieldTitle: REAL_TIME_LOG_EMAIL_FIELD_LABEL.SUBSTATION,
  //     fieldValue: `${data.substation?.name as any} ${NL}${
  //       REAL_TIME_LOG_EMAIL_FIELD_LABEL.EQUIPMENT
  //     } ${data.facility?.outgFacNm} - ${data.facility?.volt?.voltNm}kV - ${
  //       data.facility?.facTyp?.facTypNm
  //     }`,
  //   },
  //   [REAL_TIME_LOG_EMAIL_FIELD_LABEL.EQUIPMENT_TYPE]: {
  //     fieldTitle: REAL_TIME_LOG_EMAIL_FIELD_LABEL.EQUIPMENT_TYPE,
  //     fieldValue: data.facility?.facTyp?.facTypNm,
  //   },
  //   [REAL_TIME_LOG_EMAIL_FIELD_LABEL.VOLTAGE]: {
  //     fieldTitle: REAL_TIME_LOG_EMAIL_FIELD_LABEL.VOLTAGE,
  //     fieldValue: `${data.facility?.volt?.voltNm}kV`,
  //   },
  //   [REAL_TIME_LOG_EMAIL_FIELD_LABEL.EQUIPMENT]: {
  //     fieldTitle: REAL_TIME_LOG_EMAIL_FIELD_LABEL.EQUIPMENT,
  //     fieldValue: data.facility?.outgFacNm,
  //   },
  //   [REAL_TIME_LOG_EMAIL_FIELD_LABEL.SUBS_INTERRUPTED]: {
  //     fieldTitle: REAL_TIME_LOG_EMAIL_FIELD_LABEL.SUBS_INTERRUPTED,
  //     fieldValue: data.forcedLogSubs?.length
  //       ? `${data?.forcedLogSubs
  //           ?.map((val) => ` ${val.substation.name}`)
  //           .toString()
  //           .trim()}`
  //       : 'None',
  //   },
  //   [REAL_TIME_LOG_EMAIL_FIELD_LABEL.DATE_TIME_OCCURED]: {
  //     fieldTitle: REAL_TIME_LOG_EMAIL_FIELD_LABEL.DATE_TIME_OCCURED,
  //     fieldValue: data.logDtTmFrcdOutOccurred
  //       ? format(data.logDtTmFrcdOutOccurred, dateTimeFormat)
  //       : '',
  //   },
  //   [REAL_TIME_LOG_EMAIL_FIELD_LABEL.DATE_TIME_RECOVERED]: {
  //     fieldTitle: REAL_TIME_LOG_EMAIL_FIELD_LABEL.DATE_TIME_RECOVERED,
  //     fieldValue: data.logDtTmFrcdOutRestored
  //       ? format(data.logDtTmFrcdOutRestored, dateTimeFormat)
  //       : '',
  //   },
  //   [REAL_TIME_LOG_EMAIL_FIELD_LABEL.LOG_ENTRY]: {
  //     fieldTitle: REAL_TIME_LOG_EMAIL_FIELD_LABEL.LOG_ENTRY,
  //     fieldValue: data.logEntry?.length ? data.logEntry : '',
  //   },
  // };

  // let content = '';
  // let subject = 'Real-Time Interruption: ';

  // Object.keys(mailBody).forEach((keyValue) => {
  //   const field = mailBody[keyValue as REAL_TIME_LOG_EMAIL_FIELD_LABEL];

  //   switch (keyValue) {
  //     case REAL_TIME_LOG_EMAIL_FIELD_LABEL.DATE_TIME:
  //       content += `${field.fieldTitle} ${field.fieldValue}\n`;
  //       break;
  //     case REAL_TIME_LOG_EMAIL_FIELD_LABEL.CATEGORY: {
  //       content += `${field.fieldTitle} ${field.fieldValue}\n`;
  //       content += '\n';
  //       break;
  //     }
  //     case REAL_TIME_LOG_EMAIL_FIELD_LABEL.LINE: {
  //       if (!data.substation) {
  //         subject += `${data.facility?.volt?.voltNm}kV ${data.facility?.outgFacNm}`;
  //         content += `${field.fieldTitle} ${field.fieldValue}\n`;
  //         content += '\n';
  //       }
  //       break;
  //     }
  //     case REAL_TIME_LOG_EMAIL_FIELD_LABEL.SUBSTATION: {
  //       if (data.substation) {
  //         subject += `${data.facility?.volt?.voltNm}kV ${
  //           data.substation?.name as any
  //         }`;
  //         content += `${field.fieldTitle} ${field.fieldValue}\n`;
  //         content += '\n';
  //       }
  //       break;
  //     }
  //     case REAL_TIME_LOG_EMAIL_FIELD_LABEL.SUBS_INTERRUPTED:
  //       if (field.fieldValue?.length) {
  //         content += `${field.fieldTitle}\n${field.fieldValue}\n`;
  //       }
  //       break;
  //     case REAL_TIME_LOG_EMAIL_FIELD_LABEL.DATE_TIME_OCCURED:
  //       if (field.fieldValue?.length) {
  //         content += '\n';
  //         content += `${field.fieldTitle} ${field.fieldValue}\n`;
  //       }
  //       break;
  //     case REAL_TIME_LOG_EMAIL_FIELD_LABEL.DATE_TIME_RECOVERED: {
  //       if (field.fieldValue?.length) {
  //         content += `${field.fieldTitle} ${field.fieldValue}\n`;
  //       }
  //       break;
  //     }
  //     case REAL_TIME_LOG_EMAIL_FIELD_LABEL.LOG_ENTRY:
  //       if (field.fieldValue?.length) {
  //         content += '\n';
  //         content += `${field.fieldTitle}\n${field.fieldValue}`;
  //         content += '\n';
  //       }
  //       break;
  //     default:
  //       break;
  //   }
  // });

  // return {
  //   logId: data.logId as number,
  //   mailSubject: subject,
  //   mailContent: content,
  // };
};
