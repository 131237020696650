import { AlignmentType } from 'docx';

export const BPOR_REPORT_FOOTER = 'This e-mail and/or attachment(s) contain Non-Public Transmission Information covered by the FERC Standards of Conduct. This communication may NOT be sent or disclosed in any fashion to Marketing Affiliates (including SDG&E’s E&G Procurement and SoCal’s Gas Acquisitions) or Energy Affiliates. SDG&E’s Energy and Marketing Affiliates are listed on SDG&E’s OASIS website at <http://www.sdge.com/toforum/parentCorp.shtml> and <http://www.sdge.com/toforum/transmissionMarketing.shtml>.';
export const BPOR_REPORT_FOOTER_CLASSIFICATION = 'Security classification: INTERNAL';
export const OPERATIONS_REPORT = 'OPERATIONS REPORT';
export const BULK_POWER = 'BULK POWER';
export enum ELEMENT_NODE {
    PARAGRAPH = 'P',
    SPAN = 'SPAN',
    ORDERED_LIST = 'OL',
    UNORDERED_LIST = 'UL',
    HORIZONTAL_RULE = 'HR',
    HEADING_LEVEL_1 = 'H1',
    UNDERLINE = 'U',
    ITALIC = 'EM',
    BOLD = 'STRONG',
    TEXT = '#text',
}

export type ElementAttribute = {
    Alignment: typeof AlignmentType;
    Color: string;
};

export enum FONT_FAMILY {
    TAHOMA = 'Tahoma',
    ARIAL = 'Arial',
    ARIAL_BLACK = 'Arial Black',
    TIMES_NEW_ROMAN = 'Times New Roman',
}
