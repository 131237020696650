import React, { useCallback, useEffect, useState } from 'react';
import {
  ATMButton,
  ATMCheckbox,
  ATMDatePicker,
  ATMField,
  ATMForm,
  ATMFormProvider,
  ATMGrid,
  ATMHeader,
  ATMInput,
  ATMModal,
  ATMSegment,
  ATMTextArea,
  formatTime,
  MOLReadonlyField,
  useATMFormContext,
} from 'shared-it-appmod-ui';
import Lang from 'src/libraries/language';
import Moment from 'src/libraries/moment.library';
import {
  ISystemChange,
  IsystemChangeCompleteForm,
  SystemChangeCompleteFormSchema,
} from 'src/models/system-change.model';
import LabelRequired from 'src/components/atoms/label-required/label-required.component';
import { getUser } from 'src/libraries/amplify.library';
import { REGEX_24HR_TIME } from 'src/constants/regex.constant';
import { generateSystemChangeEmailFormDetails } from 'src/helpers/system-change.helper';
import styles from './system-change-item.module.scss';

type IProps = {
  data: ISystemChange;
  formRef: React.RefObject<HTMLFormElement>;
  handleSubmit: (data: IsystemChangeCompleteForm) => void;
  handleClick: () => void;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  loading: boolean;
  source?: string;
};

type IFormContent = {
  requestIdTemp: number;
  isChecked: boolean;
  loading: boolean;
};

const FormContent: React.FC<IFormContent> = ({
  requestIdTemp,
  isChecked,
  loading,
}) => {
  const {
    formState: { errors },
    control,
    getValues,
    setValue,
  } = useATMFormContext<IsystemChangeCompleteForm>();
  const [currentCompletedOn] = useState<Date | null | undefined>(
    getValues('completedOn')
  );
  const [dateSelected, setDateSelected] = useState<Date | null>(null);

  useEffect(() => {
    setValue('requestId', requestIdTemp);
    setValue('isComplete', isChecked);
    setValue('completionSetBy', isChecked ? getUser()?.emp_no : null);
    setValue('completedOn', isChecked ? new Date() : null);
    setValue('completedOnDate', isChecked ? new Date() : null);
  }, [requestIdTemp, isChecked]);

  const handleDateSelect = useCallback((year, month, day, hour?, min?) => {
    if (hour !== undefined && min !== undefined) {
      setDateSelected(new Date(year, month, day, hour, min));
      setValue('completedOn', new Date(year, month, day, hour, min));
    } else {
      setDateSelected(new Date(year, month, day));
      setValue('completedOnDate', new Date(year, month, day));
    }
  }, []);

  return (
    <ATMGrid>
      <ATMGrid.Row columns={1}>
        <ATMGrid.Column>
          {isChecked ? (
            <p>
              {Lang.formatString(
                Lang.MSG_SYSTEM_CHANGE_CONFIRM_COMPLETION,
                requestIdTemp
              )}
            </p>
          ) : (
            <p>
              {Lang.formatString(
                Lang.MSG_SYSTEM_CHANGE_CANCEL_COMPLETION,
                requestIdTemp
              )}
            </p>
          )}
        </ATMGrid.Column>
      </ATMGrid.Row>
      {!isChecked ? (
        <ATMGrid.Row columns={1} style={{ paddingBottom: '0em' }}>
          <ATMGrid.Column>
            <MOLReadonlyField
              label={Lang.LBL_COMPLETED_ON}
              value={Moment(currentCompletedOn).format('MM/DD/YYYY HH:mm')}
            />
          </ATMGrid.Column>
        </ATMGrid.Row>
      ) : (
        <ATMGrid.Row columns={3}>
          <ATMGrid.Column>
            <ATMField
              as={ATMDatePicker}
              label={<LabelRequired>{Lang.LBL_COMPLETE_ON}</LabelRequired>}
              dateFormat="MM-DD-YYYY"
              maxDate={new Date()}
              className={styles.datePickerStyle}
              isDateTimePicker
              dateTimePlaceHolder="Select"
              control={control}
              name="completedOnDate"
              error={errors.completedOnDate}
              clearable={false}
              disabled={loading}
              onChange={([_, val]) => {
                if (!val.value) {
                  setValue('completedOnDate', null);
                } else {
                  const day = val?.value?.getDate();
                  const month = val?.value?.getMonth();
                  const year = val?.value?.getFullYear();

                  handleDateSelect(year, month, day);
                }

                return val.value;
              }}
            />
          </ATMGrid.Column>
          <ATMGrid.Column>
            <ATMField
              as={ATMInput}
              name="completedOnTime"
              label={<LabelRequired>{Lang.LBL_TIME}</LabelRequired>}
              control={control}
              autoComplete="off"
              className={styles.timeStyle}
              error={errors.completedOnTime}
              disabled={!getValues('completedOnDate') || loading}
              maxLength={5}
              placeholder="hh:mm"
              clearable
              onChange={([_, { value }]) => {
                const formattedTime = formatTime(value);
                if (formattedTime) {
                  const regArray = REGEX_24HR_TIME.exec(formattedTime);

                  const day = dateSelected?.getDate();
                  const month = dateSelected?.getMonth();
                  const year = dateSelected?.getFullYear();

                  if (regArray && regArray.length === 2) {
                    const hour = Number(formattedTime.split(':')[0]);
                    const min = Number(formattedTime.split(':')[1]);

                    if (!(hour > 23 || min > 59)) {
                      handleDateSelect(year, month, day, hour, min);
                    } else {
                      handleDateSelect(year, month, day);
                    }
                  } else {
                    handleDateSelect(year, month, day);
                  }
                }
                return formattedTime;
              }}
            />
          </ATMGrid.Column>
        </ATMGrid.Row>
      )}
      <ATMGrid.Row style={{ paddingTop: '0em' }}>
        <ATMGrid.Column>
          <ATMField
            as={ATMTextArea}
            label={
              <span className={styles.emphasizedSmall}>
                You may edit the details before sending
              </span>
            }
            name="mailContent"
            maxLength={5000}
            control={control}
            error={errors.mailContent}
            // rows={isChecked ? 15 : 3}
            rows={3}
            disabled={loading}
            autoComplete="off"
          />
        </ATMGrid.Column>
      </ATMGrid.Row>
    </ATMGrid>
  );
};

const SystemChangeItemDetail: React.FC<IProps> = ({
  data,
  formRef,
  handleClick,
  handleSubmit,
  isOpen,
  setIsOpen,
  loading,
  source,
}) => {
  const [isChecked, setIsChecked] = useState<boolean | null | undefined>(
    data.isComplete ?? false
  );

  return (
    <div>
      {source === 'ler' ? (
        <ATMSegment attached>
          <ATMGrid>
            <ATMGrid.Row>
              <ATMGrid.Column width={10}>
                {data.isComplete ? (
                  <MOLReadonlyField
                    label="Date Systems Change Completed By"
                    value={
                      <>
                        <u>
                          &nbsp;&nbsp;&nbsp;{data.completionSetBy}
                          &nbsp;&nbsp;&nbsp;
                        </u>{' '}
                        in{' '}
                        <u>
                          &nbsp;&nbsp;&nbsp;
                          {Moment(data.completedOn).format('MM/DD/YYYY HH:mm')}
                          &nbsp;&nbsp;&nbsp;
                        </u>
                      </>
                    }
                  />
                ) : (
                  <MOLReadonlyField
                    label="Date Systems Change Completed By"
                    value="______________________________ on ____/____/________"
                  />
                )}
              </ATMGrid.Column>
              <ATMGrid.Column width={6}>
                <ATMModal
                  open={isOpen}
                  size="tiny"
                  onClose={() => setIsOpen(false)}
                  onOpen={() => setIsOpen(true)}
                  closeOnDimmerClick={false}
                  closeOnEscape={false}
                  trigger={
                    <ATMCheckbox
                      name="isComplete"
                      label={Lang.LBL_SYSTEM_CHANGE_COMPLETE}
                      checked={data.isComplete ?? false}
                      onChange={(__, { checked }) => {
                        setIsChecked(checked);
                        return checked;
                      }}
                    />
                  }
                >
                  <ATMModal.Header>
                    {isChecked
                      ? Lang.TTL_SYSTEM_CHANGE_COMPLETION_SET
                      : Lang.TTL_SYSTEM_CHANGE_COMPLETION_CLEAR}
                  </ATMModal.Header>
                  <ATMModal.Content>
                    <ATMForm
                      ref={formRef}
                      onSubmit={handleSubmit}
                      mode="onChange"
                      defaultValues={{
                        requestId: data.requestId,
                        completedOn: data.completedOn ?? null,
                        completedOnDate: data.completedOn ?? null,
                        completedOnTime: null,
                        completionSetBy: null,
                        isComplete: isChecked,
                        mailContent: generateSystemChangeEmailFormDetails(
                          isChecked ?? false
                        ),
                      }}
                      validationSchema={SystemChangeCompleteFormSchema}
                    >
                      {(props) => {
                        return (
                          <ATMFormProvider {...props}>
                            <FormContent
                              requestIdTemp={data.requestId}
                              isChecked={isChecked ?? false}
                              loading={loading}
                            />
                          </ATMFormProvider>
                        );
                      }}
                    </ATMForm>
                  </ATMModal.Content>
                  <ATMModal.Actions>
                    <ATMButton
                      secondary
                      content={Lang.LBL_CANCEL}
                      onClick={() => {
                        setIsOpen(false);

                        setIsChecked(data.isComplete ?? false);
                      }}
                      type="button"
                      disabled={loading}
                    />
                    <ATMButton
                      type="button"
                      positive
                      content={Lang.LBL_YES}
                      onClick={handleClick}
                      loading={loading}
                    />
                  </ATMModal.Actions>
                </ATMModal>
              </ATMGrid.Column>
            </ATMGrid.Row>
          </ATMGrid>
        </ATMSegment>
      ) : (
        <>
          <ATMHeader attached="top" className={styles.labelRight}>
            Grid Ops System Changes (LER {data.requestId})
          </ATMHeader>
          <ATMSegment attached>
            <ATMGrid columns={2}>
              <ATMGrid.Row>
                <ATMGrid.Column>
                  <MOLReadonlyField
                    label="In Service Date"
                    value={Moment(data.inServiceDate).format('ddd, MM/DD/YYYY')}
                  />
                </ATMGrid.Column>
                <ATMGrid.Column>
                  <MOLReadonlyField
                    label={Lang.LBL_FACILITY}
                    value={`${data.outageFacility?.facility?.outgFacNm ?? '-'}${
                      data.outageFacility?.substation
                        ? ` @ ${data.outageFacility?.substation?.name}`
                        : ''
                    }`}
                  />
                </ATMGrid.Column>
                <ATMGrid.Column>
                  <div className={styles.removeLabel}>
                    <MOLReadonlyField
                      label=""
                      value={<strong>System Change</strong>}
                    />
                  </div>
                </ATMGrid.Column>
                <ATMGrid.Column>
                  <MOLReadonlyField
                    label={Lang.LBL_WORK_DESC}
                    value={data.wrkDesc}
                  />
                </ATMGrid.Column>
                <ATMGrid.Column />
                <ATMGrid.Column>
                  <MOLReadonlyField
                    label={Lang.LBL_COMMENTS}
                    value={data.sysChngSummary?.noteTxt}
                  />
                </ATMGrid.Column>
                <ATMGrid.Column>
                  {data.isComplete ? (
                    <MOLReadonlyField
                      label="Date Systems Change Completed By"
                      value={
                        <>
                          <u>
                            &nbsp;&nbsp;&nbsp;{data.completionSetBy}
                            &nbsp;&nbsp;&nbsp;
                          </u>{' '}
                          in{' '}
                          <u>
                            &nbsp;&nbsp;&nbsp;
                            {Moment(data.completedOn).format(
                              'MM/DD/YYYY HH:mm'
                            )}
                            &nbsp;&nbsp;&nbsp;
                          </u>
                        </>
                      }
                    />
                  ) : (
                    <MOLReadonlyField
                      label="Date Systems Change Completed By"
                      value="______________________________ on ____/____/________"
                    />
                  )}
                </ATMGrid.Column>

                <ATMGrid.Column>
                  <ATMModal
                    open={isOpen}
                    size="tiny"
                    onClose={() => setIsOpen(false)}
                    onOpen={() => setIsOpen(true)}
                    closeOnDimmerClick={false}
                    closeOnEscape={false}
                    trigger={
                      <ATMCheckbox
                        name="isComplete"
                        label={Lang.LBL_SYSTEM_CHANGE_COMPLETE}
                        checked={data.isComplete ?? false}
                        onChange={(__, { checked }) => {
                          setIsChecked(checked);
                          return checked;
                        }}
                      />
                    }
                  >
                    <ATMModal.Header>
                      {isChecked
                        ? Lang.TTL_SYSTEM_CHANGE_COMPLETION_SET
                        : Lang.TTL_SYSTEM_CHANGE_COMPLETION_CLEAR}
                    </ATMModal.Header>
                    <ATMModal.Content>
                      <ATMForm
                        ref={formRef}
                        onSubmit={handleSubmit}
                        mode="onChange"
                        defaultValues={{
                          requestId: data.requestId,
                          completedOn: data.completedOn ?? null,
                          completedOnDate: data.completedOn ?? null,
                          completedOnTime: null,
                          completionSetBy: null,
                          isComplete: isChecked,
                          mailContent: generateSystemChangeEmailFormDetails(
                            isChecked ?? false
                          ),
                        }}
                        validationSchema={SystemChangeCompleteFormSchema}
                      >
                        {(props) => {
                          return (
                            <ATMFormProvider {...props}>
                              <FormContent
                                requestIdTemp={data.requestId}
                                isChecked={isChecked ?? false}
                                loading={loading}
                              />
                            </ATMFormProvider>
                          );
                        }}
                      </ATMForm>
                    </ATMModal.Content>
                    <ATMModal.Actions>
                      <ATMButton
                        secondary
                        content={Lang.LBL_CANCEL}
                        onClick={() => {
                          setIsOpen(false);

                          setIsChecked(data.isComplete ?? false);
                        }}
                        type="button"
                        disabled={loading}
                      />
                      <ATMButton
                        type="button"
                        positive
                        content={Lang.LBL_YES}
                        onClick={handleClick}
                        loading={loading}
                      />
                    </ATMModal.Actions>
                  </ATMModal>
                </ATMGrid.Column>
              </ATMGrid.Row>
            </ATMGrid>
          </ATMSegment>
        </>
      )}
    </div>
  );
};

export default SystemChangeItemDetail;
