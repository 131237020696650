import React, { useState, useEffect } from 'react';
import { ATMButton } from 'shared-it-appmod-ui';
import styles from './back-top-top.module.scss';

const ATMBackToTop: React.FC = () => {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const handleScroll = () => {
    if (window.scrollY > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <>
      {isVisible && (
        <ATMButton
          type="button"
          secondary
          icon="chevron up"
          onClick={scrollToTop}
          className={styles.backToTop}
          content="Back to Top"
        />
      )}
    </>
  );
};

export default ATMBackToTop;
