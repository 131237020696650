import moment from 'moment';
import { IORGDataTableQueryState } from 'shared-it-appmod-ui';
import { httpClient } from 'src/libraries/http.library';
import { RealTimeLogAuditPayloadSchema } from 'src/models/real-time-log-audit.model';
import {
  RealTimeLogLinePayloadSchema,
  IRealTimeLogEntryForm,
  RealTimeLogListPayloadSchema,
  RealTimeLogDeletePayloadSchema,
  RealTimeLogPayloadSchema,
  EmployeesRealTimeLogListPayloadSchema,
  // RealTimeLogMailerSchema,
  ForcedOutageNotificationStatusSchema,
  BporReportPayloadSchema,
  IForcedOutageConfirmationForm,
  IRealTimeLogBPORReport,
  RealTimeLogInProgressPayloadSchema,
  BPORLogoResponseSchema,
} from 'src/models/real-time-log.model';

const client = httpClient();
const endpoint = 'real-time-log';
const employeesEndpoint = 'real-time-log?action=employees';

const services = {
  dataGET: async (logId: number) => {
    return client.get(`/${endpoint}/${logId}`, {}, RealTimeLogPayloadSchema);
  },

  listGET: async (data?: Partial<IORGDataTableQueryState>) => {
    const params: Record<string, any> = {
      ...data,
    };
    if (params.order) {
      params.order = params.order === 'ascending' ? 'asc' : 'desc';
    }
    return client.get(`/${endpoint}`, params, RealTimeLogListPayloadSchema);
  },

  listInProgressGET: async () => {
    return client.get(
      `/${endpoint}?action=inprogress`,
      {},
      RealTimeLogInProgressPayloadSchema
    );
  },

  reportGET: async (reportDate: Date) => {
    return client.get(
      `/${endpoint}?action=report`,
      { reportDate },
      BporReportPayloadSchema
    );
  },

  auditLogsGET: async (data?: Partial<IORGDataTableQueryState>) => {
    const params: Record<string, any> = {
      ...data,
    };
    if (params.order) {
      params.order = params.order === 'ascending' ? 'asc' : 'desc';
    }
    return client.get(
      `/${endpoint}?action=audit`,
      params,
      RealTimeLogAuditPayloadSchema
    );
  },

  employeeListGET: async () => {
    return client.get(
      `/${employeesEndpoint}`,
      {},
      EmployeesRealTimeLogListPayloadSchema
    );
  },

  createPOST: async (data: IRealTimeLogEntryForm) => {
    if (data.logDtTm) {
      // eslint-disable-next-line no-param-reassign
      data.logDtTm = moment(data.logDtTm).utc(false) as unknown as Date;
    }
    if (data.logDtTmFrcdOutOccurred) {
      // eslint-disable-next-line no-param-reassign
      data.logDtTmFrcdOutOccurred = moment(data.logDtTmFrcdOutOccurred).utc(
        false
      ) as unknown as Date;
    }
    if (data.logDtTmFrcdOutRestored) {
      // eslint-disable-next-line no-param-reassign
      data.logDtTmFrcdOutRestored = moment(data.logDtTmFrcdOutRestored).utc(
        false
      ) as unknown as Date;
    }

    return client.post(`/${endpoint}`, data, RealTimeLogPayloadSchema);
  },

  updatePUT: async (logId: number, data: IRealTimeLogEntryForm) => {
    return client.put(`/${endpoint}/${logId}`, data, RealTimeLogPayloadSchema);
  },

  dataDELETE: async (logId: number) => {
    return client.delete(
      `/${endpoint}/${logId}`,
      RealTimeLogDeletePayloadSchema
    );
  },

  lineGET: async () => {
    return client.get(
      `/${endpoint}?action=line`,
      {},
      RealTimeLogLinePayloadSchema
    );
  },

  handleSendBPORReportEmail: async (report: IRealTimeLogBPORReport) => {
    return client.post(
      `/${endpoint}?action=sendBPORReportEmail`,
      report,
      ForcedOutageNotificationStatusSchema
    );
  },

  handleSendEmailNow: async (data: IForcedOutageConfirmationForm) => {
    return client.post(
      `/${endpoint}?action=sendNow`,
      data,
      ForcedOutageNotificationStatusSchema
    );
  },

  handleTurnoffEmail: async (logId: number) => {
    return client.post(
      `/${endpoint}?action=turnoffEmail`,
      { logId },
      ForcedOutageNotificationStatusSchema
    );
  },

  handleEnableEmail: async (logId: number) => {
    return client.post(
      `/${endpoint}?action=enableEmail`,
      { logId },
      ForcedOutageNotificationStatusSchema
    );
  },

  handleSendEmailLater: async (logId: number) => {
    return client.post(
      `/${endpoint}?action=sendLater`,
      { logId },
      ForcedOutageNotificationStatusSchema
    );
  },

  bporLogoPOST: async (logo: string) => {
    return client.post(
      `/${endpoint}?action=bporLogo`,
      { logo },
      BPORLogoResponseSchema
    );
  },
};

export default services;
