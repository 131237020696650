import { IORGDataTableQueryState } from 'shared-it-appmod-ui';
import { RealTimeLogTab } from 'src/constants/real-time-log.constant';
import {
  realTimeLogActionTypes,
  IRealTimeLogState,
} from 'src/ducks/real-time-log.duck';
import { IRealTimeLogAll } from 'src/models/real-time-log.model';
import { IStatus } from 'src/libraries/thunk.library';
import Moment from 'src/libraries/moment.library';

export const getRealTimeLogStatus = (
  state: IRealTimeLogState,
  action: keyof typeof realTimeLogActionTypes
): IStatus =>
  state.status[action] ?? {
    fetching: false,
    error: null,
  };

export const getRealTimeLogParams = (
  params: Partial<IORGDataTableQueryState>,
  currentTab?: RealTimeLogTab,
  filterAll?: IRealTimeLogAll
): Partial<IORGDataTableQueryState> => {
  const { keyword, ...temp } = params;
  const data = temp;

  if (data.filters) {
    const checkMyEntries = data.filters?.filter(
      (val) => val.name === 'isMyEntries'
    );
    const checkOssEntries = data.filters?.filter(
      (val) => val.name === 'isOssEntries'
    );
    const checkTsoEntries = data.filters?.filter(
      (val) => val.name === 'isTsoEntries'
    );
    const checkBPor = data.filters?.filter(
      (val) => val.name === 'logBporReport'
    );
    const logDtTm = data.filters?.filter((val) => val.name === 'logDtTm');
    const logFacId = data.filters?.filter((val) => val.name === 'logFacId');
    const logSubstation = data.filters?.filter(
      (val) => val.name === 'logSubstation'
    );
    const logCategory = data.filters?.filter(
      (val) => val.name === 'logCategory'
    );
    const isAll = data.filters?.filter((val) => val.name === 'isAll');

    if (logFacId && logFacId.length > 0) {
      // eslint-disable-next-line eqeqeq
      data.filters = data.filters?.filter((val) => val.name != 'logFacId');
      // eslint-disable-next-line eqeqeq
      data.filters = data.filters?.filter((val) => val.name != 'logSubstation');

      data.filters = [
        ...(data.filters ?? []),
        {
          name: 'logFacId',
          value: logFacId?.[0].value,
        },
      ];
    }

    if (logSubstation && logSubstation.length > 0) {
      // eslint-disable-next-line eqeqeq
      data.filters = data.filters?.filter((val) => val.name != 'logSubstation');
      // eslint-disable-next-line eqeqeq
      data.filters = data.filters?.filter((val) => val.name != 'logFacId');
      data.filters = [
        ...(data.filters ?? []),
        {
          name: 'logSubstation',
          value: logSubstation?.[0].value,
        },
      ];
    }

    if (checkMyEntries && checkMyEntries?.length > 0) {
      // eslint-disable-next-line eqeqeq
      data.filters = data.filters?.filter((val) => val.name != 'isMyEntries');
      data.filters = [
        ...(data.filters ?? []),
        {
          name: 'isMyEntries',
          value:
            checkMyEntries?.[0]?.value === 'true' ||
            checkMyEntries?.[0]?.value === true
              ? checkMyEntries?.[0]?.value
              : undefined,
        },
      ];
    }

    if (checkOssEntries && checkOssEntries?.length > 0) {
      // eslint-disable-next-line eqeqeq
      data.filters = data.filters?.filter((val) => val.name != 'isOssEntries');
      data.filters = [
        ...(data.filters ?? []),
        {
          name: 'isOssEntries',
          value:
            checkOssEntries?.[0]?.value === 'true' ||
            checkOssEntries?.[0]?.value === true
              ? checkOssEntries?.[0]?.value
              : undefined,
        },
      ];
    }

    if (checkTsoEntries && checkTsoEntries?.length > 0) {
      // eslint-disable-next-line eqeqeq
      data.filters = data.filters?.filter((val) => val.name != 'isTsoEntries');
      data.filters = [
        ...(data.filters ?? []),
        {
          name: 'isTsoEntries',
          value:
            checkTsoEntries?.[0]?.value === 'true' ||
            checkTsoEntries?.[0]?.value === true
              ? checkTsoEntries?.[0]?.value
              : undefined,
        },
      ];
    }

    if (checkBPor && checkBPor?.length > 0) {
      // eslint-disable-next-line eqeqeq
      data.filters = data.filters?.filter((val) => val.name != 'logBporReport');
      data.filters = [
        ...(data.filters ?? []),
        {
          name: 'logBporReport',
          value:
            checkBPor?.[0]?.value === 'true' || checkBPor?.[0]?.value === true
              ? checkBPor?.[0]?.value
              : undefined,
        },
      ];
    }

    if (logCategory && logCategory?.length > 0) {
      // eslint-disable-next-line eqeqeq
      data.filters = data.filters?.filter((val) => val.name != 'logCategory');
      data.filters = [
        ...(data.filters ?? []),
        {
          name: 'logCategory',
          value: logCategory?.[0]?.value,
        },
      ];
    }

    if (
      logDtTm &&
      logDtTm?.length > 0 &&
      logDtTm[0].value &&
      logDtTm[0].value.length > 0
    ) {
      // eslint-disable-next-line eqeqeq
      data.filters = data.filters?.filter((val) => val.name !== 'logDtTm');
      data.filters = [
        ...(data.filters ?? []),
        {
          name: 'logDtTm',
          value: [
            Moment(String(logDtTm[0].value[0])).startOf('day').toDate(),
            Moment(String(logDtTm[0].value[1] ?? logDtTm[0].value[0]))
              .endOf('day')
              .toDate(),
          ],
        },
      ];
    }

    if (isAll && isAll?.length > 0) {
      // eslint-disable-next-line eqeqeq
      data.filters = data.filters?.filter((val) => val.name !== 'isAll');
    }
  }

  if (filterAll) {
    data.filters = [
      // ...(data.filters ?? []),
      {
        name: 'id',
        value: keyword,
      },
      {
        name: 'logDtTm',
        value: [
          Moment(String(filterAll?.logDtTm ? filterAll?.logDtTm[0] : ''))
            .startOf('day')
            .toDate(),
          Moment(
            String(() => {
              let endDate;
              if (filterAll?.logDtTm && filterAll?.logDtTm[0]) {
                if (filterAll?.logDtTm[1]) {
                  endDate = filterAll?.logDtTm[1];
                } else {
                  endDate = filterAll?.logDtTm[0];
                }
              }
              return endDate ?? '';
            })
          )
            .endOf('day')
            .toDate(),
        ],
      },
      {
        name: 'logUserId',
        value: filterAll?.logUserId,
      },
      {
        name: 'logEntry',
        value: filterAll?.logEntry,
      },
      {
        name: 'isMyEntries',
        value: filterAll?.isMyEntries,
      },
      {
        name: 'isOssEntries',
        value: filterAll?.isOssEntries,
      },
      {
        name: 'isTsoEntries',
        value: filterAll?.isTsoEntries,
      },
      {
        name: 'logBporReport',
        value: filterAll?.logBporReport,
      },
      {
        name: 'logFacId',
        value: filterAll?.logFacId,
      },
      {
        name: 'logSubstation',
        value: filterAll?.logSubstation,
      },
      {
        name: 'logCategory',
        value: filterAll?.logCategory,
      },
    ];
  }

  switch (currentTab) {
    case RealTimeLogTab.All: {
      data.filters = [
        ...(data.filters ?? []),
        {
          name: 'isAll',
          value: true,
        },
      ];
      break;
    }

    // Note: Commenting this if currrentTab is 0 (today) to unset isAll (and prevent showing isAll as filter in Today's tab)
    // case RealTimeLogTab.Today: {
    //   data.filters = [
    //     ...(data.filters ?? []),
    //     {
    //       name: 'isAll',
    //       value: false,
    //     },
    //   ];
    //   break;
    // }

    default: {
      break;
    }
  }

  return data;
};
