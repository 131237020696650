import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useRealTimeLogContext } from 'src/contexts/real-time-log.context';
import { realTimeLogActionTypes } from 'src/ducks/real-time-log.duck';
import {
  ForcedOutageConfirmationFormSchema,
  IForcedOutageConfirmationForm,
  IRealTimeLog,
  IRealTimeLogEntryForm,
} from 'src/models/real-time-log.model';
import {
  getRealTimeLogParams,
  getRealTimeLogStatus,
} from 'src/selectors/real-time-log.selector';
import history from 'src/history';
import {
  ToastError,
  ToastSuccess,
} from 'src/components/atoms/toaster/toaster.component';
import Lang from 'src/libraries/language';
import { useSubstationContext } from 'src/contexts/substation.context';
import { useEquipmentTypeContext } from 'src/contexts/equipment-type.context';
import { RealTimeLogCategory } from 'src/constants/real-time-log.constant';
import { getUser } from 'src/libraries/amplify.library';
import { addDays, isBefore } from 'date-fns';
import { hasRole } from 'src/libraries/access.library';
import { AccessRole } from 'src/constants/access.constant';
import RealTimeLogEditView from './real-time-log-edit.view';

type IProp = {
  data: IRealTimeLog;
  showTableAll?: boolean;
  setShowTableAll?: (x: boolean) => void;
  searchFilters?: Record<any, any>;
  setSearchFilters?: React.Dispatch<React.SetStateAction<Record<any, any>>>;
  currentTab?: number;
  timer?: any;
  setIsFormOpen?: React.Dispatch<React.SetStateAction<boolean | null>>;
  isFromInProgress?: boolean;
  setUpdatedInProgressLog?: React.Dispatch<React.SetStateAction<number | null>>;
};

const RealTimeLogEdit: React.FC<IProp> = ({
  data,
  showTableAll,
  setShowTableAll,
  searchFilters,
  setSearchFilters,
  currentTab,
  timer,
  isFromInProgress,
  setIsFormOpen,
  setUpdatedInProgressLog,
}) => {
  const { state, actions } = useRealTimeLogContext();
  const { actions: substationActions } = useSubstationContext();
  const { actions: equipmentTypeActions } = useEquipmentTypeContext();
  const [isEditOpen, setIsEditOpen] = useState<boolean | null>(null);
  const [tripOnEdit, setTripOnEdit] = useState<{
    index: number;
    logSub: string;
    tripsTargets: string;
  } | null>(null);
  const [isChangeLogUpdate, setIsChangeLogUpdate] = useState(false);
  const [changeLogUpdateComplete, setChangeLogUpdateComplete] =
    useState<boolean>(true);
  const [changeLogOnEditMode, setChangeLogOnEditMode] = useState(false);
  const [isClearNoticeConfirmOpen, setIsClearNoticeConfirmOpen] =
    useState(false);
  const [isForcedOutageConfirmOpen, setIsForcedOutageConfirmOpen] =
    useState(false);
  const [isEnableConfirmOpen, setIsEnableConfirmOpen] = useState(false);

  const formRef = useRef<HTMLFormElement>(null);
  const formRefEmail = useRef<HTMLFormElement>(null);

  const status = getRealTimeLogStatus(
    state,
    realTimeLogActionTypes.REAL_TIME_LOG_DATA_UPDATE
  );

  const sendNowStatus = getRealTimeLogStatus(
    state,
    realTimeLogActionTypes.FORCED_OUTAGE_SEND_NOW
  );

  const turnOffEmailStatus = getRealTimeLogStatus(
    state,
    realTimeLogActionTypes.FORCED_OUTAGE_EMAIL_TURNOFF
  );

  const enableEmailStatus = getRealTimeLogStatus(
    state,
    realTimeLogActionTypes.FORCED_OUTAGE_EMAIL_ENABLE
  );

  useEffect(() => {
    if (isEditOpen) {
      if (timer && timer.current) {
        clearTimeout(timer.current);
      }

      if (setUpdatedInProgressLog) {
        setUpdatedInProgressLog(null);
      }

      substationActions?.listSubstationCreateGET({ limit: 0, page: 1 });
      if (data.substation?.substationId) {
        equipmentTypeActions?.listGET({
          limit: 0,
          page: 1,
          filters: [
            { name: 'substationId', value: data.substation?.substationId },
          ],
        });
      }

      if (!state.line?.length || state.line?.length === 0) {
        actions?.lineGET();
      }
    }

    if (isEditOpen === false && currentTab === 0) {
      history.push('/real-time-log?realTimeLogStatus=0');
    }
  }, [isEditOpen]);

  const handleClick = useCallback(() => {
    if (formRef && formRef.current) {
      formRef.current.handleSubmit();
    }
  }, [formRef]);

  // Only handleSubmit is working for some reason?!? Other custom name is not working so let's allow two
  // types of formData and call schema.validate() to know the type and perform processing based on that.
  const handleSubmit = useCallback(
    async (formData: IRealTimeLogEntryForm | IForcedOutageConfirmationForm) => {
      let isConfirm;
      await ForcedOutageConfirmationFormSchema.validate(formData)
        .then(async (validFormData) => {
          isConfirm = true;

          const response = await actions.handleSendEmailNow(validFormData);

          if (!response.error) {
            ToastSuccess(Lang.MSG_FORCED_OUTAGE_EMAIL_SENT);

            setIsForcedOutageConfirmOpen(false);
            setIsEditOpen(false);
            if (setIsFormOpen) {
              setIsFormOpen(false);
            }
          } else {
            ToastError(Lang.MSG_FORCED_OUTAGE_EMAIL_ERROR);
          }
        })
        .catch((error) => {
          isConfirm = false;
          return error;
        });

      if (isConfirm === false) {
        const updatedForm: IRealTimeLogEntryForm =
          formData as IRealTimeLogEntryForm;

        if (isChangeLogUpdate === true) {
          setIsChangeLogUpdate(false);

          const response = await actions.updatePUT(
            updatedForm.logId as number,
            {
              ...updatedForm,
            }
          );

          if (!response.error) {
            ToastSuccess(Lang.MSG_REAL_TIME_LOG_NOTE_EDIT);
            setIsEditOpen(false);
            setChangeLogUpdateComplete(true);
            setChangeLogOnEditMode(false);
            if (setIsFormOpen) {
              setIsFormOpen(false);
            }
          } else {
            ToastError('Error updating Real Time Log Note.');
          }
        } else {
          let restorationUpdated = false;
          // if (updatedForm.logCategory !== RealTimeLogCategory.FORCEDOUTAGE) {
          //   updatedForm.logDtTmFrcdOutOccurred = null;
          //   updatedForm.logDtTmFrcdOutRestored = null;
          //   updatedForm.logTmFrcdOutOccurred = null;
          //   updatedForm.logTmFrcdOutRestored = null;
          // } else {
          if (updatedForm.logDtTmFrcdOutOccurred) {
            updatedForm.logDtTmFrcdOutOccurred = new Date(
              updatedForm.logDtTmFrcdOutOccurred?.getFullYear(),
              updatedForm.logDtTmFrcdOutOccurred?.getMonth(),
              updatedForm.logDtTmFrcdOutOccurred?.getDate(),
              Number(updatedForm.logTmFrcdOutOccurred?.split(':')[0]),
              Number(updatedForm.logTmFrcdOutOccurred?.split(':')[1])
            );
          }

          if (updatedForm.logDtTmFrcdOutRestored) {
            updatedForm.logDtTmFrcdOutRestored = new Date(
              updatedForm.logDtTmFrcdOutRestored?.getFullYear(),
              updatedForm.logDtTmFrcdOutRestored?.getMonth(),
              updatedForm.logDtTmFrcdOutRestored?.getDate(),
              Number(updatedForm.logTmFrcdOutRestored?.split(':')[0]),
              Number(updatedForm.logTmFrcdOutRestored?.split(':')[1])
            );
          }

          updatedForm.originalRestoration =
            updatedForm.originalRestoration ?? null;

          // If restoration time has changed, then set restorationUpdated to true which will show the
          // forced outage email popup
          if (
            updatedForm.logDtTmFrcdOutRestored &&
            updatedForm.logDtTmFrcdOutRestored?.getTime() !==
              updatedForm.originalRestoration?.getTime()
          ) {
            restorationUpdated = true;
          }
          // }

          updatedForm.logDtTm = new Date(
            updatedForm.logDtTm.getFullYear(),
            updatedForm.logDtTm.getMonth(),
            updatedForm.logDtTm.getDate(),
            Number(updatedForm.logTm.split(':')[0]),
            Number(updatedForm.logTm.split(':')[1])
          );

          // Ensure that updatedBy is the currently logged user.
          updatedForm.updatedBy = getUser()?.emp_no;

          if (tripOnEdit) {
            updatedForm.forcedLog[tripOnEdit.index].logSub = tripOnEdit.logSub;
            updatedForm.forcedLog[tripOnEdit.index].tripsTargets =
              tripOnEdit.tripsTargets;
          }

          setTripOnEdit(null);

          const response = await actions.updatePUT(data.logId, {
            ...updatedForm,
          });

          if (!response.error) {
            ToastSuccess(Lang.MSG_REAL_TIME_LOG_EDIT);
            if (
              updatedForm.logCategory === RealTimeLogCategory.FORCEDOUTAGE &&
              hasRole(AccessRole.BPOR_SENDER) &&
              restorationUpdated
            ) {
              setIsForcedOutageConfirmOpen(true);
            } else if (showTableAll) {
              setIsEditOpen(false);
              if (setIsFormOpen) {
                setIsFormOpen(false);
              }

              history.push('/real-time-log?realTimeLogStatus=1');
              await actions?.listGET(searchFilters);
            } else {
              if (!isFromInProgress) {
                const logEntryDate = new Date(updatedForm.logDtTm);
                const todayMinusOne = addDays(new Date(), -1);

                if (isBefore(logEntryDate, todayMinusOne)) {
                  const searchCriteria = getRealTimeLogParams(
                    {
                      page: 1,
                    },
                    1
                  );
                  if (setShowTableAll) {
                    setShowTableAll(true);
                  }

                  if (setSearchFilters) {
                    setSearchFilters(searchCriteria);
                  }

                  history.push('/real-time-log?realTimeLogStatus=1');
                  await actions?.listGET(searchCriteria);
                }
              }

              setIsEditOpen(false);
              if (setIsFormOpen) {
                setIsFormOpen(false);
              }
            }
          } else {
            ToastError('Error updating Real Time Log.');
          }
        }
      }
    },
    [
      data,
      actions,
      setIsEditOpen,
      isChangeLogUpdate,
      setIsChangeLogUpdate,
      setTripOnEdit,
      setIsForcedOutageConfirmOpen,
    ]
  );

  const handleConfirmEmailClick = useCallback(() => {
    if (formRefEmail && formRefEmail.current) {
      formRefEmail.current.handleSubmit();
    }
  }, [formRefEmail]);

  const clearForcedOutageNotice = useCallback(
    async (logId) => {
      const response = await actions.handleTurnOffEmail(logId);

      if (!response.error) {
        setIsClearNoticeConfirmOpen(false);
        ToastSuccess(Lang.MSG_FORCED_OUTAGE_EMAIL_TURNOFF);
      } else {
        ToastError(Lang.MSG_FORCED_OUTAGE_EMAIL_TURNOFF_ERROR);
      }
    },
    [actions]
  );

  const enableEmailNotice = useCallback(
    async (logId) => {
      const response = await actions.handleEnableEmail(logId);
      if (!response.error) {
        // setIsNoticeCleared(false);
        setIsEnableConfirmOpen(false);
        ToastSuccess(Lang.MSG_FORCED_OUTAGE_EMAIL_ENABLE);
      } else {
        ToastError(Lang.MSG_FORCED_OUTAGE_EMAIL_ENABLE_ERROR);
      }
    },
    [actions]
  );

  return (
    <RealTimeLogEditView
      formRef={formRef}
      formRefEmail={formRefEmail}
      defaultValues={data}
      loading={status.fetching}
      handleClick={handleClick}
      handleSubmit={handleSubmit}
      isEditOpen={isEditOpen}
      handleOpen={setIsEditOpen}
      setIsFormOpen={setIsFormOpen}
      setIsChangeLogUpdate={setIsChangeLogUpdate}
      changeLogUpdateComplete={changeLogUpdateComplete}
      setChangeLogUpdateComplete={setChangeLogUpdateComplete}
      changeLogOnEditMode={changeLogOnEditMode}
      setChangeLogOnEditMode={setChangeLogOnEditMode}
      setTripOnEdit={setTripOnEdit}
      handleConfirmEmailSubmit={handleSubmit}
      handleConfirmEmailClick={handleConfirmEmailClick}
      clearForcedOutageNotice={clearForcedOutageNotice}
      isForcedOutageConfirmOpen={isForcedOutageConfirmOpen}
      setIsForcedOutageConfirmOpen={setIsForcedOutageConfirmOpen}
      isClearNoticeConfirmOpen={isClearNoticeConfirmOpen}
      setIsClearNoticeConfirmOpen={setIsClearNoticeConfirmOpen}
      sendNowLoading={sendNowStatus.fetching}
      turnOffEmailLoading={turnOffEmailStatus.fetching}
      isEnableConfirmOpen={isEnableConfirmOpen}
      setIsEnableConfirmOpen={setIsEnableConfirmOpen}
      enableEmailNotice={enableEmailNotice}
      enableEmailLoading={enableEmailStatus.fetching}
      // setUpdatedInProgressLog={setUpdatedInProgressLog}
    />
  );
};

export default RealTimeLogEdit;
