import { ICommonState, IReducerAction } from 'src/libraries/thunk.library';
import { orderBy } from 'lodash';
import services from 'src/services/real-time-log.service';
import {
  IRealTimeLog,
  IRealTimeLogLine,
  IEmployeesRealTimeLog,
  IBporReport,
  IBPORLogoResponse,
} from 'src/models/real-time-log.model';

export const realTimeLogActionTypes = {
  REAL_TIME_LOG_DATA_READ: 'REAL_TIME_LOG_DATA_READ',
  REAL_TIME_LOG_LIST_READ: 'REAL_TIME_LOG_LIST_READ',
  REAL_TIME_LOG_EMPLOYEE_LIST_READ: 'REAL_TIME_LOG_EMPLOYEE_LIST_READ',
  REAL_TIME_LOG_DATA_CREATE: 'REAL_TIME_LOG_DATA_CREATE',
  REAL_TIME_LOG_DATA_UPDATE: 'REAL_TIME_LOG_DATA_UPDATE',
  REAL_TIME_LOG_DATA_DELETE: 'REAL_TIME_LOG_DATA_DELETE',
  REAL_TIME_LOG_CLEAR_LIST: 'REAL_TIME_LOG_CLEAR_LIST',
  REAL_TIME_LOG_CLEAR_BPOR_REPORT: 'REAL_TIME_LOG_CLEAR_BPOR_REPORT',
  REAL_TIME_LOG_DATA_SET: 'REAL_TIME_LOG_DATA_SET',
  REAL_TIME_LOG_LINE_READ: 'REAL_TIME_LOG_LINE_READ',
  REAL_TIME_LOG_LINE_CREATE: 'REAL_TIME_LOG_LINE_CREATE',
  REAL_TIME_LOG_MAILER: 'REAL_TIME_LOG_MAILER',
  REAL_TIME_LOG_FORCED_OUTAGE_EMAIL_UPDATE:
    'REAL_TIME_LOG_FORCED_OUTAGE_EMAIL_UPDATE',
  FORCED_OUTAGE_SEND_NOW: 'FORCED_OUTAGE_SEND_NOW',
  FORCED_OUTAGE_SEND_LATER: 'FORCED_OUTAGE_SEND_LATER',
  FORCED_OUTAGE_EMAIL_TURNOFF: 'FORCED_OUTAGE_EMAIL_TURNOFF',
  BPOR_REPORT_READ: 'BPOR_REPORT_READ',
  FORCED_OUTAGE_EMAIL_ENABLE: 'FORCED_OUTAGE_EMAIL_ENABLE',
  REAL_TIME_LOG_INPROGRESS_LIST_READ: 'REAL_TIME_LOG_INPROGRESS_LIST_READ',
  REAL_TIME_LOG_CLEAR_INPROGRESS_LIST: 'REAL_TIME_LOG_CLEAR_INPROGRESS_LIST',
} as const;

export const duckActions = {
  // These are async actions that has promise response on event queue

  lineGET: {
    type: realTimeLogActionTypes.REAL_TIME_LOG_LINE_READ,
    service: services.lineGET,
    meta: {
      error: false, // Overrides default error handler if you want to have custom error message
    },
  },

  lineCreateGET: {
    type: realTimeLogActionTypes.REAL_TIME_LOG_LINE_CREATE,
    service: services.lineGET,
    meta: {
      error: false, // Overrides default error handler if you want to have custom error message
    },
  },

  dataGET: {
    type: realTimeLogActionTypes.REAL_TIME_LOG_DATA_READ,
    service: services.dataGET,
  },

  listGET: {
    type: realTimeLogActionTypes.REAL_TIME_LOG_LIST_READ,
    service: services.listGET,
  },

  listInProgressGET: {
    type: realTimeLogActionTypes.REAL_TIME_LOG_INPROGRESS_LIST_READ,
    service: services.listInProgressGET,
  },

  reportGET: {
    type: realTimeLogActionTypes.BPOR_REPORT_READ,
    service: services.reportGET,
  },

  employeeListGET: {
    type: realTimeLogActionTypes.REAL_TIME_LOG_EMPLOYEE_LIST_READ,
    service: services.employeeListGET,
  },

  createPOST: {
    type: realTimeLogActionTypes.REAL_TIME_LOG_DATA_CREATE,
    service: services.createPOST,
    meta: {
      error: false, // Overrides default error handler if you want to have custom error message
    },
  },

  updatePUT: {
    type: realTimeLogActionTypes.REAL_TIME_LOG_DATA_UPDATE,
    service: services.updatePUT,
  },

  dataDELETE: {
    type: realTimeLogActionTypes.REAL_TIME_LOG_DATA_DELETE,
    service: services.dataDELETE,
  },

  handleBPORReportEmail: {
    type: realTimeLogActionTypes.REAL_TIME_LOG_MAILER,
    service: services.handleSendBPORReportEmail,
  },

  handleSendEmailNow: {
    type: realTimeLogActionTypes.FORCED_OUTAGE_SEND_NOW,
    service: services.handleSendEmailNow,
  },

  handleSendEmailLater: {
    type: realTimeLogActionTypes.FORCED_OUTAGE_SEND_LATER,
    service: services.handleSendEmailLater,
  },

  handleTurnOffEmail: {
    type: realTimeLogActionTypes.FORCED_OUTAGE_EMAIL_TURNOFF,
    service: services.handleTurnoffEmail,
  },

  handleEnableEmail: {
    type: realTimeLogActionTypes.FORCED_OUTAGE_EMAIL_ENABLE,
    service: services.handleEnableEmail,
  },

  clearList: () => ({
    type: realTimeLogActionTypes.REAL_TIME_LOG_CLEAR_LIST,
  }),

  clearReport: () => ({
    type: realTimeLogActionTypes.REAL_TIME_LOG_CLEAR_BPOR_REPORT,
  }),

  clearInProgressList: () => ({
    type: realTimeLogActionTypes.REAL_TIME_LOG_CLEAR_INPROGRESS_LIST,
  }),

  // This is a sync action
  setData: (realTimeLog: IRealTimeLog) => ({
    type: realTimeLogActionTypes.REAL_TIME_LOG_DATA_SET,
    payload: realTimeLog,
  }),
};

export type IRealTimeLogAsync = typeof duckActions;

export interface IRealTimeLogState
  extends ICommonState<typeof realTimeLogActionTypes> {
  data?: IRealTimeLog;
  list: IRealTimeLog[];
  employeeList: IEmployeesRealTimeLog[];
  total: number;
  all: number;
  today: number;
  inProgressCnt: number;
  watchList: number;
  line: IRealTimeLogLine[];
  lineCreateList: IRealTimeLogLine[];
  mail: string;
  report?: IBporReport;
  inProgress: IRealTimeLog[];
  bporLogo?: IBPORLogoResponse;
}

export const defaultState: IRealTimeLogState = {
  status: {},
  list: [],
  employeeList: [],
  total: 0,
  all: 0,
  today: 0,
  inProgressCnt: 0,
  watchList: 0,
  line: [],
  lineCreateList: [],
  mail: '',
  report: undefined,
  inProgress: [],
};

const RealTimeLogReducer = (
  state: IRealTimeLogState,
  action: IReducerAction<IRealTimeLogAsync>
): IRealTimeLogState => {
  switch (action.type) {
    case realTimeLogActionTypes.REAL_TIME_LOG_DATA_SET:
    case realTimeLogActionTypes.REAL_TIME_LOG_DATA_READ:
    case realTimeLogActionTypes.REAL_TIME_LOG_DATA_UPDATE: {
      const foundItem = state.inProgress.find(
        (item) => item.logId === action.payload?.logId
      );

      if (!foundItem && action.payload && action.payload.logInProgress) {
        state.inProgress.push(action.payload);
      }

      let tempInProgress = state.inProgress.map((item) => {
        if (item.logId === action.payload?.logId) {
          if (action.payload.logInProgress) {
            return action.payload;
          }
        }
        return item;
      });

      if (foundItem && action.payload && !action.payload.logInProgress) {
        tempInProgress = state.inProgress.filter(
          (item) => item.logId !== action.payload?.logId
        );
      }

      return {
        ...state,
        list: state.list.map((item) =>
          item.logId === action.payload?.logId ? action.payload : item
        ),
        inProgress: tempInProgress
          .filter((item) => item !== null)
          .sort(
            (a, b) =>
              (b.logDtTm as Date).getTime() - (a.logDtTm as Date).getTime()
          ),
        inProgressCnt: tempInProgress.length,
        data: action.payload,
      };
    }
    case realTimeLogActionTypes.REAL_TIME_LOG_DATA_CREATE: {
      // Adding th newly added log to the list. In general, this is no needed but there is a bug on displaying the list afer
      // a newly added log wherein the last record on the list is not being displaye for some reason even if the record
      // is on the response. And for some reason, pushing the newly added record right away and the immediate call to
      // list service fixes the weird problem.
      state.list.push(action.payload as IRealTimeLog);

      if (action.payload?.logInProgress) {
        state.inProgress.push(action.payload);
      }

      return {
        ...state,
        list: state.list.sort(
          (a, b) =>
            (b.logDtTm as Date).getTime() - (a.logDtTm as Date).getTime()
        ),
        data: action.payload,
        inProgress: state.inProgress.sort(
          (a, b) =>
            (b.logDtTm as Date).getTime() - (a.logDtTm as Date).getTime()
        ),
        inProgressCnt: state.inProgress.length,
      };
    }

    case realTimeLogActionTypes.REAL_TIME_LOG_LINE_READ: {
      return {
        ...state,
        line: orderBy(action.payload, ['outgFacNm'], ['asc']) ?? [],
        lineCreateList: orderBy(action.payload, ['outgFacNm'], ['asc']) ?? [],
      };
    }

    case realTimeLogActionTypes.REAL_TIME_LOG_LINE_CREATE: {
      return {
        ...state,
        lineCreateList: orderBy(action.payload, ['outgFacNm'], ['asc']) ?? [],
      };
    }

    case realTimeLogActionTypes.REAL_TIME_LOG_LIST_READ: {
      return {
        ...state,
        list: action.payload?.rows ?? [],
        all: action.payload?.count.all ?? 0,
        today: action.payload?.count.today ?? 0,
      };
    }

    case realTimeLogActionTypes.REAL_TIME_LOG_INPROGRESS_LIST_READ: {
      return {
        ...state,
        inProgress: action.payload?.rows ?? [],
        inProgressCnt: action.payload?.count ?? 0,
      };
    }

    case realTimeLogActionTypes.REAL_TIME_LOG_EMPLOYEE_LIST_READ: {
      return {
        ...state,
        employeeList: action.payload?.rows ?? [],
      };
    }

    case realTimeLogActionTypes.BPOR_REPORT_READ: {
      return {
        ...state,
        report: action.payload ?? undefined,
      };
    }

    case realTimeLogActionTypes.REAL_TIME_LOG_DATA_DELETE: {
      if (action.params) {
        const [id] = action.params;
        const list = state.list.filter((value) => value.logId !== id);

        return {
          ...state,
          data: undefined,
          total: state.total - (state.list.length - list.length),
          list,
        };
      }

      return state;
    }

    case realTimeLogActionTypes.REAL_TIME_LOG_CLEAR_LIST: {
      return {
        ...state,
        list: [],
      };
    }

    case realTimeLogActionTypes.REAL_TIME_LOG_CLEAR_BPOR_REPORT: {
      return {
        ...state,
        report: {
          count: {
            completedSystemChanges: 0,
            forcedInterruptions: 0,
          },
          rows: {
            completedSystemChanges: [],
            forcedInterruptions: [],
          },
        },
      };
    }

    case realTimeLogActionTypes.REAL_TIME_LOG_CLEAR_INPROGRESS_LIST: {
      return {
        ...state,
        inProgress: [],
      };
    }

    case realTimeLogActionTypes.REAL_TIME_LOG_MAILER: {
      return {
        ...state,
        mail: action.payload?.message ?? '',
      };
    }

    case realTimeLogActionTypes.FORCED_OUTAGE_SEND_NOW: {
      return {
        ...state,
        list: state.list.map((item) =>
          item.logId === action.payload?.data?.logId
            ? {
                ...item,
                frcdOutgEmailSent: action.payload?.data?.frcdOutgEmailSent,
              }
            : item
        ),
        inProgress: state.inProgress.map((item) =>
          item.logId === action.payload?.data?.logId
            ? {
                ...item,
                frcdOutgEmailSent: action.payload?.data?.frcdOutgEmailSent,
              }
            : item
        ),
        mail: action.payload?.message ?? '',
      };
    }
    case realTimeLogActionTypes.FORCED_OUTAGE_EMAIL_ENABLE: {
      const foundListItem = state.list.find(
        (item) => item.logId === action.payload?.data?.logId
      );

      const foundInProgressItem = state.inProgress.find(
        (item) => item.logId === action.payload?.data?.logId
      );

      if (
        !foundInProgressItem &&
        foundListItem &&
        action.payload &&
        action.payload.data &&
        action.payload.data.logInProgress
      ) {
        state.inProgress.push(foundListItem);
      }

      return {
        ...state,
        list: state.list.map((item) =>
          item.logId === action.payload?.data?.logId
            ? {
                ...item,
                frcdOutgEmailSent: action.payload?.data?.frcdOutgEmailSent,
                logInProgress: action.payload?.data?.logInProgress,
              }
            : item
        ),
        inProgress: state.inProgress
          .map((item) =>
            item.logId === action.payload?.data?.logId
              ? {
                  ...item,
                  frcdOutgEmailSent: action.payload?.data?.frcdOutgEmailSent,
                  logInProgress: action.payload?.data?.logInProgress,
                }
              : item
          )
          .sort(
            (a, b) =>
              (b.logDtTm as Date).getTime() - (a.logDtTm as Date).getTime()
          ),
        inProgressCnt: state.inProgress.length,
        // emailFlagState: action.payload ?? undefined,
      };
    }
    case realTimeLogActionTypes.FORCED_OUTAGE_EMAIL_TURNOFF: {
      const tempInProgress = state.inProgress.filter(
        (item) => item.logId !== action.payload?.data?.logId
      );

      return {
        ...state,
        list: state.list.map((item) =>
          item.logId === action.payload?.data?.logId
            ? {
                ...item,
                frcdOutgEmailSent: action.payload?.data?.frcdOutgEmailSent,
                logInProgress: action.payload?.data?.logInProgress,
              }
            : item
        ),
        inProgress: tempInProgress,
        inProgressCnt: tempInProgress.length,
      };
    }

    case realTimeLogActionTypes.FORCED_OUTAGE_SEND_LATER: {
      return {
        ...state,
      };
    }

    default: {
      return state;
    }
  }
};

export default RealTimeLogReducer;
