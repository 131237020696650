import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  ISystemChange,
  IsystemChangeCompleteForm,
} from 'src/models/system-change.model';
import { useSystemChangeContext } from 'src/contexts/system-change.context';
import { systemChangeActionTypes } from 'src/ducks/system-change.duck';
import { getSystemChangeStatus } from 'src/selectors/system-change.selector';
import {
  ToastError,
  ToastSuccess,
  ToastWarning,
} from 'src/components/atoms/toaster/toaster.component';
import SystemChangeItemDetail from './system-change-item-detail.component';

type IProps = {
  item: ISystemChange;
  source: string;
};

const SystemChangeItem: React.FC<IProps> = ({ item, source }) => {
  const { actions, state } = useSystemChangeContext();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [sysChangeItem, setSysChangeItem] = useState<ISystemChange>(item);
  const formRef = useRef<HTMLFormElement>(null);
  const systemChangeStatus = getSystemChangeStatus(
    state,
    systemChangeActionTypes.SYSTEM_CHANGE_COMPLETE_UPDATE
  );

  useEffect(() => {
    if (source === 'ler' && item.requestId === state.data?.requestId) {
      setSysChangeItem(state.data);
    }
  }, [source]);

  const handleClick = useCallback(() => {
    if (formRef && formRef.current) {
      formRef.current.handleSubmit();
    }
  }, [formRef]);

  const handleSubmit = useCallback(
    async (formData: IsystemChangeCompleteForm) => {
      const result = await actions.dataPUT(formData.requestId, formData);
      if (result.error) {
        ToastError('Failed to update system change completion.');
      } else {
        if (source === 'ler' && item.requestId === result.payload?.requestId) {
          setSysChangeItem(result.payload);
        }

        if (result.payload?.completionMailStatus) {
          ToastSuccess('Successfully updated system change completion.');
        } else {
          ToastWarning(
            'Successfully updated system change completion but failed to send notice.'
          );
        }
      }
      setIsOpen(false);
    },
    []
  );

  return (
    <SystemChangeItemDetail
      data={source === 'ler' ? sysChangeItem : item}
      formRef={formRef}
      handleClick={handleClick}
      handleSubmit={handleSubmit}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      loading={systemChangeStatus.fetching}
      source={source}
    />
  );
};

export default SystemChangeItem;
