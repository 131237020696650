import React, { useEffect, useState } from 'react';
import {
  ATMButton,
  ATMCheckbox,
  ATMDatePicker,
  ATMDropdown,
  ATMField,
  ATMForm,
  ATMGrid,
  ATMInput,
  IORGDataTableQueryState,
} from 'shared-it-appmod-ui';
import { orderBy } from 'lodash';
import { useRealTimeLogContext } from 'src/contexts/real-time-log.context';
import { useSubstationContext } from 'src/contexts/substation.context';
import Moment from 'src/libraries/moment.library';
import Lang from 'src/libraries/language';
import {
  IEmployeesRealTimeLog,
  IRealTimeLogAll,
  RealTimeEntryLogAllSchema,
} from 'src/models/real-time-log.model';
import style from './real-time-log-all.module.scss';

type IProps = {
  formRef?: React.RefObject<HTMLFormElement>;
  data?: IRealTimeLogAll;
  handleSubmit: (data: IRealTimeLogAll) => void;
  setShowTableAll: (x: boolean) => void;
  handleClick: () => void;
  employees: IEmployeesRealTimeLog[];
  setIsSearch: React.Dispatch<React.SetStateAction<boolean>>;
  setShowAll: React.Dispatch<React.SetStateAction<boolean>>;
  showAll: boolean;
  loading: boolean;
  searchFilters?: Partial<IORGDataTableQueryState>;
};

const RealTimeLogAll: React.FC<IProps> = ({
  formRef,
  handleSubmit,
  handleClick,
  employees,
  setShowTableAll,
  setIsSearch,
  setShowAll,
  showAll,
  loading,
  searchFilters,
}) => {
  const { state: realtimeLogState } = useRealTimeLogContext();
  const { state: substationState, actions: substationActions } =
    useSubstationContext();
  const [lineSubstation, setLineSubstation] = useState<string>('');
  const [dateRange, setDateRange] = useState<Date>(new Date());
  const handleDateRange = (date: Date) => {
    const dateLimit = Moment(date).add(6, 'months');
    setDateRange(dateLimit.isAfter(Moment()) ? new Date() : dateLimit.toDate());
  };

  useEffect(() => {
    if (substationState.list.length === 0) {
      substationActions.listGET({
        limit: 0,
        page: 1,
      });
    }
  }, [substationActions]);

  return (
    <div className={style.filterContainer}>
      <ATMForm
        ref={formRef}
        onSubmit={handleSubmit}
        mode="onChange"
        validationSchema={RealTimeEntryLogAllSchema}
        // defaultValues={RealTimeEntryLogAllSchema.cast({
        //   logDtTm: searchFilters?.filters
        //     ?.filter((criteria) => criteria.name === 'logDtTm')
        //     .map((element) => element.value)[0],
        // })}
      >
        {({ control, formState: { errors, isDirty }, reset, clearErrors }) => {
          return (
            <ATMGrid>
              <ATMGrid.Row
                columns={4}
                style={{
                  marginBottom: errors && errors.logDtTm ? '2em' : '0',
                  paddingBottom: '0',
                }}
              >
                <ATMGrid.Column
                  width={3}
                  className={style.selectables}
                  style={{ paddingRight: '1em' }}
                >
                  <ATMField
                    as={ATMDatePicker}
                    name="logDtTm"
                    type="range"
                    format="MM/DD/YYYY"
                    maxDate={dateRange}
                    control={control}
                    error={errors.logDtTm}
                    keepFocus
                    onChange={([_, { value }]) => {
                      if (value) {
                        handleDateRange(value[0]);
                        // setDateRange(
                        //   value[0].setMonth(Number(value[0].getMonth()) + 3)
                        // );
                      }
                      setIsSearch(false);
                      return value ?? undefined;
                    }}
                    showToday={false}
                  />
                </ATMGrid.Column>
                <ATMGrid.Column style={{ paddingLeft: '0' }} width={3}>
                  <ATMField
                    as={ATMInput}
                    name="logEntry"
                    placeholder="Entry text"
                    control={control}
                    error={errors.logEntry}
                    onChange={([_, { value }]) => {
                      setIsSearch(false);
                      return value ?? undefined;
                    }}
                    clearable
                  />
                </ATMGrid.Column>
                <ATMGrid.Column style={{ paddingLeft: '0' }} width={4}>
                  <ATMField
                    as={ATMDropdown}
                    selection
                    clearable
                    name="logUserId"
                    placeholder="Employee Name"
                    control={control}
                    error={errors.logUserId}
                    style={{ display: 'block', marginRight: '1em' }}
                    options={employees.map((value) => ({
                      key: value.emp.empId,
                      value: value.logUserId,
                      text:
                        value.emp.firstName && value.emp.lastName
                          ? `${value.emp.lastName}, ${value.emp.firstName}`
                          : `Unavailable (${value.logUserId})`,
                    }))}
                    onChange={([_, { value }]) => {
                      setIsSearch(false);
                      return value ?? undefined;
                    }}
                    search
                    selectOnBlur={false}
                  />
                </ATMGrid.Column>
                <ATMGrid.Column width={6}>
                  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <ATMField
                      as={ATMCheckbox}
                      label="My Entries"
                      style={{ marginTop: '0.6em' }}
                      name="isMyEntries"
                      inline="true"
                      control={control}
                      error={errors.isMyEntries}
                      onChange={([_, { checked }]) => {
                        setIsSearch(false);
                        return Boolean(checked) || undefined;
                      }}
                    />
                    <ATMField
                      as={ATMCheckbox}
                      label="OSS"
                      style={{ marginTop: '0.6em', marginLeft: '0.6em' }}
                      name="isOssEntries"
                      inline="true"
                      control={control}
                      error={errors.isOssEntries}
                      onChange={([_, { checked }]) => {
                        setIsSearch(false);
                        return Boolean(checked) || undefined;
                      }}
                    />
                    <ATMField
                      as={ATMCheckbox}
                      label="TSO"
                      style={{ marginTop: '0.6em', marginLeft: '0.6em' }}
                      name="isTsoEntries"
                      inline="true"
                      control={control}
                      error={errors.isTsoEntries}
                      onChange={([_, { checked }]) => {
                        setIsSearch(false);
                        return Boolean(checked) || undefined;
                      }}
                    />
                    <ATMField
                      as={ATMCheckbox}
                      label="BPOR"
                      style={{ marginTop: '0.6em', marginLeft: '0.6em' }}
                      name="logBporReport"
                      inline="true"
                      control={control}
                      error={errors.logBporReport}
                      onChange={([_, { checked }]) => {
                        setIsSearch(false);
                        return Boolean(checked) || undefined;
                      }}
                    />
                  </div>
                </ATMGrid.Column>
              </ATMGrid.Row>
              <ATMGrid.Row columns={2} style={{ paddingTop: '0' }}>
                <ATMGrid.Column width={4}>
                  <label className={style.filterLbl}>
                    {Lang.LBL_LINE_SUBSTATION}
                  </label>
                  <ATMField
                    as={ATMDropdown}
                    selection
                    name="lineSubstation"
                    placeholder="Select"
                    size="small"
                    error={errors.lineSubstation}
                    style={{ width: '100%' }}
                    control={control}
                    options={[
                      {
                        key: 'line',
                        text: 'Line',
                        value: 'line',
                      },
                      {
                        key: 'substation',
                        text: 'Substation',
                        value: 'substation',
                      },
                    ]}
                    onChange={([_, { value }]) => {
                      setIsSearch(false);
                      if (value) {
                        setLineSubstation(value);
                      } else {
                        setLineSubstation('');
                      }
                      return value;
                    }}
                    clearable
                    selectOnBlur={false}
                  />
                </ATMGrid.Column>
                <ATMGrid.Column width={5} style={{ paddingLeft: '0' }}>
                  <label className={style.filterLbl} />
                  <ATMField
                    as={ATMDropdown}
                    style={{ marginTop: '1.4rem', width: '100%' }}
                    size="small"
                    name={
                      lineSubstation === 'line' ? 'logFacId' : 'logSubstation'
                    }
                    error={
                      lineSubstation === 'line'
                        ? errors.logFacId
                        : errors.logSubstation
                    }
                    selection
                    clearable
                    control={control}
                    placeholder="Select"
                    options={
                      lineSubstation === 'line'
                        ? realtimeLogState.line.map((value) => ({
                            key: value.outgFacId,
                            value: value.outgFacId,
                            text: value.outgFacNm,
                          }))
                        : substationState.list.map((value) => ({
                            key: value.substationId,
                            value: value.substationId,
                            text: value.name,
                          }))
                    }
                    onChange={([_, { value }]) => {
                      setIsSearch(false);
                      return value;
                    }}
                    disabled={!lineSubstation}
                    search
                    selectOnBlur={false}
                  />
                </ATMGrid.Column>
                <ATMGrid.Column width={4} style={{ paddingLeft: '0px' }}>
                  <label className={style.filterLbl}>{Lang.LBL_CATEGORY}</label>
                  <ATMField
                    as={ATMDropdown}
                    selection
                    name="logCategory"
                    placeholder="Select"
                    size="small"
                    error={errors.logCategory}
                    style={{ width: '100%' }}
                    control={control}
                    options={orderBy(
                      [
                        ...Array.from(
                          Object.keys(Lang.REAL_TIME_LOG_STATUS)
                        ).map((val) => {
                          return {
                            value: val,
                            text: Lang.REAL_TIME_LOG_STATUS[val],
                          };
                        }),
                      ],
                      'text',
                      'asc'
                    )}
                    onChange={([_, { value }]) => {
                      setIsSearch(false);
                      return value;
                    }}
                    clearable
                    search
                    selectOnBlur={false}
                  />
                </ATMGrid.Column>
              </ATMGrid.Row>
              <ATMGrid.Row style={{ paddingTop: '0' }}>
                <ATMGrid.Column>
                  <ATMButton
                    primary
                    content="Search"
                    onClick={handleClick}
                    disabled={!isDirty && !searchFilters}
                    type="submit"
                    loading={loading}
                  />
                  <ATMButton
                    secondary
                    content="Clear Fields"
                    type="button"
                    disabled={!isDirty && !searchFilters}
                    style={{ marginLeft: '1em' }}
                    onClick={() => {
                      reset({
                        logEntry: '',
                        logDtTm: [],
                        logUserId: '',
                        isMyEntries: false,
                        isOssEntries: false,
                        isTsoEntries: false,
                        logBporReport: false,
                        lineSubstation: '',
                        logFacId: '',
                        logSubstation: '',
                        logCategory: '',
                      });
                      setImmediate(() => {
                        clearErrors();
                        setShowTableAll(false);
                        setIsSearch(false);
                        setShowAll(!showAll);
                      });
                      setLineSubstation('');
                    }}
                  />
                </ATMGrid.Column>
              </ATMGrid.Row>
            </ATMGrid>
          );
        }}
      </ATMForm>
    </div>
  );
};

export default RealTimeLogAll;
