import { UseFormReturn } from 'react-hook-form';
import { RealTimeLogCategory, REGEX_24HR_TIME } from 'src/constants';
import Lang from 'src/libraries/language';
import yup from 'src/libraries/validator.library';
// import { arraysEqualUnordered } from 'src/helpers/real-time-log.helper';
import { FacilityMinShape, FacTypShape } from './facility.model';
import { SubstationSchema } from './substation.model';
import { VoltShape } from './volt.model';
import {
  RealTimeLogNotesShape,
  RealTimeLogNotesPayloadShape,
} from './real-time-log-notes.model';
import { RealTimeLogAuditSimpleShape } from './real-time-log-audit.model';
import { SystemChangeReportSchema } from './system-change.model';

export const FacilityLogShape = {
  outgFacId: yup.number().required(),
  outgFacNm: yup.string().required(),
  volt: yup.object(VoltShape),
  facTyp: yup.object(FacTypShape).nullable(),
};

export const ForcedLogSubsShape = {
  logId: yup.number().required(),
  substationId: yup.string().required(),
  updatedAt: yup.date().required(),
  updatedBy: yup.string().required(),
};

export const ForcedLogShape = {
  logId: yup.number().required(),
  logSub: yup.string().required(),
  tripsTargets: yup.string().nullable(),
  updatedAt: yup.date().required(),
  updatedBy: yup.string().required(),
};

export const ForcedLogListSchema = yup.object({
  ...ForcedLogShape,
  substation: yup
    .object({
      substationId: yup.string().required(),
      name: yup.string().required(),
    })
    .required(),
});

export const RealTimeLogShape = {
  logId: yup.number().required(),
  logFacId: yup.number().nullable(),
  logDtTm: yup.date(),
  logUserId: yup.string(),
  logUserType: yup.string(),
  logEntry: yup.string(),
  logBporReport: yup.boolean().nullable(),
  logSubstation: yup.string().nullable(),
  logDtTmFrcdOutOccurred: yup.date().nullable(),
  logDtTmFrcdOutRestored: yup.date().nullable(),
  logCategory: yup.string().nullable(),
  updatedAt: yup.date(),
  updatedBy: yup.string(),
  emailContent: yup.string().nullable(),
  emp: yup.object({
    lastName: yup.string(),
    firstName: yup.string(),
    empId: yup.string(),
  }),
  substation: yup
    .object({
      substationId: yup.string(),
      name: yup.string(),
    })
    .nullable(),
  facility: yup.object(FacilityLogShape).nullable(),
  forcedLog: yup.array().of(ForcedLogListSchema.clone()),
  forcedLogSubs: yup.array().of(
    yup.object({
      ...ForcedLogSubsShape,
      substation: yup
        .object({
          substationId: yup.string().required(),
          name: yup.string().required(),
        })
        .required(),
    })
  ),
  frcdOutgEmailSent: yup.boolean().nullable(),
  logInProgress: yup.boolean().nullable(),
  changeLog: yup.array().of(yup.object(RealTimeLogNotesShape)).nullable(),
  auditLogs: yup.array().of(yup.object(RealTimeLogAuditSimpleShape)),
};

export const ForcedOutageConfirmationFormShape = {
  logId: yup.number().required(),
  mailSubject: yup
    .string()
    .required()
    .test(
      'check-subject',
      'Subject requires an update',
      (value) => !value?.includes('<edit subject here>')
    ),
  mailContent: yup.string().required(),
  mailHistoryContent: yup.string(),
};

export const RealTimeLogSchema = yup.object(RealTimeLogShape);

export const EmployeesRealTimeLogSchema = yup.object({
  logUserId: yup.string(),
  emp: yup.object({
    lastName: yup.string(),
    firstName: yup.string(),
    empId: yup.string(),
  }),
});

export const ForcedOutageConfirmationFormSchema = yup.object(
  ForcedOutageConfirmationFormShape
);

export const RealTimeLogEntryFormSchema = yup.object({
  logId: yup.number(),
  logFacId: yup
    .number()
    .nullable()
    .when('lineSubstation', {
      is: 'line',
      then: yup.number().typeError(Lang.MSG_ERROR_REQUIRED).required(),
    })
    .when(['lineSubstation', 'logSubstation', 'equipmentType', 'volt'], {
      is: (lineSubstation, logSubstation, equipmentType, volt) =>
        lineSubstation === 'substation' &&
        logSubstation &&
        equipmentType &&
        volt,
      then: yup.number().typeError(Lang.MSG_ERROR_REQUIRED).required(),
    }),
  logDtTm: yup.date().typeError('Invalid Date').required(),
  logTm: yup
    .string()
    .required()
    .matches(REGEX_24HR_TIME, 'Invalid Time. Format: hh:mm'),
  logUserId: yup.string().required(),
  logUserType: yup.string().required(),
  logEntry: yup.string().max(2500).required(),
  logBporReport: yup.boolean(),
  logSubstation: yup
    .string()
    .nullable()
    .when('lineSubstation', {
      is: 'substation',
      then: yup.string().typeError(Lang.MSG_ERROR_REQUIRED).required(),
    }),
  logDtTmFrcdOutOccurred: yup
    .date()
    .nullable()
    .when('logCategory', {
      is: (logCategory) => logCategory === RealTimeLogCategory.FORCEDOUTAGE,
      // logCategory === RealTimeLogCategory.RCBANOTIF,
      then: yup
        .date()
        .typeError('Invalid Date')
        .nullable()
        .test(
          'check-occured',
          'Occurrence is required if restoration is set',
          function checkOccurrence() {
            const {
              logCategory,
              logDtTmFrcdOutOccurred,
              logDtTmFrcdOutRestored,
            } = this.parent;
            if (
              logCategory === RealTimeLogCategory.FORCEDOUTAGE &&
              logDtTmFrcdOutRestored &&
              !logDtTmFrcdOutOccurred
            ) {
              return false;
            }
            return true;
          }
        ),
    }),
  logTmFrcdOutOccurred: yup
    .string()
    .nullable()
    .when(['logCategory', 'logDtTmFrcdOutOccurred'], {
      is: (logCategory, logDtTmFrcdOutOccurred) =>
        // (logCategory === RealTimeLogCategory.FORCEDOUTAGE ||
        //   logCategory === RealTimeLogCategory.RCBANOTIF) &&
        logCategory === RealTimeLogCategory.FORCEDOUTAGE &&
        logDtTmFrcdOutOccurred,
      then: yup
        .string()
        .required()
        .typeError('This field is required')
        .matches(REGEX_24HR_TIME, 'Invalid Time. Format: hh:mm')
        .test(
          'check-against-current1',
          'Occurrence cannot be in the future',
          function checkAgainstCurrent1() {
            const {
              logCategory,
              logDtTmFrcdOutOccurred,
              logTmFrcdOutOccurred,
            } = this.parent;
            const currentDate = new Date();
            if (
              logCategory === RealTimeLogCategory.FORCEDOUTAGE &&
              logDtTmFrcdOutOccurred &&
              logTmFrcdOutOccurred &&
              logDtTmFrcdOutOccurred > currentDate
            ) {
              return false;
            }
            return true;
          }
        )
        .test(
          'check-date1',
          'Occurrence should be before or the same with the restoration',
          function checkDate1() {
            const {
              logCategory,
              logDtTmFrcdOutOccurred,
              logDtTmFrcdOutRestored,
              logTmFrcdOutRestored,
              logTmFrcdOutOccurred,
            } = this.parent;
            if (
              logCategory === RealTimeLogCategory.FORCEDOUTAGE &&
              logDtTmFrcdOutRestored &&
              logDtTmFrcdOutOccurred &&
              logTmFrcdOutRestored &&
              logTmFrcdOutOccurred &&
              logDtTmFrcdOutOccurred > logDtTmFrcdOutRestored
            ) {
              return false;
            }
            return true;
          }
        ),
    }),
  logDtTmFrcdOutRestored: yup
    .date()
    .nullable()
    .when('logCategory', {
      is: (logCategory) => logCategory === RealTimeLogCategory.FORCEDOUTAGE,
      then: yup.date().typeError('Invalid Date').nullable(),
    }),
  logTmFrcdOutRestored: yup
    .string()
    .nullable()
    .when(['logCategory', 'logDtTmFrcdOutRestored'], {
      is: (logCategory, logDtTmFrcdOutRestored) =>
        logCategory === RealTimeLogCategory.FORCEDOUTAGE &&
        logDtTmFrcdOutRestored,
      then: yup
        .string()
        .required()
        .typeError('This field is required')
        .matches(REGEX_24HR_TIME, 'Invalid Time. Format: hh:mm')
        .test(
          'check-against-current2',
          'Restoration cannot be in the future',
          function checkAgainstCurrent2() {
            const {
              logCategory,
              logDtTmFrcdOutRestored,
              logTmFrcdOutRestored,
            } = this.parent;
            const currentDate = new Date();
            if (
              logCategory === RealTimeLogCategory.FORCEDOUTAGE &&
              logDtTmFrcdOutRestored &&
              logTmFrcdOutRestored &&
              logDtTmFrcdOutRestored > currentDate
            ) {
              return false;
            }
            return true;
          }
        )
        .test(
          'check-date2',
          'Restoration should be after or the same with the occurrence',
          function checkDate2() {
            const {
              logCategory,
              logDtTmFrcdOutOccurred,
              logDtTmFrcdOutRestored,
              logTmFrcdOutRestored,
              logTmFrcdOutOccurred,
            } = this.parent;
            if (
              logCategory === RealTimeLogCategory.FORCEDOUTAGE &&
              logDtTmFrcdOutRestored &&
              logDtTmFrcdOutOccurred &&
              logTmFrcdOutRestored &&
              logTmFrcdOutOccurred &&
              logDtTmFrcdOutRestored < logDtTmFrcdOutOccurred
            ) {
              return false;
            }
            return true;
          }
        ),
    }),

  logCategory: yup.string().nullable(),
  updatedAt: yup.date(),
  updatedBy: yup.string(),
  forcedLog: yup
    .array()
    .of(
      yup.object({
        logSub: yup.string(),
        tripsTargets: yup.string(),
      })
    )
    .required()
    .default([])
    .when('logCategory', {
      is: RealTimeLogCategory.FORCEDOUTAGE,
      then: yup.array().of(
        yup.object({
          logSub: yup.string(),
          tripsTargets: yup.string(),
        })
      ),
    }),
  forcedLogSubs: yup
    .array()
    .of(yup.string().required())
    .default([])
    .when('logCategory', {
      is: RealTimeLogCategory.FORCEDOUTAGE,
      then: yup.array().of(yup.string()).required(),
    }),
  volt: yup
    .number()
    .nullable()
    .when(['lineSubstation', 'logSubstation', 'equipmentType'], {
      is: (lineSubstation, logSubstation, equipmentType) =>
        lineSubstation === 'substation' && logSubstation && equipmentType,
      then: yup.number().typeError(Lang.MSG_ERROR_REQUIRED).required(),
    }),
  equipmentType: yup
    .number()
    .nullable()
    .when(['lineSubstation', 'logSubstation'], {
      is: (lineSubstation, logSubstation) =>
        lineSubstation === 'substation' && logSubstation,
      then: yup.number().typeError(Lang.MSG_ERROR_REQUIRED).required(),
    }),

  lineSubstation: yup.string().nullable().when('logCategory', {
    is: RealTimeLogCategory.FORCEDOUTAGE,
    then: yup.string().required(),
  }),
  changeLogNote: yup.string().nullable(),
  // .test('check-modified', 'This field is required', function checkModfied() {
  //   const {
  //     changeLogNote,
  //     logId,
  //     logDtTm,
  //     logUserType,
  //     // logBporReport,
  //     changeLogForUpdate,
  //     logCategory,
  //     forcedLogSubs,
  //     logDtTmFrcdOutOccurred,
  //     logDtTmFrcdOutRestored,
  //     logFacId,
  //     logEntry,
  //     forcedLog,
  //     previousValue,
  //   } = this.parent;

  //   let isForcedLogSubsEqual;
  //   let isTripsTargetsEqual;

  //   if (previousValue) {
  //     isForcedLogSubsEqual = arraysEqualUnordered(
  //       forcedLogSubs,
  //       previousValue.forcedLogSubs
  //     );

  //     isTripsTargetsEqual = arraysEqualUnordered(
  //       forcedLog,
  //       previousValue.forcedLog
  //     );
  //   }

  //   if (logCategory && logId && !changeLogForUpdate) {
  //     if (!changeLogNote) {
  //       if (
  //         logCategory !== previousValue.logCategory ||
  //         (logDtTm && (logDtTm as Date).getTime()) !==
  //           (
  //             previousValue.logDtTm && (previousValue.logDtTm as Date)
  //           ).getTime() ||
  //         logUserType !== previousValue.logUserType ||
  //         // logBporReport !== previousValue.logBporReport ||
  //         !isForcedLogSubsEqual ||
  //         (logDtTmFrcdOutOccurred &&
  //           (logDtTmFrcdOutOccurred as Date).getTime()) !==
  //           (previousValue.logDtTmFrcdOutOccurred &&
  //             (previousValue.logDtTmFrcdOutOccurred as Date).getTime()) ||
  //         (logDtTmFrcdOutRestored &&
  //           (logDtTmFrcdOutRestored as Date).getTime()) !==
  //           (previousValue.logDtTmFrcdOutRestored &&
  //             (previousValue.logDtTmFrcdOutRestored as Date).getTime()) ||
  //         logFacId !== previousValue.logFacId ||
  //         logEntry !== previousValue.logEntry ||
  //         !isTripsTargetsEqual
  //       ) {
  //         return false;
  //       }
  //     }
  //   }
  //   return true;
  // }),
  // .when(['logCategory', 'logId', 'changeLogForUpdate', 'previousValue'], {
  //   is: (logCategory, logId, changeLogForUpdate, previousValue) => {
  //     if (logCategory && logId && !changeLogForUpdate) {

  //       if (previousValue.logCategory)
  //       return true;
  //     }

  //     return false;
  //   },
  //   then: yup.string().required(),
  // }),
  changeLog: yup.array().of(yup.object(RealTimeLogNotesShape)).nullable(),
  changeLogForUpdate: yup
    .object(RealTimeLogNotesPayloadShape)
    .default(null)
    .nullable(),
  tripsTargetsEntry: yup.string().nullable(),
  originalOccurrence: yup.date().nullable(),
  originalRestoration: yup.date().nullable(),
  logInProgress: yup.boolean().nullable(),
  previousValue: yup.object().nullable().notRequired(),
});

export const RealTimeEntryLogAllSchema = yup.object({
  logUserId: yup.string().nullable(),
  logDtTm: yup.array().of(yup.date()),
  logEntry: yup.string(),
  isMyEntries: yup.boolean(),
  isOssEntries: yup.boolean(),
  isTsoEntries: yup.boolean(),
  logBporReport: yup.boolean(),
  lineSubstation: yup.string(),
  logFacId: yup.string().when('lineSubstation', {
    is: 'line',
    then: yup.string().typeError(Lang.MSG_ERROR_REQUIRED).required(),
  }),
  logSubstation: yup.string().when('lineSubstation', {
    is: 'substation',
    then: yup.string().typeError(Lang.MSG_ERROR_REQUIRED).required(),
  }),
  logCategory: yup.string(),
});

export const RealTimeLogInProgressPayloadSchema = yup.object({
  count: yup.number(),
  rows: yup.array().of(RealTimeLogSchema.clone()),
});

export const RealTimeLogListPayloadSchema = yup.object({
  count: yup.object({
    all: yup.number(),
    today: yup.number(),
  }),
  // count: yup.number(),
  rows: yup.array().of(RealTimeLogSchema.clone()),
});

export const BporReportPayloadSchema = yup.object({
  count: yup.object({
    forcedInterruptions: yup.number(),
    completedSystemChanges: yup.number(),
  }),
  rows: yup.object({
    forcedInterruptions: yup.array().of(RealTimeLogSchema.clone()),
    completedSystemChanges: yup.array().of(SystemChangeReportSchema.clone()),
  }),
});

export const EmployeesRealTimeLogListPayloadSchema = yup.object({
  count: yup.number(),
  rows: yup.array().of(EmployeesRealTimeLogSchema.clone()),
});

export const RealTimeLogMailerSchema = yup.object({
  message: yup.string(),
});

export const ForcedOutageNotificationStatusSchema = yup.object({
  status: yup.number(),
  message: yup.string(),
  data: yup
    .object({
      logId: yup.number().required(),
      frcdOutgEmailSent: yup.boolean().nullable(),
      logInProgress: yup.boolean().nullable(),
    })
    .nullable(),
  uploadResponse: yup
    .object({
      status: yup.number(),
      message: yup.string(),
    })
    .nullable(),
});

export const RealTimeLogPayloadSchema = RealTimeLogSchema.clone();

export const RealTimeLogDeletePayloadSchema = yup.object({
  success: yup.boolean().required(),
});

export const RealTimeLogLineSchema = yup.object({
  ...FacilityMinShape,
  substations: yup.array(SubstationSchema.clone()),
});

export const RealTimeLogBPORReportSchema = yup.object({
  bporReport: yup.array().of(yup.string()).nullable(),
  isNothingToReport: yup.boolean().nullable(),
  reportDate: yup.date(),
});

export const RealTimeLogLinePayloadSchema = yup
  .array()
  .of(RealTimeLogLineSchema.clone());

export const EmailFieldSchema = yup.object({
  fieldTitle: yup.string().required(),
  fieldValue: yup.string().nullable(),
});

export const RealTimeLogBPORTextRunSchema = yup.object({
  textRun: yup.object({
    underline: yup.object({
      type: yup.string().nullable(),
    }),
    text: yup.string().nullable(),
    italics: yup.boolean().nullable(),
    bold: yup.boolean().nullable(),
    font: yup.string().nullable(),
    size: yup.number().nullable(),
    color: yup.string().nullable(),
  }),
  alignment: yup.string().nullable(),
  indent: yup
    .object({
      left: yup.number().nullable(),
      right: yup.number().nullable(),
    })
    .nullable(),
});

export const BPORLogoResponseSchema = yup.object({
  logo: yup.string().nullable(),
});

export const FacilityLogSchema = yup.object(FacilityLogShape);

export type IFacilityLog = yup.Asserts<typeof FacilityLogSchema>;
export type IRealTimeLog = yup.Asserts<typeof RealTimeLogSchema>;
export type IEmployeesRealTimeLog = yup.Asserts<
  typeof EmployeesRealTimeLogSchema
>;
export type IRealTimeLogLinePayload = yup.Asserts<
  typeof RealTimeLogLinePayloadSchema
>;
export type IRealTimeLogAll = yup.Asserts<typeof RealTimeEntryLogAllSchema>;
export type IRealTimeLogLine = yup.Asserts<typeof RealTimeLogLineSchema>;
export type IRealTimeLogEntryForm = yup.Asserts<
  typeof RealTimeLogEntryFormSchema
>;
export type IRealTimeLogMailer = yup.Asserts<typeof RealTimeLogMailerSchema>;
export type IForcedOutageNotificationStatus = yup.Asserts<
  typeof ForcedOutageNotificationStatusSchema
>;

export type IForcedOutageConfirmationForm = yup.Asserts<
  typeof ForcedOutageConfirmationFormSchema
>;

export type IRealTimeLogFieldModel = yup.Asserts<typeof EmailFieldSchema>;
export type IRealTimeLogBPORReport = yup.Asserts<
  typeof RealTimeLogBPORReportSchema
>;

export type IBporReportPayload = yup.Asserts<typeof BporReportPayloadSchema>;
export type IForcedLogList = yup.Asserts<typeof ForcedLogListSchema>;

export type IRealTimeLogBPORTextRun = yup.Asserts<
  typeof RealTimeLogBPORTextRunSchema
>;
export type IBporReport = yup.Asserts<typeof BporReportPayloadSchema>;
export type IBPORLogoResponse = yup.Asserts<typeof BPORLogoResponseSchema>;

export type IEntryLogFormMethods = Pick<
  UseFormReturn<IRealTimeLogEntryForm>,
  'control' | 'formState'
> &
  Partial<
    Pick<
      UseFormReturn<IRealTimeLogEntryForm>,
      'setError' | 'clearErrors' | 'formState' | 'reset' | 'watch' | 'register'
    >
  >;
