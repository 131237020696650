import React, { useCallback, useEffect, useState, useRef } from 'react';
import { Progress } from 'semantic-ui-react';
import { ATMButton, ATMIcon, ATMPopover } from 'shared-it-appmod-ui';
import Lang from 'src/libraries/language';
import { IFile } from 'src/models/file.model';
import { useFileContext } from 'src/contexts/file.context';
import { fileActionTypes } from 'src/ducks/file.duck';
import Confirm from 'src/components/atoms/confirm/confirm.component';
import { getFileStatus } from 'src/selectors/file.selector';
import { ToastSuccess, ToastError } from '../toaster/toaster.component';
import styles from './file-uploader.module.scss';

export type IProps = {
  file?: File | File[];
  text?: string;
  icon?: string;
  error?: boolean;
  displayRemoveButton?: boolean;
  returnCSVData?: boolean;
  displayButton?: boolean;
  displayMessage?: boolean;
  successMessage?: string;
  errorMessage?: string;
  onSuccess?: (file: File[]) => void;
  onError?: (error: any) => void;
  onDelete?: (file: IFile) => void;
  onReturn?: (data: Record<any, any>) => void;
  hasSharePointError?: boolean;
  popOverPostition?:
    | 'top center'
    | 'top left'
    | 'top right'
    | 'bottom right'
    | 'bottom left'
    | 'right center'
    | 'left center'
    | 'bottom center'
    | undefined;
  popOverStyle?: React.CSSProperties | undefined;
};

const FileUploader: React.FC<IProps> = ({
  file,
  icon,
  text = Lang.LBL_BROWSE,
  error = false,
  displayMessage = true,
  displayRemoveButton = true,
  successMessage = 'Success upload',
  errorMessage = 'Failed to upload',
  returnCSVData = false,
  onSuccess,
  onError,
  onDelete,
  onReturn,
  hasSharePointError = false,
  popOverPostition = 'top center',
  popOverStyle = { color: 'red', width: '150px' },
}) => {
  const { state, actions } = useFileContext();

  const fileInputRef = useRef<HTMLInputElement>(null);
  const [cachedFiles, setCachedFiles] = useState<File[]>(
    // eslint-disable-next-line no-nested-ternary
    file ? (Array.isArray(file) ? file : [file]) : []
  );
  const [loading, setLoading] = useState(false);
  const [percent, setPercent] = useState<number>();
  const [isConfirm, setIsConfirm] = useState<Partial<File>>();

  const statusDelete = getFileStatus(state, fileActionTypes.FILE_DELETE);

  useEffect(() => {
    if (file) {
      setCachedFiles(Array.isArray(file) ? file : [file]);
    }
  }, [file, setCachedFiles]);

  const handleConvert = useCallback((string) => {
    const csvRows = string.split(/[\n\r]+/).filter((value) => value !== '');
    const csvCells = csvRows.map((item) => ({
      ...item.split(','),
    }));
    return csvCells;
  }, []);

  const cleanup = useCallback(() => {
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }

    setCachedFiles([]);
    setPercent(undefined);

    setLoading(false);
  }, [fileInputRef, setCachedFiles, setPercent, setLoading]);

  const handleUpload = useCallback(
    (event) => {
      const list = Object.entries(event.currentTarget.files).map(
        ([, value]) => value
      ) as File[];

      if (returnCSVData === true) {
        const fileReader = new FileReader();
        const errorMsgs: string[] = [];
        const excelData: Record<any, any>[] = [];
        if (list.length > 0) {
          if (list[0].type !== 'text/csv') {
            errorMsgs.push(Lang.MSG_FILE_NOT_CSV);
          } else {
            fileReader.onload = ({ target }) => {
              const texted = target?.result;
              handleConvert(texted).map((item) => excelData.push(item));
              if (onReturn !== undefined) {
                if (excelData.length > 0 && errorMsgs.length === 0) {
                  onReturn(excelData);
                } else {
                  onReturn([]);
                }
              }
            };

            fileReader.readAsText(list[0]);
          }

          if (errorMsgs.length > 0 && onError !== undefined) {
            onError(errorMsgs);
          }
        }
      } else {
        const files: File[] = [];
        list.sort().map((val) => files.push(val));
        state.stash.sort().map((val) => files.push(val));
        const stashedFiles = Array.from(new Set(files.map((a) => a.name))).map(
          (fileName) => {
            return files.find((a) => a.name === fileName);
          }
        );
        actions.stashFile(stashedFiles.sort() as any);
        if (onSuccess !== undefined) {
          onSuccess(list);
          if (successMessage) {
            if (displayMessage) {
              ToastSuccess(successMessage);
            }
          }
        }

        if (onError !== undefined) {
          if (errorMessage) {
            ToastError(errorMessage);
          }
        }
      }

      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }

      setCachedFiles(list);
    },
    [
      onSuccess,
      onError,
      cleanup,
      setCachedFiles,
      handleConvert,
      fileInputRef,
      state,
      actions,
    ]
  );

  const handleRemove = useCallback(
    (data) => {
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
      setLoading(true);

      if (onDelete !== undefined) {
        onDelete(data);
      }

      setCachedFiles((values) =>
        values.filter((v) => v.lastModified !== data.lastModified)
      );

      setIsConfirm(undefined);
      setLoading(false);
    },
    [fileInputRef, setIsConfirm, onDelete, setCachedFiles]
  );

  let content: React.ReactNode = (
    <ATMButton
      secondary={!error}
      basic={error}
      color={error ? 'red' : undefined}
      type="button"
      onClick={() => {
        if (fileInputRef.current) {
          fileInputRef.current.click();
        }
      }}
    >
      {icon?.length ? <ATMIcon name={icon as any} /> : ''}
      {text}
    </ATMButton>
  );

  if (cachedFiles.length && displayRemoveButton) {
    content = (
      <div>
        {cachedFiles.map((value, index) => (
          <span key={index}>
            {value.name}
            <ATMButton
              icon="close"
              type="button"
              disabled={loading}
              loading={loading}
              onClick={() => setIsConfirm(value)}
            />
          </span>
        ))}
      </div>
    );
  } else if (percent !== undefined) {
    content = <Progress percent={percent} size="small" progress indicating />;
  }

  return (
    <div className={styles.wrapper}>
      {hasSharePointError ? (
        <ATMPopover
          style={popOverStyle}
          content="Unable to connect to Sharepoint at the moment"
          position={popOverPostition}
          size="mini"
          trigger={content}
        />
      ) : (
        content
      )}

      {returnCSVData === true ? (
        <input
          ref={fileInputRef}
          type="file"
          onChange={handleUpload}
          className="hidden"
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        />
      ) : (
        <input
          ref={fileInputRef}
          type="file"
          onChange={handleUpload}
          className="hidden"
          multiple
        />
      )}

      <Confirm
        open={!!isConfirm}
        size="tiny"
        onCancel={() => setIsConfirm(undefined)}
        header={Lang.TTL_CONFIRM_DELETE}
        content={Lang.MSG_FILE_CONFIRM_DELETE}
        loading={statusDelete.fetching}
        onConfirm={() => handleRemove(isConfirm)}
      />
    </div>
  );
};

export default FileUploader;
