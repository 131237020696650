import React, { useCallback, useState } from 'react';
import {
  ToastSuccess,
  ToastError,
} from 'src/components/atoms/toaster/toaster.component';
import Lang from 'src/libraries/language';
import { getRealTimeLogStatus } from 'src/selectors/real-time-log.selector';
import { realTimeLogActionTypes } from 'src/ducks/real-time-log.duck';
import { useRealTimeLogContext } from 'src/contexts/real-time-log.context';
import { ATMModal, ATMDropdown, ATMButton } from 'shared-it-appmod-ui';

type IProps = {
  logId: number;
};

const RealTimeLogListEmailTurnoff: React.FC<IProps> = ({ logId }) => {
  const { state, actions } = useRealTimeLogContext();
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);

  const bypassEmailStatus = getRealTimeLogStatus(
    state,
    realTimeLogActionTypes.FORCED_OUTAGE_EMAIL_TURNOFF
  );

  const clearForcedOutageNotice = useCallback(
    async (id) => {
      const response = await actions.handleTurnOffEmail(id);

      if (!response.error) {
        setIsConfirmOpen(false);
        ToastSuccess(Lang.MSG_FORCED_OUTAGE_EMAIL_TURNOFF);
      } else {
        ToastError(Lang.MSG_FORCED_OUTAGE_EMAIL_TURNOFF_ERROR);
      }
    },
    [actions]
  );

  return (
    <ATMModal
      open={isConfirmOpen}
      size="small"
      trigger={
        <ATMDropdown.Item
          content={Lang.LBL_TURNOFF_EMAIL}
          icon="close"
          onClick={() => setIsConfirmOpen(!isConfirmOpen)}
        />
      }
      closeOnDimmerClick={false}
      closeOnEscape={false}
    >
      <ATMModal.Header>
        {Lang.LBL_TURNOFF_FORCED_OUTAGE_EMAIL_CONFIRM_TITLE}
      </ATMModal.Header>
      <ATMModal.Content>
        <p>{Lang.LBL_TURNOFF_FORCED_OUTAGE_EMAIL_CONFIRM}</p>
      </ATMModal.Content>
      <ATMModal.Actions>
        <ATMButton
          type="button"
          secondary
          content={Lang.LBL_CANCEL}
          onClick={() => {
            setIsConfirmOpen(false);
          }}
          disabled={bypassEmailStatus.fetching}
        />
        <ATMButton
          type="button"
          negative
          content={Lang.LBL_TURNOFF_EMAIL}
          onClick={() => {
            clearForcedOutageNotice(logId);
          }}
          disabled={bypassEmailStatus.fetching}
          loading={bypassEmailStatus.fetching}
        />
      </ATMModal.Actions>
    </ATMModal>
  );
};

export default RealTimeLogListEmailTurnoff;
