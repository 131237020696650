import React, { useEffect, useRef } from 'react';
import { Editor } from '@tiptap/core';
// import { BubbleMenu } from '@tiptap/extension-bubble-menu';
import MenuBar from './menu-bar.component';
import styles from './editor.module.scss';
import { defaultExtensions } from './editor.component';
import { useRealTimeLogContext } from 'src/contexts/real-time-log.context';
import { generateRealTimeLogBPORReport } from 'src/helpers/real-time-log.helper';

type IProps = {
  reportDate: Date;
  loading?: boolean;
  setEditorContent: React.Dispatch<React.SetStateAction<string>>;
  editorContent?: string;
};

const RealTimeLogEditor: React.FC<IProps> = ({
  reportDate,
  // editorContent,
  setEditorContent,
}) => {
  const { state } = useRealTimeLogContext();
  const editor = useRef<Editor | null>(null);
  const editorContainer = useRef<HTMLDivElement | null>(null);
  // const bubbleMenuRef = useRef<HTMLDivElement | null>(null);
  // const [menuPosition, setMenuPosition] = useState({ top: 0, left: 0 });
  // const [showBubbleMenu, setShowBubbleMenu] = useState(false);

  useEffect(() => {
    if (editorContainer.current) {
      editor.current = new Editor({
        element: editorContainer.current,
        extensions: [
          ...defaultExtensions,
          // BubbleMenu.configure({
          //   element: bubbleMenuRef.current,
          // }),
        ],
        content: '',
        onUpdate({ editor: content }) {
          setEditorContent(content.getHTML());
        },
        // onTransaction: ({ transaction }) => {
        //   const { from, to } = transaction.selection;
        //   const coords = editor.current?.view.coordsAtPos(from);
        //   setShowBubbleMenu(from !== to);
        //   if (coords) {
        //     setMenuPosition({
        //       top: coords.top - 50,
        //       left: coords.left,
        //     });
        //   }
        // },
      });
    }

    return () => {
      if (editor.current) {
        editor.current.destroy();
      }
    };
  }, []);

  useEffect(() => {
    if (editor.current) {
      const reportTemplate = reportDate
        ? generateRealTimeLogBPORReport(
            state?.report ?? null,
            reportDate as any
          ).mailContent
        : null;
      editor.current.commands?.setContent(reportTemplate);
      setEditorContent(reportTemplate ?? editor.current?.getHTML());
    }
  }, [reportDate, state.report]);

  return (
    <div>
      <MenuBar editor={editor.current} isDefault={false} />
      {/* <div
        ref={bubbleMenuRef}
        style={{
          position: 'absolute',
          display: showBubbleMenu ? 'block' : 'none',
          top: `${menuPosition.top}px`,
          left: `${menuPosition.left}px`,
          zIndex: 10,
        }}
      >
        <MenuBar editor={editor.current} isPopup isDefault={false} />
      </div> */}
      <div ref={editorContainer} className={styles.editor} />
    </div>
  );
};

export default RealTimeLogEditor;
