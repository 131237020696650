/* eslint-disable no-nested-ternary */
import React from 'react';
import {
  ATMGrid,
  ATMField,
  ATMForm,
  ATMTextArea,
  ATMInput,
  ATMFormProvider,
  useATMFormContext,
  ATMButton,
  ATMModal,
} from 'shared-it-appmod-ui';
import Lang from 'src/libraries/language';
import {
  ForcedOutageConfirmationFormSchema,
  IForcedOutageConfirmationForm,
} from 'src/models/real-time-log.model';
import style from '../real-time-log-form.module.scss';

type IProp = {
  formRefEmail?: React.RefObject<HTMLFormElement>;
  // defaultValues?: Partial<IRealTimeLog>;
  handleConfirmEmailSubmit?: (data: IForcedOutageConfirmationForm) => void;
  handleConfirmEmailClick?: () => void;
  isForcedOutageConfirmOpen?: boolean;
  setIsForcedOutageConfirmOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  handleSendLater?: (logId: number) => void;
  logId: number;
  isClearNoticeConfirmOpen?: boolean;
  setIsClearNoticeConfirmOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  clearForcedOutageNotice?: (logId: number) => void;
  sendNowLoading?: boolean;
  sendLaterLoading?: boolean;
  turnOffEmailLoading?: boolean;
  mailFormDetails?: Partial<IForcedOutageConfirmationForm>;
  isOnEdit?: boolean;
  frcdOutgEmailSent?: boolean | null;
  enableEmailNotice?: (logId: number) => void;
  setIsEnableConfirmOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  isEnableConfirmOpen?: boolean;
  enableEmailLoading?: boolean;
};

type IFormContent = {
  frcdOutgEmailSent?: boolean | null;
  sendNowLoading?: boolean;
  sendLaterLoading?: boolean;
};

const FormContent: React.FC<IFormContent> = ({
  frcdOutgEmailSent,
  sendNowLoading,
  sendLaterLoading,
}) => {
  const {
    formState: { errors },
    control,
  } = useATMFormContext<IForcedOutageConfirmationForm>();

  return (
    <ATMGrid>
      <ATMGrid.Row style={{ paddingBottom: '0em' }}>
        <ATMGrid.Column>
          {frcdOutgEmailSent
            ? Lang.LBL_RESEND_FORCED_OUTAGE_CONFIRM
            : Lang.LBL_SEND_FORCED_OUTAGE_CONFIRM}
        </ATMGrid.Column>
      </ATMGrid.Row>
      <ATMGrid.Row>
        <ATMGrid.Column>
          <ATMField
            control={control}
            as={ATMInput}
            name="logId"
            type="hidden"
            className="hidden"
          />
          <ATMField
            control={control}
            as={ATMTextArea}
            name="mailHistoryContent"
            type="hidden"
            className="hidden"
          />
          <ATMField
            control={control}
            as={ATMInput}
            name="mailSubject"
            error={errors.mailSubject}
            autoComplete="off"
            maxLength={100}
            placeholder="Format: Real-Time Interruption: <subject details>"
            disabled={sendNowLoading || sendLaterLoading}
            label={
              <span className={style.emphasizedSmall}>
                You may edit the details before sending
              </span>
            }
            onChange={([_, { value }]) => value}
          />
          <ATMField
            as={ATMTextArea}
            name="mailContent"
            maxLength={2500}
            control={control}
            error={errors.mailContent}
            rows={5}
            disabled={sendNowLoading || sendLaterLoading}
            autoComplete="off"
          />
        </ATMGrid.Column>
      </ATMGrid.Row>
    </ATMGrid>
  );
};

const ForcedOutageConfirmationForm: React.FC<IProp> = ({
  formRefEmail,
  handleConfirmEmailSubmit,
  handleConfirmEmailClick,
  isForcedOutageConfirmOpen,
  setIsForcedOutageConfirmOpen,
  handleSendLater,
  logId,
  sendNowLoading,
  sendLaterLoading,
  isClearNoticeConfirmOpen,
  setIsClearNoticeConfirmOpen,
  clearForcedOutageNotice,
  turnOffEmailLoading,
  isOnEdit,
  mailFormDetails,
  frcdOutgEmailSent,
  enableEmailNotice,
  setIsEnableConfirmOpen,
  isEnableConfirmOpen,
  enableEmailLoading,
}) => {
  return (
    <>
      {setIsClearNoticeConfirmOpen &&
        clearForcedOutageNotice &&
        isClearNoticeConfirmOpen && (
          <ATMModal
            open={isClearNoticeConfirmOpen}
            size="tiny"
            onClose={() => setIsClearNoticeConfirmOpen(false)}
            onOpen={() => setIsClearNoticeConfirmOpen(true)}
            closeOnDimmerClick={false}
            closeOnEscape={false}
          >
            <ATMModal.Header>
              {Lang.LBL_TURNOFF_FORCED_OUTAGE_EMAIL_CONFIRM_TITLE}
            </ATMModal.Header>
            <ATMModal.Content>
              {Lang.LBL_TURNOFF_FORCED_OUTAGE_EMAIL_CONFIRM}
            </ATMModal.Content>
            <ATMModal.Actions>
              <ATMButton
                type="button"
                secondary
                content={Lang.LBL_CANCEL}
                onClick={() => {
                  setIsClearNoticeConfirmOpen(false);
                }}
                disabled={turnOffEmailLoading}
              />
              <ATMButton
                type="button"
                negative
                content={Lang.LBL_TURNOFF_EMAIL}
                onClick={() => {
                  clearForcedOutageNotice(logId);
                }}
                disabled={turnOffEmailLoading}
                loading={turnOffEmailLoading}
              />
            </ATMModal.Actions>
          </ATMModal>
        )}
      {setIsEnableConfirmOpen && enableEmailNotice && isEnableConfirmOpen && (
        <ATMModal
          open={isEnableConfirmOpen}
          size="tiny"
          onClose={() => setIsEnableConfirmOpen(false)}
          onOpen={() => setIsEnableConfirmOpen(true)}
          closeOnDimmerClick={false}
          closeOnEscape={false}
        >
          <ATMModal.Header>
            {Lang.LBL_ENABLE_FORCED_OUTAGE_EMAIL_CONFIRM_TITLE}
          </ATMModal.Header>
          <ATMModal.Content>
            {Lang.LBL_ENABLE_FORCED_OUTAGE_EMAIL_CONFIRM}
          </ATMModal.Content>
          <ATMModal.Actions>
            <ATMButton
              type="button"
              secondary
              content={Lang.LBL_CANCEL}
              onClick={() => {
                setIsEnableConfirmOpen(false);
              }}
              disabled={turnOffEmailLoading}
            />
            <ATMButton
              type="button"
              positive
              content={Lang.LBL_ENABLE_EMAIL}
              onClick={() => {
                enableEmailNotice(logId);
              }}
              disabled={enableEmailLoading}
              loading={enableEmailLoading}
            />
          </ATMModal.Actions>
        </ATMModal>
      )}
      {setIsForcedOutageConfirmOpen &&
        handleConfirmEmailClick &&
        isForcedOutageConfirmOpen && (
          <ATMModal
            open={isForcedOutageConfirmOpen}
            size="tiny"
            onClose={() => setIsForcedOutageConfirmOpen(false)}
            onOpen={() => setIsForcedOutageConfirmOpen(true)}
            closeOnDimmerClick={false}
            closeOnEscape={false}
          >
            <ATMModal.Header>
              {frcdOutgEmailSent
                ? Lang.LBL_SEND_FORCED_OUTAGE_CONFIRM_RESEND_TITLE
                : Lang.LBL_SEND_FORCED_OUTAGE_CONFIRM_TITLE}
            </ATMModal.Header>
            <ATMModal.Content>
              <ATMForm
                ref={formRefEmail}
                onSubmit={handleConfirmEmailSubmit}
                mode="onChange"
                defaultValues={mailFormDetails}
                validationSchema={ForcedOutageConfirmationFormSchema}
              >
                {(props) => {
                  return (
                    <ATMFormProvider {...props}>
                      <FormContent frcdOutgEmailSent={frcdOutgEmailSent} />
                    </ATMFormProvider>
                  );
                }}
              </ATMForm>
            </ATMModal.Content>
            <ATMModal.Actions>
              <ATMButton
                type="button"
                secondary
                content={
                  frcdOutgEmailSent || isOnEdit
                    ? Lang.LBL_CANCEL
                    : Lang.LBL_SEND_LATER
                }
                onClick={() => {
                  if (isOnEdit) {
                    setIsForcedOutageConfirmOpen(false);
                    // if (setUpdatedInProgressLog && mailFormDetails) {
                    //   setUpdatedInProgressLog(null);
                    // }
                  } else if (handleSendLater) {
                    handleSendLater(logId);
                  }
                }}
                disabled={sendNowLoading || sendLaterLoading}
                loading={sendLaterLoading}
              />
              <ATMButton
                type="button"
                primary
                content={
                  isOnEdit && !frcdOutgEmailSent
                    ? Lang.LBL_SEND_EMAIL
                    : frcdOutgEmailSent
                    ? Lang.LBL_RESEND_EMAIL
                    : Lang.LBL_SEND_NOW
                }
                onClick={() => {
                  handleConfirmEmailClick();
                }}
                disabled={sendNowLoading || sendLaterLoading}
                loading={sendNowLoading}
              />
            </ATMModal.Actions>
          </ATMModal>
        )}
    </>
  );
};

export default ForcedOutageConfirmationForm;
