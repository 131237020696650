/* eslint-disable no-nested-ternary */
import React, { useCallback } from 'react';
import { useFieldArray } from 'react-hook-form';
import {
  ATMForm,
  ATMGrid,
  ATMField,
  ATMInput,
  useATMFormContext,
} from 'shared-it-appmod-ui';
import FileUploader from 'src/components/atoms/file-uploader/file-uploader.component';
import {
  ISubstationCountListForm,
  ISubstationCountListFormArray,
} from 'src/models/substation-count-list.model';
import Lang from 'src/libraries/language';
import { ISubstation } from 'src/models/substation.model';
import { useFileContext } from 'src/contexts/file.context';

type IProps = {
  fileErrors: string[];
  substations?: ISubstation[];
  handleErrors: React.Dispatch<React.SetStateAction<string[]>>;
  handleEnabled: React.Dispatch<React.SetStateAction<boolean>>;
};

const SubstationCountUploadForm: React.FC<IProps> = ({
  fileErrors,
  substations,
  handleErrors,
  handleEnabled,
}) => {
  const { control } = useATMFormContext<ISubstationCountListFormArray>();
  const { fields, append } = useFieldArray({
    name: 'substationCounts',
    control,
  });
  let dataErrors: string[] = [];
  const { state: fileState } = useFileContext();
  const checkNumber = (
    value: string | number,
    errorMsg: string,
    lineNum: string,
    isSubId?: boolean
  ) => {
    let condition;
    let message;
    if (isSubId !== undefined && isSubId === true) {
      condition = !Number.isNaN(Number(value));
      message = Lang.formatString(errorMsg, lineNum, value);
    } else {
      condition = Number.isNaN(Number(value));
      message = Lang.formatString(errorMsg, lineNum);
    }

    if (condition === true) {
      dataErrors.push(String(message));
    }
  };

  const checkBlank = (value: Record<any, any>, lineNum: string) => {
    const fieldError: string[] = [];
    // each row can have multiple blanks, thus, the multiple ifs
    if (value['0'] === undefined || value['0'] === '' || value['0'] === null) {
      fieldError.push(Lang.MSG_NO_SUB_ID);
    }
    if (value['1'] === undefined || value['1'] === '' || value['1'] === null) {
      fieldError.push(Lang.MSG_NO_SUB_COUNT);
    }
    if (value['2'] === undefined || value['2'] === '' || value['2'] === null) {
      fieldError.push(Lang.MSG_NO_SUB_LOAD);
    }
    if (value['3'] === undefined || value['3'] === '' || value['3'] === null) {
      fieldError.push(Lang.MSG_NO_YEAR);
    }
    if (fieldError.length > 0) {
      dataErrors.push(
        String(
          Lang.formatString(
            Lang.MSG_FILE_HAS_BLANKS,
            lineNum,
            fieldError.join(', ')
          )
        )
      );
    }
  };

  const checkPositive = (value: number, errorMsg: string, lineNum: string) => {
    if (value < 0) {
      dataErrors.push(String(Lang.formatString(errorMsg, lineNum)));
    }
  };

  const checkDecimal = (value: number, errorMsg: string, lineNum: string) => {
    if (value % 1 !== 0) {
      dataErrors.push(String(Lang.formatString(errorMsg, lineNum)));
    }
  };

  const validateData = (data): ISubstationCountListForm[] => {
    const columnCount = data.find((item) => Object.keys(item).length > 4);

    if (columnCount !== undefined) {
      dataErrors.push(Lang.MSG_COLUMN_COUNT);
      return [];
    }

    const validated = data.map((item, index) => {
      const lineNumber = String(Number(index) + 1);

      checkBlank(item, lineNumber);
      checkNumber(item['0'], Lang.MSG_SUB_ID_INVALID, lineNumber, true);
      checkNumber(item['1'], Lang.MSG_SUB_COUNT_NAN, lineNumber);
      checkNumber(item['2'], Lang.MSG_SUB_LOAD_NAN, lineNumber);
      checkNumber(item['3'], Lang.MSG_YEAR_NAN, lineNumber);
      checkPositive(item['1'], Lang.MSG_NEGATIVE_COUNT, lineNumber);
      checkPositive(item['2'], Lang.MSG_NEGATIVE_LOAD, lineNumber);
      checkDecimal(item['1'], Lang.MSG_DECIMAL_COUNT, lineNumber);
      checkDecimal(item['2'], Lang.MSG_DECIMAL_LOAD, lineNumber);

      if (Number(item['3']) < 2000 || Number(item['3']) > 2100) {
        dataErrors.push(
          String(Lang.formatString(Lang.MSG_YEAR_INVALID, lineNumber))
        );
      }
      if (Number(item['1']) > 99999) {
        dataErrors.push(
          String(Lang.formatString(Lang.MSG_COUNT_INVALID, lineNumber))
        );
      }
      if (Number(item['2']) > 9999) {
        dataErrors.push(
          String(Lang.formatString(Lang.MSG_LOAD_INVALID, lineNumber))
        );
      }
      if (
        substations?.find(({ substationId }) => substationId === item['0']) ===
          null ||
        substations?.find(({ substationId }) => substationId === item['0']) ===
          undefined
      ) {
        dataErrors.push(
          String(Lang.formatString(Lang.MSG_SUB_INVALID, lineNumber, item['0']))
        );
      }

      return {
        custCnt: Number(item['1']),
        substationId: item['0'],
        substationLoad: Number(item['2']),
        year: item['3'],
      } as ISubstationCountListForm;
    });

    if (dataErrors.length === 0) {
      return validated;
    }
    return [];
  };

  const handleFileData = useCallback(
    (value) => {
      dataErrors = []; // resets the error messages
      if (value.length === 0) {
        dataErrors.push(Lang.MSG_FILE_EMPTY);
      }
      const uploadedData = validateData(value);
      if (dataErrors.length > 0) {
        handleEnabled(false);
        handleErrors([...fileErrors, ...dataErrors]);
      } else {
        handleEnabled(true);
        append(uploadedData);
      }
    },
    [dataErrors, append, validateData, handleErrors]
  );

  return (
    <ATMGrid>
      <ATMGrid.Row columns="equal">
        <ATMGrid.Column width="8">
          <ATMForm.Field>
            <label>{Lang.LBL_FILE}</label>
            {fields.map((e, i) => (
              <div key={e.id}>
                <ATMField
                  key={`substationId_${i}`}
                  as={ATMInput}
                  type="hidden"
                  className="hidden"
                  control={control}
                  defaultValue={e.substationId}
                  name={`substationCounts[${i}].substationId`}
                />
                <ATMField
                  key={`year_${i}`}
                  as={ATMInput}
                  type="hidden"
                  className="hidden"
                  control={control}
                  defaultValue={e.year}
                  name={`substationCounts[${i}].year`}
                />
                <ATMField
                  key={`custCnt_${i}`}
                  as={ATMInput}
                  type="hidden"
                  className="hidden"
                  control={control}
                  defaultValue={e.custCnt}
                  name={`substationCounts[${i}].custCnt`}
                />
                <ATMField
                  key={`substationLoad_${i}`}
                  as={ATMInput}
                  type="hidden"
                  className="hidden"
                  control={control}
                  defaultValue={e.substationLoad}
                  name={`substationCounts[${i}].substationLoad`}
                />
              </div>
            ))}
            <FileUploader
              returnCSVData
              onReturn={handleFileData}
              onError={handleErrors}
              onDelete={(_) => {
                handleErrors([]);
                handleEnabled(false);
              }}
              hasSharePointError={fileState?.hasSharepointError}
              popOverPostition="right center"
              popOverStyle={{ color: 'red', width: 250 }}
            />
          </ATMForm.Field>
        </ATMGrid.Column>
      </ATMGrid.Row>
    </ATMGrid>
  );
};

export default SubstationCountUploadForm;
