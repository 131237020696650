/* eslint-disable eqeqeq */
/* eslint-disable no-nested-ternary */
import React from 'react';
// import { BubbleMenu, Editor, EditorContent, Extension } from '@tiptap/react';
import StarterKit, { StarterKitOptions } from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import TextStyle from '@tiptap/extension-text-style';
import Color from '@tiptap/extension-color';
import TextAlign from '@tiptap/extension-text-align';
import Text from '@tiptap/extension-text';
import Focus from '@tiptap/extension-focus';
import Paragraph from '@tiptap/extension-paragraph';
import HorizontalRule from '@tiptap/extension-horizontal-rule';
import { NoteEditor } from './note.editor';
import MenuBar from './menu-bar.component';
import { CustomShortcutsExtension } from './custom-extension/custom-extension';
import styles from './editor.module.scss';
import { Editor, Extension } from '@tiptap/core';

type IProps = {
  data?: any;
  editor: Editor;
  extensions: Extension<StarterKitOptions, any>[];
  withDate?: boolean;
  content: string;
  date?: Date;
  isVersionTwo?: boolean;
  isDefault?: boolean;
  children?: React.ReactNode;
  setDate?: React.Dispatch<React.SetStateAction<Date>>;
  setEditorContent?: React.Dispatch<React.SetStateAction<any>>;
  editorContent?: string;
};

export enum CustomEditorSelection {
  CUSTOM_FOR_BPOR = 'BPOR Report',
}

export const defaultExtensions = [
  StarterKit.configure({
    bulletList: {
      keepMarks: true,
      keepAttributes: false,
    },
    orderedList: {
      keepMarks: true,
      keepAttributes: false,
    },
  }),
  TextAlign.configure({
    types: ['heading', 'paragraph'],
  }),
  Paragraph,
  Text,
  TextStyle,
  Color,
  Underline,
  HorizontalRule,
  Focus.configure({
    className: styles.hasFocus,
    mode: 'all',
  }),
  CustomShortcutsExtension,
] as Extension<StarterKitOptions, any>[];

export const customEditor = (editor, customType: CustomEditorSelection) => {
  switch (customType) {
    case CustomEditorSelection.CUSTOM_FOR_BPOR: {
      return (
        <>
          <div>
            <MenuBar editor={editor} isDefault={false} />
            {/* <EditorContent editor={editor} className={styles.editor} />
            <BubbleMenu
              tippyOptions={{ duration: 150 }}
              editor={editor}
              shouldShow={({ from, to }) => {
                return from !== to;
              }}
            >
              <MenuBar editor={editor} isPopup isDefault={false} />
            </BubbleMenu> */}
          </div>
        </>
      );
    }
    default:
      break;
  }
  return '';
};

const TextEditor: React.FC<IProps> = ({
  content,
  editor,
  extensions,
  children,
  isDefault,
  editorContent,
}) => {
  if (!editor) {
    return null;
  }
  const noteEditor = new NoteEditor({
    editor,
    extensions,
    content,
    convertedContent: editorContent,
    textEditor: null,
  });

  const textEditor = noteEditor?.getEditor().editor as unknown as Editor;
  return (
    <>
      {!isDefault ? (
        children
      ) : (
        <>
          <MenuBar editor={textEditor} />
          <div>
            {/* <EditorContent editor={textEditor} className={styles.editor} />
            <BubbleMenu
              tippyOptions={{ duration: 150 }}
              editor={textEditor}
              shouldShow={({ from, to }) => {
                return from !== to;
              }}
            >
              <MenuBar editor={textEditor} isPopup />
            </BubbleMenu> */}
          </div>
        </>
      )}
    </>
  );
};

export default TextEditor;
