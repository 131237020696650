import React, { useEffect, useState, useCallback } from 'react';
import { ATMHeader, ATMIcon, ATMTable } from 'shared-it-appmod-ui';
import FileDelete from 'src/components/atoms/file-delete/FileDelete';
import { SharePointApplicationType } from 'src/constants/sharepoint-common.constant';
import { useFileContext } from 'src/contexts/file.context';
import { useSettingContext } from 'src/contexts/setting.context';
import {
  SharePointSubmitRequestType,
  useSharepoint,
} from 'src/hooks/file.hook';
import Lang from 'src/libraries/language';
import { IFileSharePoint } from 'src/models/file.model';
import { ISharepointRequestData } from 'src/models/sharepoint-service.model';
import { ToastError } from 'src/components/atoms/toaster/toaster.component';
import styles from '../ler-request-detail/ler-request-detail.module.scss';

type IProps = {
  data?: IFileSharePoint[];
  isEdit?: boolean;
  id?: number;
  isSubmitted?: boolean;
  viewType?: string;
  noHeader?: boolean;
  setIsUpdateDone?: React.Dispatch<React.SetStateAction<boolean>>;
  isUpdateDone?: boolean;
  callback?: () => void;
  setLerDocuments?: React.Dispatch<
    React.SetStateAction<IFileSharePoint[] | undefined>
  >;
  setIsSharepointError?: React.Dispatch<React.SetStateAction<boolean>>;
};

const LerRequestDocumentsDetail: React.FC<IProps> = ({
  isEdit = false,
  id,
  isSubmitted = false,
  viewType,
  noHeader = false,
  isUpdateDone = false,
  setIsUpdateDone,
  setLerDocuments,
  setIsSharepointError,
}) => {
  const { state: settingState } = useSettingContext();
  const { state: fileState } = useFileContext();
  const { fetchMccList } = useSharepoint();
  const [mccListFile, setMccListFile] = useState<IFileSharePoint[]>([]);

  const getMccList = useCallback(async () => {
    let request: SharePointSubmitRequestType = {} as any;
    const requestData: ISharepointRequestData = {
      accessToken: '',
      createdBy: '',
      documentId: '',
      fileId: '',
      fileName: '',
      folderName: JSON.stringify(id) || '',
      parentFolder: id as any,
      requestId: id as any,
      requestor: '',
      documentLibrary: '',
    };
    request = {
      applicationType: SharePointApplicationType.LER_TYPE,
      requestData,
      setMccListFile,
      withParentFolder: true,
      setting: settingState,
      files: fileState.stash,
    };
    const files = await fetchMccList(request);

    if (files === null) {
      ToastError('Unable to connect to Sharepoint at the moment.');
    }

    if (setLerDocuments) {
      setLerDocuments(files?.fileList ?? []);
    }

    if (setIsSharepointError && files.withError) {
      setIsSharepointError(files.withError);
    }
  }, [
    settingState,
    fetchMccList,
    setMccListFile,
    setLerDocuments,
    setIsSharepointError,
  ]);

  useEffect(() => {
    if (viewType === 'view' || viewType === 'edit') {
      if (!isSubmitted && !isUpdateDone) {
        getMccList();
      }
    }
  }, [id]);

  useEffect(() => {
    if (isSubmitted && viewType === 'update') {
      if (isSubmitted) {
        getMccList();
        if (setIsUpdateDone) {
          setIsUpdateDone(false);
        }
      }
    }
  }, [isSubmitted, isUpdateDone]);

  const docsTable = (
    <ATMTable>
      <ATMTable.Header>
        <ATMTable.Row>
          <ATMTable.HeaderCell>{Lang.LBL_FILE_NAME}</ATMTable.HeaderCell>
          <ATMTable.HeaderCell>{Lang.LBL_UPLOADED_BY}</ATMTable.HeaderCell>
          {isEdit && (
            <ATMTable.HeaderCell>{Lang.LBL_ACTION}</ATMTable.HeaderCell>
          )}
        </ATMTable.Row>
      </ATMTable.Header>
      <ATMTable.Body>
        {mccListFile.length ? (
          mccListFile?.map((item, i) => (
            <ATMTable.Row key={i}>
              <ATMTable.Cell>
                <>
                  <a
                    key={item.fileUrl as any}
                    href={item.fileUrl as any}
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    {item.fileName}
                  </a>
                </>
              </ATMTable.Cell>
              <ATMTable.Cell>{item.createdBy}</ATMTable.Cell>
              {isEdit && (
                <ATMTable.Cell>
                  <FileDelete
                    file={item}
                    displayConfirmation
                    handleSuccess={() => getMccList()}
                  />
                </ATMTable.Cell>
              )}
            </ATMTable.Row>
          ))
        ) : (
          <>
            {isEdit ? (
              <ATMTable.Row textAlign="center">
                <ATMTable.Cell />
                <ATMTable.Cell>
                  {Lang.MSG_NOTIFICATION_BLOCK_EMPTY}
                </ATMTable.Cell>
                <ATMTable.Cell />
              </ATMTable.Row>
            ) : (
              <ATMTable.Row>
                <ATMTable.Cell />
                <ATMTable.Cell>
                  {Lang.MSG_NOTIFICATION_BLOCK_EMPTY}
                </ATMTable.Cell>
              </ATMTable.Row>
            )}
          </>
        )}
      </ATMTable.Body>
    </ATMTable>
  );
  return (
    <>
      <div style={noHeader ? { display: 'none' } : {}}>
        <span className={styles.iconRight}>
          <ATMIcon circular name="file outline" />
        </span>
        <ATMHeader
          as="h2"
          content={Lang.TTL_DOCUMENTS}
          className={styles.headerDoc}
        />
      </div>
      {docsTable}
    </>
  );
};

export default LerRequestDocumentsDetail;
