/* eslint-disable eqeqeq */
/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react';
import RealTimeLogBPORReportView from './real-time-log-bpor-report-view.component';

type IProps = {
  reportDate: Date;
  loading: boolean;
  editorContent: string;
  setEditorContent: React.Dispatch<React.SetStateAction<string>>;
  handleBporReport: (reportDate: Date) => void;
};

const RealTimeLogBPORReport: React.FC<IProps> = ({
  reportDate,
  loading,
  editorContent,
  setEditorContent,
  handleBporReport,
}) => {
  useEffect(() => {
    if (reportDate) {
      handleBporReport(reportDate);
    }
  }, []);
  return (
    <>
      <RealTimeLogBPORReportView
        reportDate={reportDate}
        loading={loading}
        editorContent={editorContent}
        setEditorContent={setEditorContent}
      />
    </>
  );
};

export default RealTimeLogBPORReport;
