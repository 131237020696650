import React, { createContext, useContext } from 'react';
import useReducerHook from 'src/hooks/reducer.hook';
import RealTimeLogAuditReducer, {
  defaultState,
  duckActions,
  IRealTimeLogAuditState,
} from 'src/ducks/real-time-log-audit.duck';

const useReducer = (state = {}) => {
  return useReducerHook(
    RealTimeLogAuditReducer,
    {
      ...defaultState,
      ...state,
    },
    duckActions
  );
};

type IRealTimeLogAuditContext = ReturnType<typeof useReducer>;

const RealTimeLogAuditContext = createContext<
  Partial<IRealTimeLogAuditContext>
>({
  state: defaultState,
}) as React.Context<IRealTimeLogAuditContext>;

type IProps = {
  state?: Partial<IRealTimeLogAuditState>;
};

const RealTimeLogAuditProvider: React.FC<IProps> = ({ children, state }) => {
  const reducer = useReducer(state);

  return (
    <RealTimeLogAuditContext.Provider
      value={{
        ...reducer,
      }}
    >
      {children}
    </RealTimeLogAuditContext.Provider>
  );
};

const useRealTimeLogAuditContext = () => useContext(RealTimeLogAuditContext);

export type IUseRealTimeLogAuditContext = ReturnType<
  typeof useRealTimeLogAuditContext
>;

export {
  RealTimeLogAuditContext,
  RealTimeLogAuditProvider,
  useRealTimeLogAuditContext,
};
