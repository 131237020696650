import React, { useCallback, useState } from 'react';
import { ATMButton, ATMField, ATMModal } from 'shared-it-appmod-ui';
import DocumentGenerator from 'src/helpers/file-bpor-report.helper';
import Lang from 'src/libraries/language';
import { useRealTimeLogContext } from 'src/contexts/real-time-log.context';
import { getRealTimeLogStatus } from 'src/selectors/real-time-log.selector';
import { realTimeLogActionTypes } from 'src/ducks/real-time-log.duck';
import {
  ToastError,
  ToastSuccess,
} from 'src/components/atoms/toaster/toaster.component';
import styles from './real-time-log-bpor-report.module.scss';

type IProps = {
  editorContent: string;
  reportDate: Date;
};

const RealTimeLogSendBPORReport: React.FC<IProps> = ({
  editorContent,
  reportDate,
}) => {
  const { state, actions } = useRealTimeLogContext();
  const [isOpen, setIsOpen] = useState(false);
  const status = getRealTimeLogStatus(
    state,
    realTimeLogActionTypes.BPOR_REPORT_READ
  );
  const mailerStatus = getRealTimeLogStatus(
    state,
    realTimeLogActionTypes.REAL_TIME_LOG_MAILER
  );
  const handleBPORReport = useCallback(async () => {
    setIsOpen(false);
    const iconUrl = '/icons/sdge-logo.png';
    const fetchLogo = (url: string): Promise<string> => {
      return fetch(url)
        .then((response) => {
          if (!response.ok) {
            throw new Error('Failed to connect');
          }
          return response.blob();
        })
        .then((blob) => {
          return new Promise<string>((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => {
              const base64data = reader.result as string;
              resolve(base64data);
            };
            reader.onerror = () => {
              reject(new Error('Failed to convert blob to base64'));
            };
            reader.readAsDataURL(blob);
          });
        });
    };
    const logo = await fetchLogo(iconUrl);

    const extractBase64 = (data: string): string => {
      if (!data?.length) {
        return '';
      }
      const parts = data?.split(',');
      return parts?.length > 1
        ? parts[1].replace(/=/g, '')
        : data?.replace(/=/g, '');
    };
    const isNothingToReport = () => {
      const forcedInterruptions = state?.report?.rows.forcedInterruptions;
      const systemChanges = state?.report?.rows?.completedSystemChanges;
      const noReport = !!(
        !forcedInterruptions?.length && !systemChanges?.length
      );
      if (noReport) {
        const regex = new RegExp('Nothing to Report', 'gi');
        const match = editorContent.match(regex);
        if (match && match.length < 3) {
          return false;
        }
      }
      return noReport;
    };
    const sdgeLogo = extractBase64(logo) ?? null;
    const document = new DocumentGenerator({
      content: editorContent,
      fontSize: null,
      logo: sdgeLogo,
    });
    const generatedDocument = await document.generateDocument();
    const result = await actions.handleBPORReportEmail({
      bporReport: [generatedDocument],
      reportDate: reportDate as any,
      isNothingToReport: isNothingToReport(),
    });

    if (result?.payload) {
      if (result.payload?.status === 1) {
        ToastSuccess(result.payload?.message ?? 'Email successfully sent.');
      } else {
        ToastError(result.payload?.message ?? 'Failed to send email');
      }
      if (result.payload.uploadResponse?.status === 200) {
        ToastSuccess(
          result.payload?.uploadResponse?.message ??
            'File uploaded successfully'
        );
      }
      if (result.payload?.uploadResponse?.status === 400) {
        ToastError(
          result.payload?.uploadResponse?.message ?? 'Failed to upload file'
        );
      }
    }
    if (result?.error) {
      ToastError(result.error?.message ?? 'Failed to send email');
    }
  }, [actions, state, editorContent, setIsOpen]);
  return (
    <>
      <ATMModal
        open={isOpen}
        size="tiny"
        onClose={() => setIsOpen(false)}
        onOpen={() => setIsOpen(true)}
        closeOnDimmerClick={false}
        trigger={
          <ATMField
            as={ATMButton}
            primary
            icon="send"
            content="Send"
            type="button"
            disabled={
              status.fetching ||
              mailerStatus.fetching ||
              !reportDate ||
              editorContent.length <= 7
            }
            className={styles.inline}
            name="sendBporReport"
            loading={status.fetching || mailerStatus.fetching}
          />
        }
      >
        <ATMModal.Header>Send BPOR Report</ATMModal.Header>
        <ATMModal.Content>
          <p>{Lang.MSG_SEND_BPOR_REPORT}</p>
        </ATMModal.Content>
        <ATMModal.Actions>
          <ATMButton
            secondary
            content={Lang.LBL_CANCEL}
            onClick={() => setIsOpen(false)}
            type="button"
          />
          <ATMField
            as={ATMButton}
            type="button"
            content="Send Report"
            primary
            icon="send"
            className={styles.inline}
            name="sendReport"
            onClick={handleBPORReport}
          />
        </ATMModal.Actions>
      </ATMModal>
    </>
  );
};

export default RealTimeLogSendBPORReport;
