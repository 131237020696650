import { TestContext } from 'yup';

export const isValidAlphanumeric = (input: any) => {
  const usernamePattern = /^[a-zA-Z0-9]*$/;
  return usernamePattern.test(input);
};

export const isValidAlphanumericWithSpecialChar = (input: any) => {
  const usernamePattern = /^[a-zA-Z0-9&\-\s]*$/;
  return usernamePattern.test(input);
};

export const isValidNumber = (input: any) => {
  const usernamePattern = /^\d+$/;
  return usernamePattern.test(input);
};

export const isValidAlphanumericWithSpecialCharField = (
  value,
  _context: TestContext
) => {
  if (!isValidAlphanumericWithSpecialChar(value)) {
    return false;
  }
  return true;
};

export const isValidNumberField = (value, _context: TestContext) => {
  if (value.length) {
    if (!isValidNumber(value)) {
      return false;
    }
  }
  return true;
};

export const isValidAlphaNumericField = (value, _context: TestContext) => {
  if (!isValidAlphanumeric(value)) {
    return false;
  }
  return true;
};
