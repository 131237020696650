/* eslint-disable no-nested-ternary */
/* eslint-disable no-unneeded-ternary */
import React, { useState, useEffect } from 'react';
import ClassNames from 'classnames';
import {
  ATMForm,
  ATMGrid,
  ATMDropdown,
  ATMHeader,
  ATMField,
  ATMSegment,
  ATMIcon,
  ATMFormProvider,
  useATMFormContext,
  ATMPopover,
  ATMInput,
  ATMCheckbox,
  formatPhoneNumber,
} from 'shared-it-appmod-ui';
import FieldReadOnly from 'src/components/atoms/field/field-readonly/field-readonly.component';
import { useRolesContext } from 'src/contexts/roles.context';
import {
  IRole,
  IRolesForm,
  IRolesPreForm,
  RolesPreFormSchema,
} from 'src/models/roles.model';
import { IEmployee } from 'src/models/employee.model';
import Lang from 'src/libraries/language';
import DropdownEmployee from 'src/components/atoms/dropdown-employee/dropdown-employee.component';
import { AccessRole } from 'src/constants';
import RolesDelete from '../roles-delete/roles-delete.component';
import styles from './roles-form.module.scss';
// import { Controller } from 'react-hook-form';

type IProp = {
  formRef?: React.RefObject<HTMLFormElement>;
  defaultValues?: Partial<IRolesPreForm>;
  handleSubmit?: (employee: IRolesPreForm) => void;
  handleSubmitEdit?: (data: IRolesPreForm) => void;
  employee?: IEmployee;
  handleSearch: (id) => void;
  grantAccess?: boolean;
  editRole?: boolean;
  editExistingEmp?: boolean;
  empName?: string | null;
  setOnClose?: () => void;
};

const FormContent: React.FC<{
  defaultValues?: Partial<IRolesPreForm>;
  employee?: IEmployee;
  handleSearch: (id) => void;
  roleListDropdown?: IRole[];
  grantAccess?: boolean;
  empName?: string | null;
  setOnClose?: () => void;
}> = ({ defaultValues, employee, roleListDropdown, empName, setOnClose }) => {
  const { control, getValues, setValue } = useATMFormContext<IRolesForm>();
  if (getValues('empId') === undefined) {
    setValue('empId', employee?.emp_id as string);
  }
  const [active, setActive] = useState(defaultValues?.roleStatus ?? true);
  const [realTimeLogRole, setRealTimeLogRole] = useState(
    defaultValues?.rolesId?.realTimeLog
  );
  return (
    <ATMGrid columns={2}>
      <>
        <ATMField
          as={ATMInput}
          control={control}
          name="empId"
          type="hidden"
          className="hidden"
          defaultValue={defaultValues?.empId}
        />
        <ATMGrid.Row columns={1} stretched>
          <ATMGrid.Column>
            {employee ? (
              <ATMSegment color="blue" className={ClassNames(styles.container)}>
                <ATMHeader size="small">
                  <ATMIcon name="user circle" color="blue" />
                  <ATMHeader.Content>{`${employee.first_name} ${employee.last_name}`}</ATMHeader.Content>
                </ATMHeader>
                <ATMGrid stackable columns={2}>
                  <ATMGrid.Row>
                    <ATMGrid.Column>
                      <FieldReadOnly label={Lang.LBL_EMPLOYEE_NUMBER}>
                        {employee.emp_id}
                      </FieldReadOnly>
                    </ATMGrid.Column>
                    <ATMGrid.Column>
                      <FieldReadOnly label={Lang.LBL_EMAIL}>
                        {employee.e_mail_address}
                      </FieldReadOnly>
                    </ATMGrid.Column>
                    <ATMGrid.Column>
                      <FieldReadOnly label={Lang.LBL_EMPLOYEE_DEPARTMENT}>
                        {employee.dept_org_desc}
                      </FieldReadOnly>
                    </ATMGrid.Column>
                    <ATMGrid.Column>
                      <FieldReadOnly label={Lang.LBL_TELEPHONE}>
                        {formatPhoneNumber(employee?.work_phone_1 as string)}
                      </FieldReadOnly>
                    </ATMGrid.Column>
                    <ATMGrid.Column>
                      <FieldReadOnly label={Lang.LBL_MANAGER}>
                        {employee.mgr_last_name}, {employee.mgr_first_name}
                      </FieldReadOnly>
                    </ATMGrid.Column>
                  </ATMGrid.Row>
                </ATMGrid>
              </ATMSegment>
            ) : (
              <span />
            )}
          </ATMGrid.Column>
        </ATMGrid.Row>
      </>
      {employee || defaultValues ? (
        <>
          <>
            <ATMGrid.Row>
              <ATMGrid.Column>
                <ATMHeader as="h2" className={styles.marginBottomSmall}>
                  {Lang.LBL_ROLES_ASSIGMENT}
                </ATMHeader>
              </ATMGrid.Column>
              <ATMGrid.Column textAlign="right">
                <RolesDelete
                  rightPanel
                  id={defaultValues && defaultValues.empId}
                  empName={
                    empName ? empName : defaultValues && defaultValues?.empId
                  }
                  setOnClose={setOnClose}
                />
              </ATMGrid.Column>
            </ATMGrid.Row>
            <ATMGrid.Row>
              <ATMGrid.Column
                verticalAlign="middle"
                textAlign="right"
                width={6}
              >
                {Lang.LBL_ROLE_STATUS}{' '}
                <ATMPopover
                  trigger={<ATMIcon name="info circle" color="grey" />}
                  content={Lang.MSG_ROLE_GRANT_ACCESS}
                  position="top center"
                  inverted
                  size="small"
                />
              </ATMGrid.Column>
              <ATMGrid.Column verticalAlign="middle">
                <div className={styles.notificationToggle}>
                  <div className={styles.radioButton}>
                    <ATMField
                      name="roleStatus"
                      as={ATMCheckbox}
                      defaultChecked={active}
                      className={styles.radioButton}
                      toggle
                      width={6}
                      control={control}
                      onChange={([_, { checked }]) => {
                        setActive(checked);
                        return checked;
                      }}
                    />
                    <div className={styles.labelCheck}>
                      {active ? 'Active' : 'Inactive'}
                    </div>
                  </div>
                </div>
              </ATMGrid.Column>
            </ATMGrid.Row>
            <ATMGrid.Row style={{ display: 'none' }}>
              <ATMField
                style={{ display: 'none' }}
                as={ATMInput}
                control={control}
                name="empId"
                selectOnBlur={false}
                onChange={([_, { value }]) => {
                  return value;
                }}
              />
            </ATMGrid.Row>
            <ATMGrid.Row>
              <ATMGrid.Column
                verticalAlign="middle"
                textAlign="right"
                width={6}
              >
                {Lang.LBL_KEYS}
              </ATMGrid.Column>
              <ATMGrid.Column>
                <ATMField
                  as={ATMDropdown}
                  control={control}
                  name="rolesId.keys"
                  // error={errors.keys}
                  selection
                  clearable
                  placeholder="Select"
                  disabled={!active}
                  selectOnBlur={false}
                  options={roleListDropdown
                    ?.filter((value) => value.contentAccess === 'Keys')
                    .map((value) => ({
                      key: value.roleId,
                      value: Number(value.roleId),
                      text: value.roleName,
                    }))}
                  onChange={([_, { value }]) => {
                    return value === '' ? 0 : value;
                  }}
                />
              </ATMGrid.Column>
            </ATMGrid.Row>
            <ATMGrid.Row>
              <ATMGrid.Column
                verticalAlign="middle"
                textAlign="right"
                width={6}
              >
                {Lang.LBL_AUTH_LIST}
              </ATMGrid.Column>
              <ATMGrid.Column>
                <ATMField
                  as={ATMDropdown}
                  control={control}
                  name="rolesId.authList"
                  // error={errors.authList}
                  selection
                  clearable
                  placeholder="Select"
                  disabled={!active}
                  selectOnBlur={false}
                  options={roleListDropdown
                    ?.filter((value) => value.contentAccess === 'Auth List')
                    .map((value) => ({
                      key: value.roleId,
                      value: Number(value.roleId),
                      text: value.roleName,
                    }))}
                  onChange={([_, { value }]) => {
                    return value === '' ? 0 : value;
                  }}
                />
              </ATMGrid.Column>
            </ATMGrid.Row>
            <ATMGrid.Row>
              <ATMGrid.Column
                verticalAlign="middle"
                textAlign="right"
                width={6}
              >
                {Lang.LBL_SUBSTATION_ENTRY_LOG}
              </ATMGrid.Column>
              <ATMGrid.Column>
                <ATMField
                  as={ATMDropdown}
                  control={control}
                  name="rolesId.substationEntryLog"
                  selection
                  clearable
                  placeholder="Select"
                  disabled={!active}
                  selectOnBlur={false}
                  options={roleListDropdown
                    ?.filter(
                      (value) => value.contentAccess === 'Substation Entry Log'
                    )
                    .map((value) => ({
                      key: value.roleId,
                      value: Number(value.roleId),
                      text: value.roleName,
                    }))}
                  onChange={([_, { value }]) => {
                    return value === '' ? 0 : value;
                  }}
                />
              </ATMGrid.Column>
            </ATMGrid.Row>
            <ATMGrid.Row>
              <ATMGrid.Column
                verticalAlign="middle"
                textAlign="right"
                width={6}
              >
                {Lang.LBL_OUTAGE_PLANNING}
              </ATMGrid.Column>
              <ATMGrid.Column>
                <ATMField
                  as={ATMDropdown}
                  control={control}
                  name="rolesId.outagePlanning"
                  selection
                  clearable
                  placeholder="Select"
                  disabled={!active}
                  selectOnBlur={false}
                  options={roleListDropdown
                    ?.filter(
                      (value) => value.contentAccess === 'Outage Planning'
                    )
                    .map((value) => ({
                      key: value.roleId,
                      value: Number(value.roleId),
                      text: value.roleName,
                    }))}
                  onChange={([_, { value }]) => {
                    return value === '' ? 0 : value;
                  }}
                />
              </ATMGrid.Column>
            </ATMGrid.Row>
            <ATMGrid.Row>
              <ATMGrid.Column
                verticalAlign="middle"
                textAlign="right"
                width={6}
              >
                {Lang.LBL_TROUBLE_JOB}
              </ATMGrid.Column>
              <ATMGrid.Column>
                <ATMField
                  as={ATMDropdown}
                  control={control}
                  name="rolesId.troubleJob"
                  selection
                  clearable
                  placeholder="Select"
                  disabled={!active}
                  selectOnBlur={false}
                  options={roleListDropdown
                    ?.filter((value) => value.contentAccess === 'Trouble Job')
                    .map((value) => ({
                      key: value.roleId,
                      value: Number(value.roleId),
                      text: value.roleName,
                    }))}
                  onChange={([_, { value }]) => {
                    return value === '' ? 0 : value;
                  }}
                />
              </ATMGrid.Column>
            </ATMGrid.Row>
            <ATMGrid.Row>
              <ATMGrid.Column
                verticalAlign="middle"
                textAlign="right"
                width={6}
              >
                {Lang.LBL_REAL_TIME_LOG}
              </ATMGrid.Column>
              <ATMGrid.Column>
                <ATMField
                  as={ATMDropdown}
                  control={control}
                  name="rolesId.realTimeLog"
                  selection
                  clearable
                  placeholder="Select"
                  disabled={!active}
                  selectOnBlur={false}
                  options={roleListDropdown
                    ?.filter((value) => value.contentAccess === 'Real-time Log')
                    .map((value) => ({
                      key: value.roleId,
                      value: Number(value.roleId),
                      text: value.roleName,
                    }))}
                  onChange={([_, { value }]) => {
                    if (!value || value === '') {
                      setRealTimeLogRole(null);
                      const rolesId = {
                        ...getValues('rolesId'),
                        bporReport: 0,
                      };
                      setValue('rolesId', rolesId);
                      return 0;
                    }

                    if (value === AccessRole.REAL_TIME_LOG_VIEWER) {
                      const rolesId = {
                        ...getValues('rolesId'),
                        bporReport: 0,
                      };
                      setValue('rolesId', rolesId);
                    }

                    setRealTimeLogRole(value);
                    return value;
                  }}
                />
              </ATMGrid.Column>
            </ATMGrid.Row>
            <ATMGrid.Row>
              <ATMGrid.Column
                verticalAlign="middle"
                textAlign="right"
                width={6}
              >
                {Lang.LBL_BPOR_REPORT}
              </ATMGrid.Column>
              <ATMGrid.Column>
                <ATMField
                  as={ATMDropdown}
                  control={control}
                  name="rolesId.bporReport"
                  selection
                  clearable
                  placeholder="Select"
                  disabled={!active || !realTimeLogRole}
                  selectOnBlur={false}
                  options={
                    realTimeLogRole === AccessRole.REAL_TIME_LOG_UPDATER
                      ? roleListDropdown
                          ?.filter(
                            (value) => value.contentAccess === 'BPOR Report'
                          )
                          .map((value) => ({
                            key: value.roleId,
                            value: Number(value.roleId),
                            text: value.roleName,
                          }))
                      : realTimeLogRole === AccessRole.REAL_TIME_LOG_VIEWER
                      ? roleListDropdown
                          ?.filter(
                            (value) =>
                              Number(value.roleId) === AccessRole.BPOR_VIEWER
                          )
                          .map((value) => ({
                            key: value.roleId,
                            value: Number(value.roleId),
                            text: value.roleName,
                          }))
                      : []
                  }
                  onChange={([_, { value }]) => {
                    return value === '' ? 0 : value;
                  }}
                />
              </ATMGrid.Column>
            </ATMGrid.Row>
            <ATMGrid.Row>
              <ATMGrid.Column
                verticalAlign="middle"
                textAlign="right"
                width={6}
              >
                {Lang.LBL_TROUBLE_TICKET}
              </ATMGrid.Column>
              <ATMGrid.Column>
                <ATMField
                  as={ATMDropdown}
                  control={control}
                  name="rolesId.troubleTicket"
                  selection
                  clearable
                  placeholder="Select"
                  disabled={!active}
                  selectOnBlur={false}
                  options={roleListDropdown
                    ?.filter(
                      (value) => value.contentAccess === 'Trouble Ticket'
                    )
                    .map((value) => ({
                      key: value.roleId,
                      value: Number(value.roleId),
                      text: value.roleName,
                    }))}
                  onChange={([_, { value }]) => {
                    return value === '' ? 0 : value;
                  }}
                />
              </ATMGrid.Column>
            </ATMGrid.Row>
            <ATMGrid.Row>
              <ATMGrid.Column
                verticalAlign="middle"
                textAlign="right"
                width={6}
              >
                {Lang.LBL_ROLES_TITLES}
              </ATMGrid.Column>
              <ATMGrid.Column>
                <ATMField
                  as={ATMDropdown}
                  control={control}
                  name="rolesId.roles"
                  // error={errors.roles}
                  selection
                  clearable
                  placeholder="Select"
                  disabled={!active}
                  selectOnBlur={false}
                  options={roleListDropdown
                    ?.filter((value) => value.contentAccess === 'Roles')
                    .map((value) => ({
                      key: value.roleId,
                      value: Number(value.roleId),
                      text: value.roleName,
                    }))}
                  onChange={([_, { value }]) => {
                    return value === '' ? 0 : value;
                  }}
                />
              </ATMGrid.Column>
            </ATMGrid.Row>
          </>
          <>
            <ATMHeader as="h4">
              After granting access to &quot;{Lang.LBL_OUTAGE_PLANNING}
              &quot; module, also grant access to LER attachments by adding user
              to groups (MCC-LER-RW or MCC-LER-R).
            </ATMHeader>
            <ATMHeader as="h4">
              After granting access to &quot;{Lang.LBL_TROUBLE_TICKET}
              &quot; module, also grant access to Trouble Ticket attachments by
              adding user to groups (MCC-TT-RW or MCC-TT-R).
            </ATMHeader>
          </>
        </>
      ) : (
        <span />
      )}
    </ATMGrid>
  );
};

const RolesForm: React.FC<IProp> = ({
  formRef,
  defaultValues,
  handleSubmit,
  handleSearch,
  employee,
  grantAccess,
  handleSubmitEdit,
  editExistingEmp,
  empName,
  setOnClose,
}) => {
  const { state, actions } = useRolesContext();
  useEffect(() => {
    actions?.findAllOptionRolesGET();
  }, [actions]);
  return (
    <>
      {defaultValues && !grantAccess ? (
        <span />
      ) : (
        <ATMGrid>
          <ATMGrid.Column>
            <ATMField
              as={DropdownEmployee}
              width={6}
              label="Employee Name/ID"
              name="empId"
              style={{ display: 'block', marginRight: '1em', width: '50%' }}
              onChange={
                ((_, { value }) => {
                  handleSearch(value);
                  return value;
                }) as any
              }
            />
          </ATMGrid.Column>
        </ATMGrid>
      )}

      {(defaultValues || employee) && (
        <ATMForm
          key={employee?.emp_id || defaultValues?.empId}
          ref={formRef}
          onSubmit={!editExistingEmp ? handleSubmit : handleSubmitEdit}
          mode="onChange"
          defaultValues={RolesPreFormSchema.cast(defaultValues)}
          validationSchema={RolesPreFormSchema}
        >
          {(props) => {
            return (
              <ATMFormProvider {...props}>
                <FormContent
                  defaultValues={defaultValues}
                  employee={employee}
                  handleSearch={handleSearch}
                  roleListDropdown={state.roles}
                  grantAccess={grantAccess}
                  empName={empName}
                  setOnClose={setOnClose}
                />
              </ATMFormProvider>
            );
          }}
        </ATMForm>
      )}
    </>
  );
};

export default RolesForm;
