import { Extension, Mark, mergeAttributes } from '@tiptap/core';

const TAB_CHAR = '\u00A0\u00A0\u00A0\u00A0';

export const CustomSpan = Mark.create({
  name: 'customSpan',

  addAttributes() {
    return {
      class: {
        default: null,
        parseHTML: (element) => element.className,
        renderHTML: (attributes) => {
          if (!attributes.class) return {};
          return { class: attributes.class };
        },
      },
    };
  },
  parseHTML() {
    return [
      {
        tag: 'span.custom-span',
      },
    ];
  },
  renderHTML({ HTMLAttributes }) {
    return ['span', mergeAttributes(HTMLAttributes, { class: 'custom-span' }), 0];
  },

});

export const CustomShortcutsExtension = Extension.create({
  name: 'customShortcuts',
  addKeyboardShortcuts() {
    return {
      Tab: ({ editor }) => {
        editor
          .chain()
          .sinkListItem('listItem')
          .command(({ tr }) => {
            tr.insertText(TAB_CHAR);
            return true;
          })
          .run();
        return true;
      },
      Space: ({ editor }) => {
        editor
          .chain()
          .command(({ tr }) => {
            tr.insertText('\u00A0');
            return true;
          })
          .focus()
          .run();

        return true;
      },
      'Ctrl-Enter': () => {
        return true;
      },
    };
  },
});
