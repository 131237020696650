import React, { useCallback, useEffect, useRef, useState } from 'react';
import Lang from 'src/libraries/language';
import { getRealTimeLogStatus } from 'src/selectors/real-time-log.selector';
import { generateRealTimeLogEmailFormDetails } from 'src/helpers/real-time-log.helper';
import { realTimeLogActionTypes } from 'src/ducks/real-time-log.duck';
import { useRealTimeLogContext } from 'src/contexts/real-time-log.context';
import {
  ForcedOutageConfirmationFormSchema,
  IForcedOutageConfirmationForm,
  IRealTimeLog,
} from 'src/models/real-time-log.model';
import history from 'src/history';
import {
  ToastError,
  ToastSuccess,
} from 'src/components/atoms/toaster/toaster.component';
import {
  ATMButton,
  ATMDropdown,
  ATMField,
  ATMForm,
  ATMFormProvider,
  ATMGrid,
  ATMInput,
  ATMModal,
  ATMTextArea,
  useATMFormContext,
} from 'shared-it-appmod-ui';
import style from '../real-time-log-form/real-time-log-form.module.scss';

type IProps = {
  data: IRealTimeLog;
  frcdOutgEmailSent?: boolean | null;
  timer?: any;
  currentTab?: number;
};

type IFormContent = {
  sendNowLoading: boolean;
  frcdOutgEmailSent?: boolean | null;
};

const FormContent: React.FC<IFormContent> = ({
  sendNowLoading,
  frcdOutgEmailSent,
}) => {
  const {
    formState: { errors },
    control,
  } = useATMFormContext<IForcedOutageConfirmationForm>();
  return (
    <ATMGrid>
      <ATMGrid.Row style={{ paddingBottom: '0em' }}>
        <ATMGrid.Column>
          {frcdOutgEmailSent
            ? Lang.LBL_RESEND_FORCED_OUTAGE_CONFIRM
            : Lang.LBL_SEND_FORCED_OUTAGE_CONFIRM}
        </ATMGrid.Column>
      </ATMGrid.Row>
      <ATMGrid.Row>
        <ATMGrid.Column>
          <ATMField
            control={control}
            as={ATMInput}
            name="logId"
            type="hidden"
            className="hidden"
          />
          <ATMField
            control={control}
            as={ATMTextArea}
            name="mailHistoryContent"
            type="hidden"
            className="hidden"
          />
          <ATMField
            control={control}
            as={ATMInput}
            name="mailSubject"
            error={errors.mailSubject}
            autoComplete="off"
            maxLength={100}
            placeholder="Format: Real-Time Interruption: <subject details>"
            disabled={sendNowLoading}
            label={
              <span className={style.emphasizedSmall}>
                You may edit the details before sending
              </span>
            }
            onChange={([_, { value }]) => value}
          />
          <ATMField
            as={ATMTextArea}
            name="mailContent"
            maxLength={2500}
            control={control}
            error={errors.mailContent}
            rows={5}
            disabled={sendNowLoading}
          />
        </ATMGrid.Column>
      </ATMGrid.Row>
    </ATMGrid>
  );
};

const RealTimeLogListEmailSend: React.FC<IProps> = ({
  data,
  frcdOutgEmailSent,
  timer,
  currentTab,
}) => {
  const { state, actions } = useRealTimeLogContext();
  const [isConfirmOpen, setIsConfirmOpen] = useState<boolean | null>(null);

  const formRefEmail = useRef<HTMLFormElement>(null);

  const sendNowStatus = getRealTimeLogStatus(
    state,
    realTimeLogActionTypes.FORCED_OUTAGE_SEND_NOW
  );

  const handleConfirmEmailClick = useCallback(() => {
    if (formRefEmail && formRefEmail.current) {
      formRefEmail.current.handleSubmit();
    }
  }, [formRefEmail]);

  useEffect(() => {
    if (isConfirmOpen) {
      if (timer && timer.current) {
        clearTimeout(timer.current);
      }
    }

    if (isConfirmOpen === false && currentTab === 0) {
      history.push('/real-time-log?realTimeLogStatus=0');
    }
  }, [isConfirmOpen]);

  const handleSubmit = useCallback(
    async (formData: IForcedOutageConfirmationForm) => {
      const response = await actions.handleSendEmailNow(formData);

      if (!response.error) {
        setIsConfirmOpen(false);
        ToastSuccess(Lang.MSG_FORCED_OUTAGE_EMAIL_SENT);

        if (setIsConfirmOpen) {
          setIsConfirmOpen(false);
        }
      } else {
        ToastError(Lang.MSG_FORCED_OUTAGE_EMAIL_ERROR);
      }
    },
    [actions]
  );

  return (
    <ATMModal
      open={isConfirmOpen ?? false}
      size="small"
      trigger={
        <ATMDropdown.Item
          content={
            frcdOutgEmailSent
              ? Lang.LBL_EDITRESEND_EMAIL
              : Lang.LBL_EDITSEND_EMAIL
          }
          icon="paper plane"
          onClick={() => setIsConfirmOpen(!isConfirmOpen)}
        />
      }
      closeOnDimmerClick={false}
      closeOnEscape={false}
    >
      <ATMModal.Header>
        {frcdOutgEmailSent
          ? Lang.LBL_SEND_FORCED_OUTAGE_CONFIRM_RESEND_TITLE
          : Lang.LBL_SEND_FORCED_OUTAGE_CONFIRM_TITLE}
      </ATMModal.Header>
      <ATMModal.Content>
        <ATMForm
          ref={formRefEmail}
          onSubmit={handleSubmit}
          mode="onChange"
          defaultValues={generateRealTimeLogEmailFormDetails(data)}
          validationSchema={ForcedOutageConfirmationFormSchema}
        >
          {(props) => {
            return (
              <ATMFormProvider {...props}>
                <FormContent
                  frcdOutgEmailSent={frcdOutgEmailSent}
                  sendNowLoading={sendNowStatus.fetching}
                />
              </ATMFormProvider>
            );
          }}
        </ATMForm>
      </ATMModal.Content>
      <ATMModal.Actions>
        <ATMButton
          type="button"
          secondary
          content={Lang.LBL_CANCEL}
          onClick={() => {
            setIsConfirmOpen(false);
          }}
          disabled={sendNowStatus.fetching}
        />
        <ATMButton
          type="button"
          primary
          content={
            !frcdOutgEmailSent ? Lang.LBL_SEND_EMAIL : Lang.LBL_RESEND_EMAIL
          }
          onClick={() => {
            handleConfirmEmailClick();
          }}
          disabled={sendNowStatus.fetching}
          loading={sendNowStatus.fetching}
        />
      </ATMModal.Actions>
    </ATMModal>
  );
};

export default RealTimeLogListEmailSend;
