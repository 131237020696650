/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable no-param-reassign */
/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/no-use-before-define */
import {
  AlignmentType,
  BorderStyle,
  Document,
  Footer,
  Header,
  ImageRun,
  Packer,
  Paragraph,
  Table,
  TableCell,
  TableRow,
  TextRun,
  WidthType,
} from 'docx';
import {
  BPOR_REPORT_FOOTER,
  BPOR_REPORT_FOOTER_CLASSIFICATION,
  BULK_POWER,
  ELEMENT_NODE,
  ElementAttribute,
  FONT_FAMILY,
  OPERATIONS_REPORT,
} from 'src/constants/file-bpor-report.constant';
// import { saveAs } from 'file-saver';
import { IRealTimeLogBPORTextRun } from 'src/models/real-time-log.model';
import {
  REAL_TIME_LOG_BPOR_REPORT_FIELD_LABEL,
  SYSTEM_CHANGES_FIELD_LABEL,
} from 'src/constants/real-time-log.constant';

type IDocument = {
  content: string;
  fontSize: number | null;
  logo: string | null;
};

export type ITextRun = {
  text: string;
  italics: boolean;
  bold: boolean;
  underline: any;
  font: string;
  size: number;
  color: string;
};

export type IResponseData = {
  text: TextRun[];
  row: TableRow | null;
};

class DocumentGenerator {
  private document: IDocument;

  constructor(data: IDocument) {
    this.document = {
      content: data.content,
      fontSize: data.fontSize ?? 22,
      logo: data.logo,
    };
  }

  setContent(content: string) {
    this.document.content = content;
  }

  getContent() {
    return this.document.content;
  }

  toInches(inches: number) {
    return inches * 1440;
  }

  convert(percentage: number, totalWidth: number) {
    return (percentage / 100) * totalWidth;
  }

  getPageWidth() {
    const pageWidthInInches = 8.5;
    return this.toInches(pageWidthInInches);
  }

  getPageHeight() {
    const pageHeightInInches = 11;
    return this.toInches(pageHeightInInches);
  }

  getFontSize() {
    return this.document.fontSize;
  }

  convertWidthToInches(percent) {
    const pageWidthInInches = 8.5;
    const pageWidthInches = this.toInches(pageWidthInInches);
    return this.convert(percent, pageWidthInches);
  }

  convertHeightToInches(percent) {
    const pageHeightInInches = 11;
    const pageHeightInches = this.toInches(pageHeightInInches);
    return this.convert(percent, pageHeightInches);
  }

  setLogo(logo) {
    this.document.logo = logo;
  }

  getLogo() {
    return this.document.logo;
  }

  getHeaderImage() {
    const logo = this.getLogo();
    if (logo) {
      return Buffer.from(logo, 'base64');
    }
    return '';
  }

  getElementAttribute(attribute: any): ElementAttribute {
    if (attribute?.length) {
      const attrs = attribute?.toString()?.split(':');
      if (attrs?.length) {
        if (attrs.length === 2) {
          switch (attrs[0]) {
            case 'text-align': {
              return {
                Alignment: attrs[1]?.trim(),
                Color: '',
              };
            }
            case 'color': {
              return {
                Alignment: '' as any,
                Color: attrs[1]?.trim(),
              };
            }
            default:
              break;
          }
        }
      }
    }

    return { Alignment: AlignmentType.LEFT as any, Color: '#000000' };
  }

  convertRGBToHex(r: number, g: number, b: number): string {
    r = Math.max(0, Math.min(255, r));
    g = Math.max(0, Math.min(255, g));
    b = Math.max(0, Math.min(255, b));

    const toHex = (value: number): string => {
      const hex = value.toString(16);
      return hex.length === 1 ? `0${hex}` : hex;
    };

    return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
  }

  isForForcedInterruptionsTable(field: any): boolean {
    const fields = Object.values(REAL_TIME_LOG_BPOR_REPORT_FIELD_LABEL);
    const matchFound = fields.filter((val) => {
      if (
        val.includes(REAL_TIME_LOG_BPOR_REPORT_FIELD_LABEL.ITEMS_OF_INTEREST) ||
        val.includes(REAL_TIME_LOG_BPOR_REPORT_FIELD_LABEL.FORCED_INTERRUPTIONS)
      ) {
        return false;
      }
      if (field.includes(val)) {
        return true;
      }
      return false;
    });
    return !!matchFound.length;
  }

  isForSystemChangesTable(field: any): boolean {
    const fields = Object.values(SYSTEM_CHANGES_FIELD_LABEL);
    const matchFound = fields.filter((val) => {
      if (val.includes(SYSTEM_CHANGES_FIELD_LABEL.SYSTEM_CHANGES)) {
        return false;
      }
      if (field.includes(val)) {
        return true;
      }
      return false;
    });
    return !!matchFound.length;
  }

  createTableCell(data: TextRun[]): TableCell[] {
    const tableCell: TableCell[] = [];
    data?.map((val) => {
      tableCell.push(
        new TableCell({
          borders: {
            top: { style: BorderStyle.NONE, size: 1, color: '#FFFFFF' },
            bottom: { style: BorderStyle.NONE, size: 1, color: '#FFFFFF' },
            left: { style: BorderStyle.NONE, size: 1, color: '#FFFFFF' },
            right: { style: BorderStyle.NONE, size: 1, color: '#FFFFFF' },
          },
          children: [
            new Paragraph({
              children: [val],
            }),
          ],
        })
      );
    });
    return tableCell;
  }

  createTableRow(data: TextRun[]): TableRow {
    const tableRow = new TableRow({
      children: [...this.createTableCell(data)],
    });
    return tableRow;
  }

  createTable(data: TableRow[]) {
    const table = new Table({
      borders: {
        top: { style: BorderStyle.NONE },
        bottom: { style: BorderStyle.NONE },
        left: { style: BorderStyle.NONE },
        right: { style: BorderStyle.NONE },
      },
      rows: [...data],
    });
    return table;
  }

  extractFieldName(name) {
    const fieldName = /^(.*?:\s*)/.exec(name);
    return fieldName?.[0];
  }

  getAllChildElements(element): IResponseData {
    const elements: any[] = [];
    const childNodes = Array.from(element);
    const isNodeElement = (node) => node.nodeType === Node.ELEMENT_NODE;
    const rgbRegex = /rgb\((\d+),\s*(\d+),\s*(\d+)\)/;
    const isRGB = (color) => color?.match(rgbRegex);
    const getChildNodeAttributes = (
      elem,
      attr: IRealTimeLogBPORTextRun
    ): IRealTimeLogBPORTextRun => {
      switch (elem.nodeType) {
        case Node.ELEMENT_NODE: {
          switch (elem.nodeName) {
            case ELEMENT_NODE.BOLD: {
              attr.textRun.bold = true;
              isNodeElement(elem) &&
                elem.childNodes.forEach((child) => {
                  getChildNodeAttributes(child, attr);
                });
              break;
            }
            case ELEMENT_NODE.ITALIC: {
              attr.textRun.italics = true;
              isNodeElement(elem) &&
                elem.childNodes.forEach((child) => {
                  getChildNodeAttributes(child, attr);
                });
              break;
            }
            case ELEMENT_NODE.UNDERLINE: {
              attr.textRun.underline = { type: 'single' };
              isNodeElement(elem) &&
                elem.childNodes.forEach((child) => {
                  getChildNodeAttributes(child, attr);
                });
              break;
            }
            case ELEMENT_NODE.TEXT: {
              attr.textRun.text = elem.textContent;
              isNodeElement(elem) &&
                elem.childNodes.forEach((child) => {
                  getChildNodeAttributes(child, attr);
                });
              break;
            }
            case ELEMENT_NODE.SPAN: {
              attr.textRun.text = elem.textContent;
              const el = elem as Element;
              const style = this.getElementAttribute(el.getAttribute('style'));
              if (style) {
                attr.alignment = style.Alignment as any;
                const rgb = isRGB(style?.Color);
                const color =
                  rgb?.length > 3
                    ? this.convertRGBToHex(rgb[1], rgb[2], rgb[3])
                    : null;
                attr.textRun.color = color || (style?.Color as any);
              }
              isNodeElement(elem) &&
                elem.childNodes.forEach((child) => {
                  getChildNodeAttributes(child, attr);
                });
              break;
            }
            default: {
              isNodeElement(elem) &&
                elem.childNodes.forEach((child) => {
                  getChildNodeAttributes(child, attr);
                });
              break;
            }
          }
          break;
        }
        case Node.TEXT_NODE: {
          attr.textRun.text = elem.textContent;
          break;
        }
      }
      return attr;
    };
    let isForTable = false;
    childNodes.map((elem: any) => {
      const attributes: IRealTimeLogBPORTextRun = {
        textRun: {
          text: '',
          italics: false,
          bold: false,
          underline: null as any,
          font: FONT_FAMILY.TIMES_NEW_ROMAN,
          size: this.getFontSize(),
          color: '#000000',
        },
        alignment: AlignmentType.LEFT as any,
        indent: null,
      };
      getChildNodeAttributes(elem, attributes);
      if (!isForTable) {
        isForTable =
          this.isForForcedInterruptionsTable(attributes.textRun.text?.trim()) ||
          this.isForSystemChangesTable(attributes.textRun.text?.trim());
      }
      const match = attributes.textRun.text?.match(/^(.*?):\s*(.+)$/);
      if (match) {
        match.map((val, i) => {
          switch (i) {
            case 0: {
              const fieldName = this.extractFieldName(val);
              elements.push(
                new TextRun({ ...(attributes.textRun as any), text: fieldName })
              );
              break;
            }
            case 2: {
              elements.push(
                new TextRun({ ...(attributes.textRun as any), text: val })
              );
              break;
            }
            default:
              break;
          }
        });
      } else {
        elements.push(new TextRun(attributes.textRun as any));
      }
      return null;
    });
    return {
      text: elements,
      row: isForTable ? this.createTableRow(elements) : null,
    };
  }

  public convertHTMLToDocument(html: string) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    const elems: any[] = [];
    const addText = (content, fontSize) => {
      return new TextRun({
        text: content,
        font: FONT_FAMILY.TIMES_NEW_ROMAN,
        size: fontSize || this.getFontSize(),
      });
    };
    let tableRow: TableRow[] = [];
    Array.from(doc.body.childNodes).forEach((children) => {
      switch (children.nodeName) {
        case ELEMENT_NODE.PARAGRAPH: {
          let textAlign = AlignmentType.LEFT;
          if (children.nodeType === Node.ELEMENT_NODE) {
            const element = children as Element;
            const attr = this.getElementAttribute(
              element.getAttribute('style')
            );
            textAlign = attr?.Alignment as any;
          }
          const childNodes = this.getAllChildElements(children?.childNodes);
          if (childNodes?.row) {
            tableRow.push(childNodes.row);
          } else {
            elems.push(
              new Paragraph({
                children: [...childNodes.text],
                alignment: textAlign,
              })
            );
          }
          break;
        }
        case ELEMENT_NODE.HORIZONTAL_RULE: {
          elems.push(
            new Paragraph({
              children: [addText('', null)],
            })
          );
          tableRow = [];
          break;
        }
        case ELEMENT_NODE.UNORDERED_LIST: {
          children.childNodes.forEach((val) => {
            val.childNodes.forEach((item) => {
              elems.push(
                new Paragraph({
                  children: [addText(item.textContent || '', null)],
                  bullet: {
                    level: 0,
                  },
                })
              );
            });
          });
          break;
        }
        case ELEMENT_NODE.ORDERED_LIST: {
          children.childNodes.forEach((val) => {
            val.childNodes.forEach((item) => {
              elems.push(
                new Paragraph({
                  children: [addText(item.textContent || '', null)],
                  numbering: {
                    reference: 'numbering',
                    level: 0,
                  },
                  indent: {
                    left: 360,
                  },
                })
              );
            });
          });
          break;
        }
        default:
          break;
      }
      const tableData = tableRow.length && this.createTable(tableRow);
      tableData && elems.push(tableData);
      tableRow = [];
    });
    return elems;
  }

  public createDocument(): Document {
    const document = new Document({
      numbering: {
        config: [
          {
            reference: 'numbering',
            levels: [
              {
                level: 0,
                format: 'decimal',
                start: 1,
                text: '%1.',
                alignment: AlignmentType.LEFT,
              },
            ],
          },
        ],
      },
      sections: [
        {
          properties: {
            page: {
              size: {
                width: this.getPageWidth(),
                height: this.getPageHeight(),
              },
              margin: {
                top: 720,
                right: 720,
                bottom: 720,
                left: 720,
              },
            },
          },
          headers: {
            default: this.createHeader(),
          },
          children: this.convertHTMLToDocument(this.document.content),
          footers: {
            default: this.createFooter(),
          },
        },
      ],
    });
    return document;
  }

  public createHeader(): Header {
    const styles = {
      borders: {
        top: { style: BorderStyle.NONE },
        bottom: { style: BorderStyle.NONE },
        left: { style: BorderStyle.NONE },
        right: { style: BorderStyle.NONE },
      },
      cellBorders: {
        top: { style: BorderStyle.NONE, size: 1, color: '#FFFFFF' },
        bottom: { style: BorderStyle.NONE, size: 1, color: '#FFFFFF' },
        left: { style: BorderStyle.NONE, size: 1, color: '#FFFFFF' },
        right: { style: BorderStyle.NONE, size: 1, color: '#FFFFFF' },
      },
      spacing: {
        before: 240,
        after: 240,
      },
    };
    const header = new Header({
      children: [
        new Table({
          borders: styles.borders,
          rows: [
            new TableRow({
              children: [
                new TableCell({
                  width: {
                    size: this.convertHeightToInches(30),
                    type: WidthType.DXA,
                  },
                  borders: styles.cellBorders,
                  children: [
                    new Paragraph({
                      alignment: AlignmentType.RIGHT,
                      children: [
                        new TextRun({
                          bold: true,
                          size: 24,
                          font: FONT_FAMILY.ARIAL_BLACK,
                          text: BULK_POWER,
                        }),
                      ],
                      spacing: styles.spacing,
                    }),
                  ],
                }),
                new TableCell({
                  width: {
                    size: this.convertHeightToInches(40),
                    type: WidthType.DXA,
                  },
                  borders: styles.cellBorders,
                  children: [
                    new Paragraph({
                      border: styles.borders,
                      children: [
                        new ImageRun({
                          data: this.getHeaderImage(),
                          transformation: {
                            width: 140,
                            height: 40,
                          },
                        }),
                      ],
                      alignment: AlignmentType.CENTER,
                      spacing: {
                        before: 60,
                        after: 40,
                      },
                    }),
                  ],
                }),
                new TableCell({
                  width: {
                    size: this.convertHeightToInches(30),
                    type: WidthType.DXA,
                  },
                  borders: styles.cellBorders,
                  children: [
                    new Paragraph({
                      alignment: AlignmentType.LEFT,
                      children: [
                        new TextRun({
                          bold: true,
                          size: 24,
                          font: FONT_FAMILY.ARIAL_BLACK,
                          text: OPERATIONS_REPORT,
                        }),
                      ],
                      spacing: styles.spacing,
                    }),
                  ],
                }),
              ],
            }),
          ],
        }),
      ],
    });
    return header;
  }

  public createFooter(): Footer {
    const styles = {
      borders: {
        top: { style: BorderStyle.NONE, size: 0 },
        bottom: { style: BorderStyle.NONE, size: 0 },
        left: { style: BorderStyle.NONE, size: 0 },
        right: { style: BorderStyle.NONE, size: 0 },
      },
      cellBorders: {
        top: { style: BorderStyle.NONE, size: 1, color: '#FFFFFF' },
        bottom: { style: BorderStyle.NONE, size: 1, color: '#FFFFFF' },
        left: { style: BorderStyle.NONE, size: 1, color: '#FFFFFF' },
        right: { style: BorderStyle.NONE, size: 1, color: '#FFFFFF' },
      },
      spacing: {
        before: 40,
        after: 40,
      },
    };
    const footer = new Footer({
      children: [
        new Table({
          borders: styles.borders,
          margins: {
            bottom: 30,
          },
          rows: [
            new TableRow({
              children: [
                new TableCell({
                  width: {
                    size: this.convertHeightToInches(30),
                    type: WidthType.DXA,
                  },
                  borders: styles.cellBorders,
                  children: [
                    new Paragraph({
                      alignment: AlignmentType.LEFT,
                      children: [
                        new TextRun({
                          bold: false,
                          size: 22,
                          font: FONT_FAMILY.ARIAL,
                          text: '',
                        }),
                      ],
                    }),
                  ],
                }),
                new TableCell({
                  width: {
                    size: this.convertHeightToInches(40),
                    type: WidthType.DXA,
                  },
                  borders: styles.cellBorders,
                  children: [
                    new Paragraph({
                      alignment: AlignmentType.CENTER,
                      children: [
                        new TextRun({
                          bold: false,
                          size: 22,
                          font: FONT_FAMILY.ARIAL,
                          text: BPOR_REPORT_FOOTER_CLASSIFICATION,
                        }),
                      ],
                    }),
                  ],
                }),
                new TableCell({
                  width: {
                    size: this.convertHeightToInches(30),
                    type: WidthType.DXA,
                  },
                  borders: styles.cellBorders,
                  children: [
                    new Paragraph({
                      children: [
                        new TextRun({
                          text: '',
                          size: 22,
                          font: FONT_FAMILY.ARIAL,
                        }),
                      ],
                      alignment: AlignmentType.RIGHT,
                    }),
                  ],
                }),
              ],
            }),
          ],
        }),

        new Paragraph({
          alignment: AlignmentType.CENTER,
          spacing: {
            after: 240,
          },
          children: [
            new TextRun({
              italics: true,
              font: FONT_FAMILY.TAHOMA,
              size: 16,
              color: '#0000FF',
              text: BPOR_REPORT_FOOTER,
            }),
          ],
        }),
      ],
    });
    return footer;
  }

  public async generateDocument() {
    const doc = this.createDocument();
    const base64String = await Packer.toBase64String(doc);
    // const blob = await Packer.toBlob(doc);
    // saveAs(blob, 'BPOR_REPORT.docx');
    return base64String;
  }
}

export default DocumentGenerator;
