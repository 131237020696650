import { ICommonState, IReducerAction } from 'src/libraries/thunk.library';
import services from 'src/services/real-time-log.service';
import { IEmployeesRealTimeLog } from 'src/models/real-time-log.model';
import { IRealTimeLogAudit } from 'src/models/real-time-log-audit.model';

export const realTimeLogAuditActionTypes = {
  REAL_TIME_LOG_EMPLOYEE_LIST_READ: 'REAL_TIME_LOG_EMPLOYEE_LIST_READ',
  BPOR_AUDIT_LOGS_READ: 'BPOR_AUDIT_LOGS_READ',
  REAL_TIME_LOG_AUDIT_CLEAR_LIST: 'REAL_TIME_LOG_AUDIT_CLEAR_LIST',
} as const;

export const duckActions = {
  // These are async actions that has promise response on event queue
  auditLogsGET: {
    type: realTimeLogAuditActionTypes.BPOR_AUDIT_LOGS_READ,
    service: services.auditLogsGET,
  },

  employeeListGET: {
    type: realTimeLogAuditActionTypes.REAL_TIME_LOG_EMPLOYEE_LIST_READ,
    service: services.employeeListGET,
  },

  clearList: () => ({
    type: realTimeLogAuditActionTypes.REAL_TIME_LOG_AUDIT_CLEAR_LIST,
  }),
};

export type IRealTimeLogAuditAsync = typeof duckActions;

export interface IRealTimeLogAuditState
  extends ICommonState<typeof realTimeLogAuditActionTypes> {
  employeeList: IEmployeesRealTimeLog[];
  list: IRealTimeLogAudit[];
  today: number;
  all: number;
}

export const defaultState: IRealTimeLogAuditState = {
  status: {},
  employeeList: [],
  list: [],
  all: 0,
  today: 0,
};

const RealTimeLogAuditReducer = (
  state: IRealTimeLogAuditState,
  action: IReducerAction<IRealTimeLogAuditAsync>
): IRealTimeLogAuditState => {
  switch (action.type) {
    case realTimeLogAuditActionTypes.BPOR_AUDIT_LOGS_READ: {
      return {
        ...state,
        list: action.payload?.rows ?? [],
        all: action.payload?.count.all ?? 0,
        today: action.payload?.count.today ?? 0,
      };
    }

    case realTimeLogAuditActionTypes.REAL_TIME_LOG_EMPLOYEE_LIST_READ: {
      return {
        ...state,
        employeeList: action.payload?.rows ?? [],
      };
    }

    case realTimeLogAuditActionTypes.REAL_TIME_LOG_AUDIT_CLEAR_LIST: {
      return {
        ...state,
        list: [],
      };
    }

    default: {
      return state;
    }
  }
};

export default RealTimeLogAuditReducer;
