/* eslint-disable react/no-children-prop */

import React from 'react';
import { ATMGrid, ATMHeader, ATMIcon, ATMLoader } from 'shared-it-appmod-ui';
// import { useEditor } from '@tiptap/react';
// import TextEditor, {
//   CustomEditorSelection,
//   customEditor,
//   defaultExtensions,
// } from 'src/components/organisms/editor/editor.component';
// import { generateRealTimeLogBPORReport } from 'src/helpers/real-time-log.helper';
import { Dimmer } from 'semantic-ui-react';
import Lang from 'src/libraries/language';
import { useRealTimeLogContext } from 'src/contexts/real-time-log.context';
import styles from './real-time-log-bpor-report.module.scss';
import RealTimeLogEditor from 'src/components/organisms/editor/real-time-log-editor.component';

type IProps = {
  reportDate: Date;
  loading?: boolean;
  setEditorContent: React.Dispatch<React.SetStateAction<string>>;
  editorContent?: string;
};

const RealTimeLogBPORReportView: React.FC<IProps> = ({
  reportDate,
  loading,
  // editorContent,
  setEditorContent,
}) => {
  const { state } = useRealTimeLogContext();
  // const editor = useEditor({
  //   extensions: defaultExtensions,
  //   autofocus: true,
  //   content: '',
  //   onUpdate({ editor: content }) {
  //     setEditorContent(content.getHTML());
  //   },
  // });

  // useEffect(() => {
  //   if (editor) {
  //     const reportTemplate = reportDate
  //       ? generateRealTimeLogBPORReport(
  //           state?.report ?? null,
  //           reportDate as any
  //         ).mailContent
  //       : null;
  //     editor.commands.setContent(reportTemplate);
  //     setEditorContent(reportTemplate ?? editor.getHTML());
  //   }
  // }, [reportDate, state.report]);
  return reportDate && state.report ? (
    <div className="admin-container">
      <ATMGrid columns={16}>
        <ATMGrid.Row>
          <ATMGrid.Column width={16}>
            {loading && (
              <Dimmer active inverted>
                <ATMLoader inverted content={Lang.LBL_LOADING} />
              </Dimmer>
            )}
            {/* <TextEditor
              content=""
              extensions={defaultExtensions}
              editor={editor as any}
              editorContent={editorContent}
              children={customEditor(
                editor,
                CustomEditorSelection.CUSTOM_FOR_BPOR
              )}
              setEditorContent={setEditorContent}
              isDefault={false}
            /> */}
            <RealTimeLogEditor
              reportDate={reportDate}
              loading={loading}
              setEditorContent={setEditorContent}
            />
          </ATMGrid.Column>
        </ATMGrid.Row>
      </ATMGrid>
    </div>
  ) : (
    <ATMGrid columns={16}>
      <ATMGrid.Row>
        <ATMGrid.Column width={16}>
          {loading && (
            <Dimmer active inverted>
              <ATMLoader inverted content={Lang.LBL_LOADING} />
            </Dimmer>
          )}
          <div className={styles.searchContainerDefault}>
            <ATMIcon
              circular
              name="search"
              size="large"
              className={styles.searchIcon}
            />
            <ATMHeader
              as="h3"
              content={Lang.LBL_VIEW_RECORDS}
              className={styles.title}
            />
            <p className={styles.subtitle}>{Lang.LBL_BPOR_SEARCH_ENTRY}</p>
          </div>
        </ATMGrid.Column>
      </ATMGrid.Row>
    </ATMGrid>
  );
};

export default RealTimeLogBPORReportView;
